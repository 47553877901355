import { isEmpty } from 'lodash';

import { trackGtmEvent } from '@/utils/pixels/helloWork';

const trackSignin = ({ user, event }) => {
  trackGtmEvent('login', {
    user_id_back: user?._id || '',
    jobdating_name: event?.slug || '',
    isCandidate: isEmpty(user._currentOrganization),
  });
};

const trackRecruiterClickedAccessSchoolEvents = ({ authUser }) => {
  trackGtmEvent('seekalendar_click', {
    page_category: 'home',
    page_subject: 'home_page',
    user_id_back: authUser._id,
    role: authUser.currentRole?._role?.key,
    company: authUser._currentOrganization.name,
    isClient: true,
  });
};

const trackRecruiterClickedDiscoverSchoolEvents = ({ authUser }) => {
  trackGtmEvent('seekalendar_contact', {
    page_category: 'home',
    page_subject: 'home_page',
    user_id_back: authUser._id,
    role: authUser.currentRole?._role?.key,
    company: authUser._currentOrganization?.name,
    isClient: false,
  });
};

const trackRecruiterClickedDiscoverOurOffers = ({ authUser }) => {
  trackGtmEvent('seekalendar_contact', {
    page_category: 'home',
    page_subject: 'home_page',
    user_id_back: authUser._id,
    role: authUser.currentRole?._role?.key,
    company: authUser._currentOrganization?.name,
    isClient: false,
  });
};

const trackRecruiterDownloadedSeekubeCalendar = ({ authUser }) => {
  trackGtmEvent('seekalendar_download', {
    page_category: 'home',
    page_subject: 'home_page',
    user_id_back: authUser._id,
    role: authUser.currentRole?._role?.key,
    company: authUser._currentOrganization.name,
    isClient: true,
  });
};

export {
  trackSignin,
  trackRecruiterClickedAccessSchoolEvents,
  trackRecruiterClickedDiscoverSchoolEvents,
  trackRecruiterDownloadedSeekubeCalendar,
  trackRecruiterClickedDiscoverOurOffers,
};
