import React from 'react';

import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import parseISO from 'date-fns/parseISO';
import head from 'lodash/head';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';

import toJS from '../../../../../app/utils/toJS';
import Icon from '../../../../components/Icon';
import Image, { getCdnUrl } from '../../../../components/Image';
import Tag from '../../../../components/Tag';
import styles from './styles.less';

/* eslint-disable */
class EventCard extends React.PureComponent {
  // eslint-disable-line
  state = {
    isMobile: window.innerWidth <= 768,
  };

  render() {
    const { intl, event } = this.props;
    const { isMobile } = this.state;
    const tooltip = intl.formatMessage({ id: 'calendar.eventCard.tooltip' });
    const tagPrivate = intl.formatMessage({ id: 'private' });

    return (
      <div className={styles.eventCard}>
        <div
          className={styles.cover}
          style={{ backgroundImage: 'url(' + getCdnUrl(event.theme.coverPictureUrl, 500) + ')' }}
        >
          <div className={styles.coverContent}>
            <div
              className={styles.tags}
              data-tip={tooltip}
              data-place="top"
              data-effect="solid"
              data-tip-disable={isMobile}
            >
              {event.visibility === 'private' ? <Tag icon="password-lock" name={tagPrivate} /> : ''}
            </div>
            {head(event.planners._organizations) &&
            head(event.planners._organizations).slug !== 'seekube' ? (
              <div className={styles.organization}>
                <div className={styles.organizationText}>
                  <div>
                    <FormattedMessage id="organized" />
                  </div>
                  <div>
                    <FormattedMessage id="by" /> {event.planners._organizations[0].name}
                  </div>
                </div>
                <div className={styles.organizationImage}>
                  {head(event.planners._organizations).profile ? (
                    <Image
                      image={
                        <img
                          src={getCdnUrl(event.planners._organizations[0].profile.pictureUrl, 100)}
                          alt="Organization logo"
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={styles.title}>{event.name}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentText}>
            <Icon name="calendar" />
            {parseISO(event.keyDates.jobfair.endAt) < new Date() ? (
              <div>
                <small className="ended">
                  <FormattedMessage id="ended" />
                </small>
              </div>
            ) : (
              <div>
                <strong>{format(parseISO(event.keyDates.jobfair.beginAt), 'dd')}</strong>
                <small>
                  {format(parseISO(event.keyDates.jobfair.beginAt), 'MMM', { locale: fr })}
                </small>
                -<strong>{format(parseISO(event.keyDates.jobfair.endAt), 'dd')}</strong>
                <small>
                  {format(parseISO(event.keyDates.jobfair.endAt), 'MMM', { locale: fr })}
                </small>
              </div>
            )}
          </div>
          <div className={styles.contentHover}>
            <a
              href="https://seekube.hellowork.com/demande-de-rappel"
              target="_blank"
              className="btn primary large"
            >
              <FormattedMessage id="knowMore" />
            </a>
          </div>
        </div>
        <ReactTooltip />
      </div>
    );
  }
}

export default compose(injectIntl)(toJS(EventCard));
