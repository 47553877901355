/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';

/**
 * App reducers
 */
import languageProviderReducer from '../app/containers/LanguageProvider/reducer';
import actionReducer from '../app/store/action';
import appReducer from '../app/store/app/reducer';
import appointmentReducer from '../app/store/appointment';
import authReducer from '../app/store/auth';
import criterionReducer from '../app/store/criterion';
import entitiesReducer from '../app/store/entities';
import eventReducer from '../app/store/event';
import exponentReducer from '../app/store/exponent';
import interactionReducer from '../app/store/interaction';
import jobReducer from '../app/store/job';

/**
 * App reducers
 */
import notificationReducer from '../app/store/notification';
import offerReducer from '../app/store/offer';
import organizationReducer from '../app/store/organization';
import participantReducer from '../app/store/participant';
import sectorReducer from '../app/store/sector';
import surveyReducer from '../app/store/survey';
import timeslotReducer from '../app/store/timeslot';
import uploadReducer from '../app/store/upload';
import userReducer from '../app/store/user';
import v1KpiReducer from '../app/store/v1Kpi';

/**
 * Merge route into the global application state
 */
/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    language: languageProviderReducer,
    app: appReducer,
    entities: entitiesReducer,
    notification: notificationReducer,
    event: eventReducer,
    upload: uploadReducer,
    participant: participantReducer,
    job: jobReducer,
    auth: authReducer,
    user: userReducer,
    interaction: interactionReducer,
    exponent: exponentReducer,
    offer: offerReducer,
    appointment: appointmentReducer,
    timeslot: timeslotReducer,
    organization: organizationReducer,
    sector: sectorReducer,
    v1Kpi: v1KpiReducer,
    criterion: criterionReducer,
    action: actionReducer,
    survey: surveyReducer,
  });
}
