import { createAction } from 'redux-actions';

import * as types from './types';

export const setCurrentInteraction = createAction(types.SET_CURRENT_INTERACTION);

// GET all participants
export const getInteractions = createAction(types.GET_INTERACTIONS);
export const getInteractionsSuccess = createAction(types.GET_INTERACTIONS_SUCCESS);
export const getInteractionsError = createAction(types.GET_INTERACTIONS_ERROR);
export const changeInteractionOwner = createAction(types.CHANGE_INTERACTION_OWNER);
