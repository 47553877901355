import React from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Body1, IconMapPin, IconVideo, IconWifi } from '@seekube-tech/ui-kit';

import { EVENT_FORMAT } from '@/utils/constants';

import styles from '../../styles.less';

const EventFormat = ({ format, location = '', keyMoments }) => {
  const t = useFormatMessage();

  if (isEmpty(format)) {
    return null;
  }

  const icons = {
    virtual: <IconVideo className="text-neutral-300" size={16} />,
    hybrid: <IconWifi className="text-neutral-300" size={16} />,
    physical: <IconMapPin className="text-neutral-300" size={16} />,
  };

  const getDate = ({ endAt = '', beginAt = '' } = {}) =>
    t(
      { id: 'searchEngine.list.event.dates' },
      {
        dateFrom: moment(beginAt).format('DD MMMM YYYY'),
        dateTo: moment(endAt).format('DD MMMM YYYY'),
      },
    );

  if (format === EVENT_FORMAT.HYBRID && !isEmpty(keyMoments)) {
    const virtual = keyMoments.jobfair.find(moment => moment.format === EVENT_FORMAT.VIRTUAL);
    const physical = keyMoments.jobfair.find(moment => moment.format === EVENT_FORMAT.PHYSICAL);

    return (
      <div className={styles.infosContainer}>
        <div className={styles.infoItem}>
          {icons.virtual}
          <Body1 className="text-neutral-500">
            <b>{t({ id: 'virtual' })}</b>: {getDate(virtual)}
          </Body1>
        </div>
        <div className={styles.infoItem}>
          {icons.physical}
          <Body1 className="text-neutral-500">
            <b>{t({ id: 'physical' })}</b>: {getDate(physical)}
            <br />
            {location}
          </Body1>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.infoItem}>
      {icons[format]}
      <Body1 className="text-neutral-500">
        {t({ id: format })}{' '}
        {location?.name && format === EVENT_FORMAT.PHYSICAL && `, ${location?.name || ''}`}
      </Body1>
    </div>
  );
};

EventFormat.propTypes = {
  format: string,
  location: string,
  keyMoments: object,
};

export { EventFormat };
