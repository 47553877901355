import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { jobListSchema } from './schema';

const getJobReducer = state => state.get('job');
const getEntities = state => state.get('entities');

/**
 * Return all jobs
 */
export const getJobs = createSelector(getJobReducer, getEntities, (jobState, entities) => {
  const allIds = jobState.get('allIds');
  const jobsList = allIds.map(id => entities.getIn(['jobs', id]));

  return denormalize(jobsList, jobListSchema, entities);
});
