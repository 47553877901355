import { call, put, select, takeLatest } from 'redux-saga/effects';

import request from '@/utils/request';

import { trackError } from '../../utils/analytics/helpers';
import { authSelectors } from '../auth';
import * as actions from './actions';
import * as types from './types';

/**
 * PostAction
 *
 * @description
 * Create new action
 */
function* postAction({ payload: { actionParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/actions`;
  const authUser = yield select(authSelectors.getAuthUser);
  const body = actionParams;

  body._user = authUser?.get('_id');
  body.isAdmin = authUser?.get('isAdmin');

  // Post the appointment
  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

function* getActions({ payload: { name = null, eventId, candidateId, callback } }) {
  let requestUrl = `${process.env.FRONT_API_URL}/actions?eventId=${eventId}&candidateId=${candidateId}`;

  if (name !== null) {
    requestUrl = `${process.env.FRONT_API_URL}/actions?name=${name}`;
  }

  // Post the appointment
  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(actions.getActionsSuccess(result));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

function* deleteAction({ payload: { actionId, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/actions/${actionId}`;

  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
    });

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_ACTIONS, getActions),
  takeLatest(types.POST_ACTION, postAction),
  takeLatest(types.DELETE_ACTION, deleteAction),
];
