import { get } from 'lodash';
import { normalize } from 'normalizr';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { API_CUSTOM_ERRORS } from '@/utils/constants';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

import { trackError } from '../../utils/analytics/helpers';
import * as actionActions from '../action/actions';
import { authSelectors } from '../auth';
import { switchAuth, switchExit } from '../auth/actions';
import { entitiesActions } from '../entities';
import { eventSelectors } from '../event';
import { notificationActions } from '../notification';
import { organizationSchema } from '../organization/schema';
import * as timeslotActions from '../timeslot/actions';
import * as userActions from '../user/actions';
import * as actions from './actions';
import { exponentListSchema, exponentSchema } from './schema';
import * as exponentSelectors from './selectors';
import * as types from './types';

/**
 * Get an exponent
 */
function* getExponent({ payload: { eventId, exponentId, participantId, postVisit = false } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, exponentSchema);
    const dataOrgNormalized = normalize(result._organization, organizationSchema);
    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._organization._id,
        type: 'organizations',
        entities: dataOrgNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (postVisit) {
      let authUser = yield select(authSelectors.getAuthUserConnectedAs);

      if (!authUser) {
        authUser = yield select(authSelectors.getAuthUser);
      }

      if (authUser && participantId) {
        yield put(
          actionActions.postAction({
            actionParams: {
              name: 'CANDIDATE_VISIT_STAND',
              _user: authUser.get('_id'),
              _organization: result._organization._id,
              _event: result._event._id,
              participantId,
            },
          }),
        );
      }
    }

    // Set the current event
    yield put(actions.setCurrentExponent({ exponentId: result._id }));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Get many exponent by organization
 */
function* getExponentsByOrganization({
  payload: { organizationId, page, offset, limit, context, archive = null, q = '', callback },
}) {
  const params = objectToParams({ limit, context, offset, q, page });
  let requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId?.toString()}/exponents?${params}`;

  try {
    if (archive !== null) {
      requestUrl += `&past=${archive}`;
    }

    const {
      docs,
      limit: limit2,
      total,
    } = yield call(request, requestUrl, {
      method: 'GET',
    });
    // Normaliz data
    const dataNormalized = normalize(docs, exponentListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    yield put(
      actions.getExponentsByOrganizationSuccess({
        result: dataNormalized.result,
        offset,
        limit: limit2,
        total,
        page,
        currentPage: page,
        archive,
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(actions.getExponentByOrganizationError());
  }
}

/**
 * Get an exponent by event and organization
 */
function* getExponentByOrganization({
  payload: {
    eventId,
    organizationId,
    currentUserId,
    noCache = false,
    context = 'exponent',
    lean = true,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/byOrganization/${organizationId}?context=${context}&lean=${lean}`;

  try {
    const authUser = yield select(authSelectors.getAuthUser);
    const result = yield call(
      request,
      requestUrl,
      {
        method: 'GET',
        headers: {
          KoaNoCache: noCache,
        },
      },
      [404],
    );

    // Normaliz data
    const dataNormalized = normalize(result, exponentListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    const exponent = Array.isArray(result) ? result[0] : result;

    const exponentNormalized = normalize(exponent, exponentSchema);

    if (context === 'calendar' && currentUserId) {
      const userSelect = exponent?.users
        ? get(exponent.users.find(user => user?._user?._id === currentUserId) || {}, '_user')
        : null;

      if (userSelect) {
        yield put(
          timeslotActions.getUserTimeslots({ currentUser: userSelect, context: 'calendar' }),
        );
      }
    }

    yield put(entitiesActions.mergeEntities(exponentNormalized.entities));
    yield put(actions.getExponentByOrganizationSuccess());

    // Set the current event
    if (exponent) {
      yield put(actions.setCurrentExponent({ exponentId: exponent._id, context }));
    }

    if (
      result._organization &&
      authUser.get('_currentOrganization') &&
      authUser.get('_currentOrganization').get('_id') !== result._organization._id
    ) {
      yield put(userActions.switchOrganization({ organizationId: result._organization._id }));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.getExponentByOrganizationError());
  }
}

/**
 * Get exponent
 */
function* getExponents({
  payload: {
    eventId,
    search,
    page,
    offset,
    limit,
    sort,
    all,
    owner,
    facetKey,
    eventFormat = 'all',
  },
}) {
  const searchParams = objectToParams(search);
  let requestUrl;

  if (all === true) {
    requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/all?${searchParams}&owner=${owner}&offset=${offset}&page=${page}&limit=${limit}&sort=${sort}&eventFormat=${eventFormat}`;
  } else {
    requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents?${searchParams}&sort=${sort}&offset=${offset}&page=${page}&limit=${limit}&eventFormat=${eventFormat}`;
  }

  try {
    const event = yield select(eventSelectors.getCurrentEvent);
    const result = yield call(request, requestUrl);
    const items = result.docs || result;
    const { total, totalAll } = result;

    // Normaliz data
    const dataNormalized = normalize(items, exponentListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save all ids
    yield put(
      actions.getExponentsSuccess({
        result: dataNormalized.result,
        currentPage: page,
        event: event.toJS(),
        facetKey,
        offset,
        limit,
        total,
        totalAll,
        facets: result.facets,
        lastSearch: {
          search: {
            sector: search.sector || '',
            size: search.size || '',
            localization: search.localization || '',
            keywords: search.keywords || '',
          },
          matchingTmp: {
            filters: [],
            sector: search.sector || '',
            size: search.size || '',
            localization: search.localization || '',
            keywords: search.keywords || '',
          },
        },
      }),
    );
  } catch (err) {
    trackError(err);
  }
}

/**
 * Get exponent
 */
function* getExponentsStats({ payload: { eventId } }) {
  try {
    const stats = yield call(
      request,
      `${process.env.FRONT_API_URL}/events/${eventId}/exponents/stats`,
    );

    // Save all ids
    yield put(actions.getExponentsStatsSuccess({ stats }));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Patch an exponent
 */
function* patchExponent({
  payload: {
    exponentId,
    eventId,
    exponentParams,
    context = 'exponent',
    notificationParams,
    lean = false,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}?lean=${lean}`;

  let currentExponent =
    context === 'calendar'
      ? yield select(exponentSelectors.getCalendarExponent)
      : yield select(exponentSelectors.getCurrentExponent);
  let result;

  if (!currentExponent) {
    currentExponent = yield select(exponentSelectors.getCurrentExponent);
  }

  try {
    result = yield call(
      request,
      requestUrl,
      {
        method: 'PATCH',
        headers: {
          KoaUpdateCache: currentExponent
            ? `/events/${eventId}/exponents/byOrganization/${currentExponent.get('_organization').get('_id')}`
            : null,
        },
        body: JSON.stringify(exponentParams),
      },
      [],
      true,
    );

    // Normalize data
    const dataNormalized = normalize(result, exponentSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: exponentId,
        type: 'exponents',
        entities: dataNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    const errorData = yield err;

    if (errorData?.message === API_CUSTOM_ERRORS.userInAnotherStand) {
      yield put(
        actions.patchExponentError({
          message: notificationParams?.error?.userInAnotherStand?.message,
        }),
      );

      return;
    }

    if (notificationParams && notificationParams.error && errorData) {
      yield put(
        actions.patchExponentError({
          message: notificationParams.error.message.replace('%organization%', errorData.message),
        }),
      );
    }
  }
}

/**
 * Post an exponent
 */
function* postExponent({ payload: { notificationParams, callbackError, ...exponentParams } }) {
  const connectedAs = yield select(authSelectors.getAuthUserConnectedAs);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${exponentParams._event}/exponents`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(exponentParams),
    });

    if (exponentParams.reloadAuthUser) {
      /**
       * if user is connected as, just refresh the switch
       * else refresh authuser
       */
      if (connectedAs) {
        yield put(switchExit());
        yield put(
          switchAuth({
            userId: connectedAs.get('_id'),
            email: connectedAs.get('username'),
            noRedirect: true,
          }),
        );
      } else {
        // TODO : check this
        // yield put(autoLogin({ authToken }))
      }
    }

    // Normaliz data
    const dataNormalized = normalize(result, exponentSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save to new into allIds
    yield put(actions.postExponentSuccess(dataNormalized.result));

    // Success notification
    yield put(
      notificationActions.sendNotification({
        message: notificationParams?.success.message || '',
        kind: 'success',
      }),
    );

    if (typeof exponentParams.callback === 'function') {
      exponentParams.callback();
    }
  } catch (err) {
    trackError(err);

    if (typeof callbackError === 'function') {
      callbackError();
    }
  }
}

/**
 * Delete an exponent
 */
function* deleteExponent({
  payload: { eventId, exponentId, callback, noReload, notificationParams },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}`;

  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
    });

    // Delete exponent
    yield put(actions.deleteExponentSuccess(exponentId));

    if (typeof callback === 'function') {
      callback();
    }

    // Success notification
    yield put(
      notificationActions.sendNotification({
        message: notificationParams?.success?.message || '',
        kind: 'success',
      }),
    );

    if (noReload !== true) {
      yield put(
        actions.getExponents({
          eventId,
          offset: 0,
          page: 1,
          search: {},
          limit: 100,
          all: true,
          owner: true,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err?.message || notificationParams?.error?.message || '',
        kind: 'error',
      }),
    );
  }
}

/**
 * Get exponents partners
 */
function* getExponentsPartners({ payload: { eventId } }) {
  try {
    const partners = yield call(
      request,
      `${process.env.FRONT_API_URL}/events/${eventId}/exponents/partners`,
    );

    yield put(actions.getExponentsPartnersSuccess(partners));
  } catch (error) {
    yield put(actions.getExponentsPartnersError());

    trackError(error);

    yield put(
      notificationActions.sendNotification({
        message: error.message,
        kind: 'error',
      }),
    );
  }
}

/**
 * Patch exponents partners
 */
function* patchExponentsPartners({ payload: { eventId, params, notificationParams } }) {
  try {
    const partners = yield call(
      request,
      `${process.env.FRONT_API_URL}/events/${eventId}/exponents/partners`,
      {
        method: 'PATCH',
        body: JSON.stringify(params),
      },
    );

    yield put(actions.patchExponentsPartnersSuccess(partners));

    // Success notification
    if (notificationParams?.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (error) {
    yield put(actions.patchExponentsPartnersError());

    trackError(error);

    yield put(
      notificationActions.sendNotification({
        message: error.message,
        kind: 'error',
      }),
    );
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_EXPONENT, getExponent),
  takeEvery(types.GET_EXPONENT_BY_ORGANIZATION, getExponentByOrganization),
  takeLatest(types.GET_EXPONENTS_BY_ORGANIZATION, getExponentsByOrganization),
  takeLatest(types.GET_EXPONENTS, getExponents),
  takeLatest(types.GET_EXPONENTS_STATS, getExponentsStats),
  takeLatest(types.PATCH_EXPONENT, patchExponent),
  takeLatest(types.POST_EXPONENT, postExponent),
  takeLatest(types.DELETE_EXPONENT, deleteExponent),
  takeLatest(types.GET_EXPONENTS_PARTNERS, getExponentsPartners),
  takeLatest(types.PATCH_EXPONENTS_PARTNERS, patchExponentsPartners),
];
