import { LOCALES } from './constants';

const LOCAL_STORAGE = {
  recruiter: {
    hideConfirmOnParticipantMove: 'hide_confirm_on_participant_move',
    hideConfirmInteractionSoonClosed: 'hide_confirm_interaction_soon_closed',
    isEditingCalendar: 'is_editing_calendar',
  },
  candidate: {
    jobAppoitmentsCriterias: 'job_appointments_criterias',
  },
  global: {
    locale: 'locale',
    isConnected: 'isConnected',
  },
  event: {
    signUpSuccess: 'signupSuccess',
  },
};

const setLocalStorage = (key, val) => {
  window.localStorage.setItem(key, val);
};

const getLocalStorage = key => window.localStorage.getItem(key);

const removeLocalStorage = key => window.localStorage.removeItem(key);

const getLocale = () => getLocalStorage(LOCAL_STORAGE.global.locale) || LOCALES.fr;

export { setLocalStorage, getLocalStorage, removeLocalStorage, getLocale, LOCAL_STORAGE };
