import { defineMessages } from 'react-intl';

export default defineMessages({
  h1: {
    id: 'calendar.title',
  },
  excerpt: {
    id: 'calendar.excerpt',
  },
  cta: {
    id: 'calendar.cta',
  },
  h2: {
    id: 'calendar.subtitle',
  },
  moreTitle: {
    id: 'recruiter.contact.title',
  },
  moreCta: {
    id: 'recruiter.contact.btn',
  },
});
