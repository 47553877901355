import { defineMessages } from 'react-intl';

export default defineMessages({
  about: {
    id: 'footer.about',
  },
  interview: {
    id: 'footer.interview',
  },
  weRecruit: {
    id: 'footer.weRecruit',
  },
  candidate: {
    id: 'footer.candidate',
  },
  calendar: {
    id: 'header.menu.calendar',
  },
  recruiter: {
    id: 'footer.recruiter',
  },
  legal: {
    id: 'footer.legal',
  },
  legalMention: {
    id: 'footer.legalMention',
  },
  termOfUse: {
    id: 'footer.termOfUse',
  },
  privacy: {
    id: 'footer.privacy',
  },
  cookie: {
    id: 'footer.cookie',
  },
  followUs: {
    id: 'footer.followUs',
  },
  help: {
    id: 'footer.help',
  },
  media: {
    id: 'footer.media',
  },
  jobs: {
    id: 'footer.jobs',
  },
  sports: {
    id: 'footer.sports',
  },
  corporate: {
    id: 'footer.corporate',
  },
});
