import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';

import Button from '../Button';
import styles from './styles.less';

const DropdownMenu = ({ children, menuItems, triggerClass }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  return (
    <div>
      <Button
        type="submit"
        onClick={toggleDropdown}
        className={`${styles.dropbtn} ${triggerClass}`}
        variant="tonal"
      >
        {children}
        <ChevronDown />
      </Button>
      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          {menuItems.map((item, index) => (
            <a
              key={item.name + index.toString()}
              className="dropdown"
              href={item.href}
              onClick={item.onClick}
            >
              {item.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  triggerClass: PropTypes.string,
};

export default DropdownMenu;
