import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import { GET_USERS_ORGANIZATION } from '../user/types';
import * as types from './types';

const initialReducer = fromJS({
  error: null,
  currentId: null,
  calendarId: null,
  isFetching: false,
  isPatching: false,
  isInit: false,
  allIds: [],
  byUserId: {},
  facets: {},
  stats: {},
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    totalAll: 0,
    offset: 0,
  },
  lastParticipantExponentsSearch: {},
  partners: [],
});

const exponentsReducer = handleActions(
  {
    [types.SET_CURRENT_EXPONENT]: (state, { payload: { exponentId, context = 'exponent' } }) =>
      state
        .set('error', null)
        .set('isFetching', false)
        .set(context === 'calendar' ? 'calendarId' : 'currentId', exponentId),
    [types.GET_EXPONENT]: state =>
      state.set('error', null).set('currentId', null).set('isFetching', true),
    [types.GET_EXPONENTS]: (state, { payload: { noLoader } }) =>
      state.set('isFetching', noLoader !== true),
    [types.GET_EXPONENT_BY_ORGANIZATION]: state => state.set('isFetching', true),
    [types.GET_EXPONENT_BY_ORGANIZATION_SUCCESS]: state =>
      state.set('isInit', true).set('isFetching', false),
    [types.GET_EXPONENTS_BY_ORGANIZATION]: state => state.set('isFetching', true),
    [types.GET_EXPONENTS_BY_ORGANIZATION_SUCCESS]: (state, { payload, payload: { result } }) => {
      const newAllIds = state.get('allIds').clear().merge(result);

      return state
        .set('allIds', newAllIds)
        .set('isFetching', false)
        .setIn(['pagination', 'current'], payload.currentPage)
        .setIn(['pagination', 'pageSize'], payload.limit)
        .setIn(['pagination', 'offset'], payload.offset)
        .setIn(['pagination', 'total'], payload.total);
    },
    [types.GET_EXPONENT_BY_ORGANIZATION_ERROR]: state => state.set('isFetching', false),
    [types.GET_EXPONENTS_STATS_SUCCESS]: (state, { payload }) => state.set('stats', payload.stats),
    [types.PATCH_EXPONENT]: state => state.set('error', null),
    [GET_USERS_ORGANIZATION]: state => state.set('error', null),
    [types.PATCH_EXPONENT_SUCCESS]: state => state.set('error', null),
    [types.PATCH_EXPONENT_ERROR]: (state, { payload }) => state.set('error', payload),
    [types.GET_EXPONENTS_SUCCESS]: (state, { payload, payload: { facets, lastSearch } }) => {
      const newAllIds = state.get('allIds').clear().merge(payload.result);

      return state
        .set('allIds', newAllIds)
        .set('facets', facets)
        .set('isFetching', false)
        .setIn(['pagination', 'current'], payload.currentPage)
        .setIn(['pagination', 'pageSize'], payload.limit)
        .setIn(['pagination', 'offset'], payload.offset)
        .setIn(['pagination', 'total'], payload.total)
        .setIn(['pagination', 'totalAll'], payload.totalAll)
        .set('lastParticipantExponentsSearch', lastSearch);
    },
    [types.DELETE_EXPONENT_SUCCESS]: (state, { payload: exponentId }) => {
      const newAllIds = state.get('allIds').filter(id => id !== exponentId);
      return state.set('allIds', newAllIds);
    },
    [types.POST_EXPONENT_SUCCESS]: (state, { payload: exponentId }) =>
      state
        .set('error', null)
        .set('allIds', state.get('allIds').push(exponentId))
        .set('currentId', exponentId),

    // Get exponents partners
    [types.GET_EXPONENTS_PARTNERS]: state => state.set('isFetching', true),
    [types.GET_EXPONENTS_PARTNERS_SUCCESS]: (state, { payload: partners }) =>
      state.set('isFetching', false).set('partners', partners),
    [types.GET_EXPONENTS_PARTNERS_ERROR]: state => state.set('isFetching', false),

    // Patch exponents partners
    [types.PATCH_EXPONENTS_PARTNERS]: state => state.set('isPatching', true),
    [types.PATCH_EXPONENTS_PARTNERS_SUCCESS]: (state, { payload: partners }) =>
      state.set('isPatching', false).set('partners', partners),
    [types.PATCH_EXPONENTS_PARTNERS_ERROR]: state => state.set('isPatching', false),
    [types.EXPONENT_CLEAR_ERROR]: state => state.set('error', null),
  },
  initialReducer
);

export default exponentsReducer;
