import { handleActions } from 'redux-actions';

import * as types from './types';

// Initial State
const initialReducer = false;

// Upload reducer
const uploadReducer = handleActions(
  {
    [types.UPLOAD_START]: () => true,
    [types.UPLOAD_STOP]: () => false,
  },
  initialReducer,
);

export default uploadReducer;
