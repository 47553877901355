const track = ({ name, event, properties }) => {
  let eventData = {};
  // Mixpanel is disabled in dev so we log all every track
  if (
    window.location.hostname !== 'www.seekube.com' ||
    localStorage.getItem('segmentDisabled') === 'true'
  ) {
    const css =
      'font-weight: bold; font-size: 17x;color: red;text-shadow: 2px 2px 0 rgba(217, 31, 38, 0.2)';
    const cssName = 'font-weight: 600';
    /* eslint-disable no-console */
    console.groupCollapsed(`%ctracker %c${name}`, css, cssName);
    console.log({ properties });
    console.groupEnd();
    return false;
  }

  if (event) {
    eventData = {
      forum: event.name,
      forumId: event.slug,
    };
  }

  window.analytics.track(name, {
    ...properties,
    ...eventData,
  });

  return true;
};

const trackError = err => {
  if (window.location.hostname === 'www.seekube.com' && window.datadogRum) {
    window.datadogRum.addError(err, undefined, 'source');
  } else {
    console.error(err);
  }
};

export { track, trackError };
