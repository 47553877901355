import React from 'react';

import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';

import { Button, H1, H4 } from '@seekube-tech/ui-kit';

import EventsSearching from '@/components/SearchEvents';
import { DEFAULT_LOCALE } from '@/containers/App/constants';
import addUtmToLink from '@/store/availableActions/utils/event/addUtmToLink';
import { useIsMobile } from '@/utils/MediaQueries';
import useSearchEngineTracker from '@/utils/analytics/trackers/category/eventSearchEngine/useSearchEngineTracker';
import useEventsSearchEngine from '@/utils/hooks/useEventsSearchEngine';
import { objectToParams } from '@/utils/url';

import Footer from '../../components/Footer';
import Header from '../../components/HeaderV2';
import { getAppBaseUrl } from '../../utils/hostname';
import { getLandingParams } from './helpers';
import styles from './styles.less';

export const SearchEvents = () => {
  const t = useFormatMessage();
  const history = useHistory();
  const language =
    JSON.parse(window.localStorage.getItem('redux'))?.language?.locale || DEFAULT_LOCALE;
  const {
    events,
    suggestedEvents,
    hasFoundEvents,
    shouldShowNoResults,
    contracts,
    localisations,
    search,
    isEmptyFilters,
    keyword,
  } = useEventsSearchEngine();
  const appBaseUrl = getAppBaseUrl();
  const filtersParams = `?keywords=${search.keywords || ''}&filters=${[...contracts, ...localisations].join(',')}`;
  const isWiderThanMobile = !useIsMobile();
  const utmOffer = {
    source: 'seekube',
    campaign: 'discover_opportunities',
    medium: 'landing_search',
  };
  const utmGeneral = {
    source: 'seekube',
    campaign: 'learn_more',
    medium: 'landing_search',
  };
  const { onTrackShowMore, onTrackCta } = useSearchEngineTracker({
    events,
    contracts,
    localisations,
    keyword,
  });

  const onSubmit = (filters, facetKey = '') => {
    history.push({
      search: objectToParams({ ...filters, facetKey }),
    });
  };

  const getActions = event => {
    const params = getLandingParams(
      search.keywords,
      contracts,
      localisations,
      events?.data?.pages?.[0]?.offersFacets,
    );

    return (
      <div className={styles.actions}>
        <a
          href={addUtmToLink(`${appBaseUrl}/${event.slug}`, utmGeneral)}
          onClick={() => onTrackShowMore(event)}
        >
          <Button color="neutral" variant="outline" className={styles.btn}>
            {t({ id: 'customizeModal.actions.findMore' })}
          </Button>
        </a>
        <a
          href={`${addUtmToLink(`${appBaseUrl}/${event.slug}?${params}`, utmOffer)}#offers`}
          onClick={() => onTrackCta(event)}
        >
          <Button className={styles.btn}>
            {t({ id: 'searchEngine.eventsList.discoverOffers' })}
          </Button>
        </a>
      </div>
    );
  };

  return (
    <>
      <Header />
      <section className="events">
        <div className="content-flow">
          <div className="searchEngine">
            <div className="title">
              {isWiderThanMobile ? (
                <H1 className="text-basic-white">{t({ id: 'searchEngine.offers.title' })}</H1>
              ) : (
                <H4 className="text-basic-white">{t({ id: 'searchEngine.offers.title' })}</H4>
              )}
              {isWiderThanMobile && (
                <H4 className="text-basic-white">{t({ id: 'searchEngine.offers.subtitle' })}</H4>
              )}
            </div>
            <div className={`flex justify-center ${!isWiderThanMobile && 'w-full'}`}>
              <EventsSearching.SearchEngine
                contracts={contracts}
                localisations={localisations}
                keywords={search.keywords || ''}
                facets={events?.data?.pages?.[0]?.offersFacets || {}}
                onFilterChange={onSubmit}
                onSubmit={onSubmit}
              />
            </div>
          </div>
          <div className="resultsContainer">
            <EventsSearching.Results
              locale={language}
              shouldShowNoResults={shouldShowNoResults}
              isLoading={events.isFetching || suggestedEvents.isLoading}
              eventsInfiniteQuery={hasFoundEvents ? events : suggestedEvents}
              filters={[...contracts, ...localisations]}
              keywords={search.keywords || ''}
              title={
                !hasFoundEvents
                  ? t({ id: 'searchEngine.list.suggestions' })
                  : t(
                      { id: 'searchEngine.list.results.title.pl' },
                      { count: events.data?.pages?.[0]?.total },
                    )
              }
              actions={getActions}
              shouldHideOffersCount={isEmptyFilters || !hasFoundEvents}
            />
          </div>
        </div>
      </section>
      <Footer background="transparent" />
    </>
  );
};
