import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as types from './types';

const initialState = fromJS({
  currentId: null,
});

const surveyReducer = handleActions(
  {
    [types.SET_CURRENT_SURVEY]: (state, { payload: surveyId }) => state.set('currentId', surveyId),
  },
  initialState,
);

export default surveyReducer;
