import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { initialStats } from './reducer';
import { participantListSchema, participantSchema } from './schema';

const getEventReducer = state => state.get('event');
const getParticipantReducer = state => state.get('participant');
const getEntities = state => state.get('entities');

/**
 * Return current participant
 */
export const getCurrentParticipant = createSelector(
  getParticipantReducer,
  getEntities,
  (participantState, entities) => {
    const participantId = participantState.get('currentId');
    const participant = entities.getIn(['participants', participantId]);
    return denormalize(participant, participantSchema, entities);
  },
);

/**
 * Return current participant
 */
export const getSettingParticipant = createSelector(
  getParticipantReducer,
  getEntities,
  (participantState, entities) => {
    const participantId = participantState.get('settingId');
    const participant = entities.getIn(['participants', participantId]);
    return denormalize(participant, participantSchema, entities);
  },
);

/**
 * Return participants
 */
export const getParticipants = createSelector(
  getParticipantReducer,
  getEntities,
  (participantState, entities) => {
    const allIds = participantState.get('allIds');

    const participantsList = allIds.map(id => entities.getIn(['participants', id]));

    return denormalize(participantsList, participantListSchema, entities);
  },
);

/**
 * Get participant pagination
 */
export const getParticipantPagination = createSelector(getParticipantReducer, participantState =>
  participantState.get('pagination'),
);

/**
 * Return participants
 */
export const getParticipantsStats = createSelector(
  getEventReducer,
  getParticipantReducer,
  (eventState, participantState) => {
    const stats = participantState.getIn(['statsByEvent', eventState.get('currentId')]);

    return stats || initialStats;
  },
);

/**
 * Get participants facets
 */
export const getParticipantsFacets = createSelector(getParticipantReducer, participantState =>
  participantState.get('facets'),
);

/**
 * Get participants schools
 */
export const getParticipantsSchools = createSelector(getParticipantReducer, participantState =>
  participantState.get('schools'),
);

/**
 * Get all participants IDS
 */
export const getParticipantsIds = createSelector(
  getParticipantReducer,
  getEntities,
  participantState => participantState.get('allIds'),
);

/**
 * Get loader
 */
export const getParticipantsFetching = createSelector(getParticipantReducer, participantState =>
  participantState.get('isFetching'),
);

/**
 * Get loader
 */
export const getActionIsPending = createSelector(getParticipantReducer, participantState =>
  participantState.get('actionIsPending'),
);

/**
 * Get loader
 */
export const getCountNew = createSelector(getParticipantReducer, participantState =>
  participantState.get('countNew'),
);

export const getOpenParticipant = createSelector(
  getParticipantReducer,
  getEntities,
  (participantState, entities) => {
    const participantId = participantState.get('openId');

    if (participantId) {
      const participant = entities.getIn(['participants', participantId]);
      return denormalize(participant, participantSchema, entities);
    }

    return null;
  },
);

export const getParticipantFetching = createSelector(getParticipantReducer, participantState =>
  participantState.get('participantsFetching'),
);

export const getParticipantsCoreTarget = createSelector(getParticipantReducer, participantState =>
  participantState.get('participantsCoreTarget'),
);
