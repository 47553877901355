import { createSelector } from 'reselect';

const getNotificationReducer = state => state.get('notification');

const getNotificationsList = createSelector(getNotificationReducer, notificationState =>
  notificationState.get('allIds'),
);

const getNotifications = createSelector(getNotificationReducer, notificationState =>
  notificationState.get('byId'),
);

/**
 * Return list of all notifications
 */
export const getAllNotifications = createSelector(
  getNotificationsList,
  getNotifications,
  (notificationIds, notifications) => notificationIds.map(id => notifications.get(id)),
);
