export const SET_CURRENT_PARTICIPANT = 'app/store/participant/SET_CURRENT_PARTICIPANT';
export const SET_OPEN_PARTICIPANT = 'app/store/participant/SET_OPEN_PARTICIPANT';
export const SET_SETTINGS_PARTICIPANT = 'app/store/participant/SET_SETTINGS_PARTICIPANT';
export const RESET_PARTICIPANTS_LIST = 'app/store/participants/RESET_PARTICIPANTS_LIST';
export const RESET_PARTICIPANTS_STATS = 'app/store/participants/RESET_PARTICIPANTS_STATS';
export const GET_PARTICIPANT = 'app/store/participants/GET_PARTICIPANT';
export const RESET_PARTICIPANT = 'app/store/participants/RESET_PARTICIPANT';

// GET all participants
export const GET_PARTICIPANTS = 'app/store/participants/GET_PARTICIPANTS';
export const GET_CONFERENCE_PARTICIPANTS = 'app/store/participants/GET_CONFERENCE_PARTICIPANTS';
export const GET_PARTICIPANTS_SUCCESS = 'app/store/participants/GET_PARTICIPANTS_SUCCESS';
export const GET_PARTICIPANTS_ERROR = 'app/store/participants/GET_PARTICIPANTS_ERROR';

export const GET_PARTICIPANTS_FACETS = 'app/store/participants/GET_PARTICIPANTS_FACETS';
export const GET_PARTICIPANTS_FACETS_SUCCESS =
  'app/store/participants/GET_PARTICIPANTS_FACETS_SUCCESS';

export const GET_PARTICIPANTS_STATS = 'app/store/participants/GET_PARTICIPANTS_STATS';
export const GET_PARTICIPANTS_STATS_SUCCESS =
  'app/store/participants/GET_PARTICIPANTS_STATS_SUCCESS';

// PATCH a participant
export const PATCH_PARTICIPANT = 'app/store/participant/PATCH_PARTICIPANT';
export const PATCH_PARTICIPANT_SUCCESS = 'app/store/participant/PATCH_PARTICIPANT_SUCCESS';
export const PATCH_PARTICIPANT_ERROR = 'app/store/participant/PATCH_PARTICIPANT_ERROR';

// POST a participant
export const POST_PARTICIPANT = 'app/store/participant/POST_PARTICIPANT';
export const POST_PREREGISTERED_PARTICIPANT =
  'app/store/participant/POST_PREREGISTERED_PARTICIPANT';
export const POST_PREREGISTERED_PARTICIPANT_BATCH =
  'app/store/participant/POST_PREREGISTERED_PARTICIPANT_BATCH';
export const POST_PARTICIPANT_SUCCESS = 'app/store/participant/POST_PARTICIPANT_SUCCESS';
export const POST_PARTICIPANT_ERROR = 'app/store/participant/POST_PARTICIPANT_ERROR';

// INTERACT with a participant
export const SELECT_PARTICIPANT = 'app/store/participant/SELECT_PARTICIPANT';
export const SELECT_PARTICIPANT_SUCCESS = 'app/store/participant/SELECT_PARTICIPANT_SUCCESS';
export const SELECT_PARTICIPANT_ERROR = 'app/store/participant/SELECT_PARTICIPANT_ERROR';

export const SELECT_PARTICIPANTS = 'app/store/participant/SELECT_PARTICIPANTS';
export const SELECT_PARTICIPANTS_SUCCESS = 'app/store/participant/SELECT_PARTICIPANTS_SUCCESS';
export const SELECT_PARTICIPANTS_ERROR = 'app/store/participant/SELECT_PARTICIPANTS_ERROR';

export const REFUSE_PARTICIPANT = 'app/store/participant/REFUSE_PARTICIPANT';
export const REFUSE_PARTICIPANT_SUCCESS = 'app/store/participant/REFUSE_PARTICIPANT_SUCCESS';
export const REFUSE_PARTICIPANT_ERROR = 'app/store/participant/REFUSE_PARTICIPANT_ERROR';

export const REFUSE_PARTICIPANTS = 'app/store/participant/REFUSE_PARTICIPANTS';
export const REFUSE_PARTICIPANTS_SUCCESS = 'app/store/participant/REFUSE_PARTICIPANTS_SUCCESS';
export const REFUSE_PARTICIPANTS_ERROR = 'app/store/participant/REFUSE_PARTICIPANTS_ERROR';

export const VALIDATE_PARTICIPANT = 'app/store/participant/VALIDATE_PARTICIPANT';
export const VALIDATE_PARTICIPANT_SUCCESS = 'app/store/participant/VALIDATE_PARTICIPANT_SUCCESS';
export const VALIDATE_PARTICIPANT_ERROR = 'app/store/participant/VALIDATE_PARTICIPANT_ERROR';

export const HIDE_PARTICIPANTS = 'app/store/participant/HIDE_PARTICIPANTS';

export const CANCEL_PARTICIPANT = 'app/store/participant/CANCEL_PARTICIPANT';
export const CANCEL_PARTICIPANT_SUCCESS = 'app/store/participant/CANCEL_PARTICIPANT_SUCCESS';
export const CANCEL_PARTICIPANT_ERROR = 'app/store/participant/CANCEL_PARTICIPANT_ERROR';

export const VISIT_PARTICIPANT = 'app/store/participant/VISIT_PARTICIPANT';
export const VISIT_PARTICIPANT_SUCCESS = 'app/store/participant/VISIT_PARTICIPANT_SUCCESS';

export const MOVE_PARTICIPANT = 'app/store/participant/MOVE_PARTICIPANT';
export const MOVE_PARTICIPANT_SUCCESS = 'app/store/participant/MOVE_PARTICIPANT_SUCCESS';

export const MOVE_PARTICIPANTS = 'app/store/participant/MOVE_PARTICIPANTS';
export const MOVE_PARTICIPANTS_SUCCESS = 'app/store/participant/MOVE_PARTICIPANTS_SUCCESS';

export const BAN_PARTICIPANT = 'app/store/participant/BAN_PARTICIPANT';
export const BAN_PARTICIPANT_SUCCESS = 'app/store/participant/BAN_PARTICIPANT_SUCCESS';
export const BAN_PARTICIPANT_ERROR = 'app/store/participant/BAN_PARTICIPANT_ERROR';

// BATCH actions
export const BATCH_PARTICIPANTS = 'app/store/participant/BATCH_PARTICIPANTS';

// Tag admin status in participants by context
export const TAG_PARTICIPANTS_IN_INTERCOM = 'app/store/participant/TAG_PARTICIPANTS_IN_INTERCOM';

// Core target
export const TOOGLE_CORE_TARGET = 'app/store/participant/TOOGLE_CORE_TARGET';
export const TOOGLE_CORE_TARGET_SUCCESS = 'app/store/participant/TOOGLE_CORE_TARGET_SUCCESS';
export const TOOGLE_CORE_TARGET_FAILURE = 'app/store/participant/TOOGLE_CORE_TARGET_FAILURE';

export const TOOGLE_CORE_TARGETS = 'app/store/participant/TOOGLE_CORE_TARGETS';
export const TOOGLE_CORE_TARGETS_SUCCESS = 'app/store/participant/TOOGLE_CORE_TARGETS_SUCCESS';
export const TOOGLE_CORE_TARGETS_FAILURE = 'app/store/participant/TOOGLE_CORE_TARGETS_FAILURE';

export const CREATE_INFORMAL1TO1 = 'app/store/participant/CREATE_INFORMAL1TO1';
export const CREATE_INFORMAL1TO1_SUCCESS = 'app/store/participant/CREATE_INFORMAL1TO1_SUCCESS';
export const CREATE_INFORMAL1TO1_ERROR = 'app/store/participant/CREATE_INFORMAL1TO1_ERROR';
