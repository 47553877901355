import { createAction } from 'redux-actions';

import * as types from './types';

export const setParticipantSelectedOffer = createAction(types.SET_PARTICIPANT_SELECTED_OFFER);
export const deleteParticipantSelectedOffer = createAction(types.DELETE_PARTICIPANT_SELECTED_OFFER);

export const getParticipantOffers = createAction(types.GET_PARTICIPANT_OFFERS);
export const getParticipantOffersSuccess = createAction(types.GET_PARTICIPANT_OFFERS_SUCCESS);

/**
 * Action for get offers of an event
 */
export const getEventOffers = createAction(types.GET_EVENT_OFFERS);
export const getEventOffersSuccess = createAction(types.GET_EVENT_OFFERS_SUCCESS);

export const getEventOffersByMatching = createAction(types.GET_EVENT_OFFERS_BY_MATCHING);
export const getEventOffersByMatchingSuccess = createAction(
  types.GET_EVENT_OFFERS_BY_MATCHING_SUCCESS,
);

/**
 * Action for get offers of an organization in a event
 */
export const getOrganizationOffers = createAction(types.GET_ORGANIZATION_OFFERS);
export const getOrganizationOffersSuccess = createAction(types.GET_ORGANIZATION_OFFERS_SUCCESS);
export const getOrganizationOffersError = createAction(types.GET_ORGANIZATION_OFFERS_ERROR);

export const getOrganizationUserOffers = createAction(types.GET_ORGANIZATION_USER_OFFERS);
export const getOrganizationUserOffersSuccess = createAction(
  types.GET_ORGANIZATION_USER_OFFERS_SUCCESS,
);
export const getOrganizationUserOffersError = createAction(
  types.GET_ORGANIZATION_USER_OFFERS_ERROR,
);

export const getOffer = createAction(types.GET_OFFER);
export const getGlobalOffer = createAction(types.GET_GLOBAL_OFFER);
export const getGlobalOfferSuccess = createAction(types.GET_GLOBAL_OFFER_SUCCESS);

/**
 * Action for get crossforum offers (6 past month)
 */
export const getCrossForumOffer = createAction(types.GET_CROSSFORUM_OFFER);
export const getCrossForumOfferSuccess = createAction(types.GET_CROSSFORUM_OFFER_SUCCESS);
export const getCrossForumOfferError = createAction(types.GET_CROSSFORUM_OFFER_ERROR);

/**
 * Action for post new action
 */
export const patchOffer = createAction(types.PATCH_OFFER);
export const patchOfferSuccess = createAction(types.PATCH_OFFER_SUCCESS);
export const patchOfferError = createAction(types.PATCH_OFFER_ERROR);

/**
 * Action for patch new action that is not linked to a specific event
 */
export const patchGlobalOffer = createAction(types.PATCH_GLOBAL_OFFER);
export const patchGlobalOfferSuccess = createAction(types.PATCH_GLOBAL_OFFER_SUCCESS);
export const patchGlobalOfferError = createAction(types.PATCH_GLOBAL_OFFER_ERROR);

/**
 * Action for post new action
 */
export const postOffer = createAction(types.POST_OFFER);
export const postOfferSuccess = createAction(types.POST_OFFER_SUCCESS);
export const postOfferError = createAction(types.POST_OFFER_ERROR);

export const patchGlobalOfferByEvents = createAction(types.PATCH_GLOBAL_OFFER_BY_EVENTS);
export const patchGlobalOfferByEventsSuccess = createAction(
  types.PATCH_GLOBAL_OFFER_BY_EVENTS_SUCCESS,
);

export const postGlobalOffer = createAction(types.POST_GLOBAL_OFFER);
export const postGlobalOfferSuccess = createAction(types.POST_GLOBAL_OFFER_SUCCESS);
export const postGlobalOfferError = createAction(types.POST_GLOBAL_OFFER_ERROR);

export const postOffersSuccess = createAction(types.POST_OFFERS_SUCCESS);

export const deleteOffer = createAction(types.DELETE_OFFER);
export const deleteOfferSuccess = createAction(types.DELETE_OFFER_SUCCESS);

export const deleteGlobalOffer = createAction(types.DELETE_GLOBAL_OFFER);
export const deleteGlobalOfferSuccess = createAction(types.DELETE_GLOBAL_OFFER_SUCCESS);

export const duplicateOffer = createAction(types.DUPLICATE_OFFER);
export const duplicateCrossOffers = createAction(types.DUPLICATE_CROSS_OFFERS);
export const duplicateGlobalOffer = createAction(types.DUPLICATE_GLOBAL_OFFER);

export const applyOffer = createAction(types.APPLY_OFFER);
export const applyOfferWithoutValidation = createAction(types.APPLY_OFFER_WITHOUT_VALIDATION);
export const applyOfferSuccess = createAction(types.APPLY_OFFER_SUCCESS);
export const applyOfferError = createAction(types.APPLY_OFFER_ERROR);

export const setLastParticipantOfferSearch = createAction(types.SET_LAST_PARTICIPANT_OFFER_SEARCH);

export const patchDraftOffers = createAction(types.PATCH_DRAFT_OFFERS);

export const postPublishOffers = createAction(types.POST_PUBLISH_OFFERS);

export const deleteOffers = createAction(types.DELETE_OFFERS);
