export const GET_EVENT_OFFERS = 'app/store/offer/GET_EVENT_OFFERS';
export const GET_EVENT_OFFERS_SUCCESS = 'app/store/offer/GET_EVENT_OFFERS_SUCCESS';

export const GET_EVENT_OFFERS_BY_MATCHING = 'app/store/offer/GET_EVENT_OFFERS_BY_MATCHING';
export const GET_EVENT_OFFERS_BY_MATCHING_SUCCESS =
  'app/store/offer/GET_EVENT_OFFERS_BY_MATCHING_SUCCESS';

export const GET_ORGANIZATION_OFFERS = 'app/store/offer/GET_ORGANIZATION_OFFERS';
export const GET_ORGANIZATION_OFFERS_SUCCESS = 'app/store/offer/GET_ORGANIZATION_OFFERS_SUCCESS';
export const GET_ORGANIZATION_OFFERS_ERROR = 'app/store/offer/GET_ORGANIZATION_OFFERS_ERROR';

export const GET_ORGANIZATION_USER_OFFERS = 'app/store/offer/GET_ORGANIZATION_USER_OFFERS';
export const GET_ORGANIZATION_USER_OFFERS_SUCCESS =
  'app/store/offer/GET_ORGANIZATION_USER_OFFERS_SUCCESS';
export const GET_ORGANIZATION_USER_OFFERS_ERROR =
  'app/store/offer/GET_ORGANIZATION_USER_OFFERS_ERROR';

export const SET_PARTICIPANT_SELECTED_OFFER = 'app/store/offer/SET_PARTICIPANT_SELECTED_OFFER';
export const DELETE_PARTICIPANT_SELECTED_OFFER =
  'app/store/offer/DELETE_PARTICIPANT_SELECTED_OFFER';
export const GET_PARTICIPANT_OFFERS = 'app/store/offer/GET_PARTICIPANT_OFFERS';
export const GET_PARTICIPANT_OFFERS_SUCCESS = 'app/store/offer/GET_PARTICIPANT_OFFERS_SUCCESS';

export const GET_OFFER = 'app/store/GET_OFFER';

export const GET_GLOBAL_OFFER = 'app/store/GET_GLOBAL_OFFER';
export const GET_GLOBAL_OFFER_SUCCESS = 'app/store/GET_GLOBAL_OFFER_SUCCESS';

export const GET_CROSSFORUM_OFFER = 'app/store/GET_CROSSFORUM_OFFER';
export const GET_CROSSFORUM_OFFER_SUCCESS = 'app/store/GET_CROSSFORUM_OFFER_SUCCESS';
export const GET_CROSSFORUM_OFFER_ERROR = 'app/store/GET_CROSSFORUM_OFFER_ERROR';

export const PATCH_OFFER = 'app/store/PATCH_OFFER';
export const PATCH_OFFER_SUCCESS = 'app/store/PATCH_OFFER_SUCCESS';
export const PATCH_OFFER_ERROR = 'app/store/PATCH_OFFER_ERROR';

export const PATCH_GLOBAL_OFFER_BY_EVENTS = 'app/store/PATCH_GLOBAL_OFFER_BY_EVENTS';
export const PATCH_GLOBAL_OFFER_BY_EVENTS_SUCCESS =
  'app/store/PATCH_GLOBAL_OFFER_BY_EVENTS_SUCCESS';

export const PATCH_GLOBAL_OFFER = 'app/store/PATCH_GLOBAL_OFFER';
export const PATCH_GLOBAL_OFFER_SUCCESS = 'app/store/PATCH_GLOBAL_OFFER_SUCCESS';
export const PATCH_GLOBAL_OFFER_ERROR = 'app/store/PATCH_GLOBAL_OFFER_ERROR';

export const POST_OFFERS_SUCCESS = 'app/store/POST_OFFERS_SUCCESS';

export const POST_OFFER = 'app/store/POST_OFFER';
export const POST_OFFER_SUCCESS = 'app/store/POST_OFFER_SUCCESS';
export const POST_OFFER_ERROR = 'app/store/POST_OFFER_ERROR';

export const POST_GLOBAL_OFFER = 'app/store/POST_GLOBAL_OFFER';
export const POST_GLOBAL_OFFER_SUCCESS = 'app/store/POST_GLOBAL_OFFER_SUCCESS';
export const POST_GLOBAL_OFFER_ERROR = 'app/store/POST_GLOBAL_OFFER_ERROR';

export const DUPLICATE_OFFER = 'app/store/DUPLICATE_OFFER';
export const DUPLICATE_CROSS_OFFERS = 'app/store/DUPLICATE_CROSS_OFFERS';

export const DUPLICATE_GLOBAL_OFFER = 'app/store/DUPLICATE_GLOBAL_OFFER';

export const DELETE_GLOBAL_OFFER = 'app/store/DELETE_GLOBAL_OFFER';
export const DELETE_GLOBAL_OFFER_SUCCESS = 'app/store/DELETE_GLOBAL_OFFER_SUCCESS';
export const DELETE_OFFER = 'app/store/DELETE_OFFER';
export const DELETE_OFFER_SUCCESS = 'app/store/DELETE_OFFER_SUCCESS';

export const APPLY_OFFER = 'app/store/APPLY_OFFER';
export const APPLY_OFFER_SUCCESS = 'app/store/APPLY_OFFER_SUCCESS';
export const APPLY_OFFER_ERROR = 'app/store/APPLY_OFFER_ERROR';

export const APPLY_OFFER_WITHOUT_VALIDATION = 'app/store/APPLY_OFFER_WITHOUT_VALIDATION';

export const SET_LAST_PARTICIPANT_OFFER_SEARCH = 'app/store/SET_LAST_PARTICIPANT_OFFER_SEARCH';

export const PATCH_DRAFT_OFFERS = 'app/store/PATCH_DRAFT_OFFERS';

export const POST_PUBLISH_OFFERS = 'app/store/POST_PUBLISH_OFFERS';

export const DELETE_OFFERS = 'app/store/DELETE_OFFERS';
