import React from 'react';

import { array, func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Button, IconSearch, InputSearch, Select } from '@seekube-tech/ui-kit';

import { CustomOption } from '../CustomOption/CustomOption';
import styles from './styles.less';

const Desktop = ({
  selected,
  onChange,
  search,
  onSearch,
  searchHits = [],
  contractsSelected,
  setContractsSelected,
  contractOptions,
  localisationSelected,
  setLocalisationSelected,
  localisationOptions,
  handleSearchEvents,
}) => {
  const t = useFormatMessage();
  const isOptionSelected = (options, opt) => options.includes(opt.value);

  const renderOption = (
    options,
    opt,
    { key, onClick, onSelect, active } = {},
    withCheckbox = false,
  ) => {
    const isSelected = withCheckbox && isOptionSelected(options, opt);

    return (
      <CustomOption
        withCheckbox={withCheckbox}
        disabled={parseInt(opt.count, 10) === 0 && !isSelected}
        opt={opt}
        onSelect={onSelect || onClick}
        id={key}
        selected={withCheckbox && isSelected}
        active={active}
      />
    );
  };

  return (
    <div className={styles.search}>
      <form className={styles.inputs} onSubmit={e => e.preventDefault()}>
        <div className={styles.grid5}>
          <InputSearch
            autocomplete
            search={search}
            onChange={onChange}
            value={selected}
            onSearch={onSearch}
            name="job"
            id="job"
            label={t({ id: 'searchEngine.keywords.label' })}
            required={false}
            className={`${styles.customSearch} ${styles.left}`}
            options={searchHits}
            renderOption={(opt, { key, onClick, active }) =>
              renderOption(null, opt, { key, active, onClick }, false)
            }
          />
        </div>
        <div className={styles.grid3}>
          <Select
            name="searchEngine.contracts"
            id="searchEngine.contracts"
            value={contractsSelected}
            onChange={setContractsSelected}
            label={t({ id: 'searchEngine.contracts.label' })}
            required={false}
            className={`${styles.grid3} ${styles.customSearch}`}
            options={contractOptions}
            renderOption={(opt, { key, onSelect }) =>
              renderOption(contractsSelected, opt, { key, onSelect }, true)
            }
            multiple
            showCounter
          />
        </div>
        <div className={styles.grid3}>
          <Select
            name="searchEngine.localisation"
            id="searchEngine.localisation"
            value={localisationSelected}
            onChange={setLocalisationSelected}
            label={t({ id: 'searchEngine.localisation.label' })}
            required={false}
            className={`${styles.grid3} ${styles.customSearch}`}
            options={localisationOptions}
            renderOption={(opt, { key, onSelect }) =>
              renderOption(localisationSelected, opt, { key, onSelect }, true)
            }
            multiple
            showCounter
          />
        </div>
        <div className={styles.grid1}>
          <Button
            type="submit"
            onClick={handleSearchEvents}
            className={styles.customSearchBtn}
            imageComponentLeft={<IconSearch size={24} />}
          />
        </div>
      </form>
    </div>
  );
};

Desktop.propTypes = {
  selectedSearch: func,
  setSelectedSearch: func,
  inputSearch: string,
  onSearch: func,
  searchHits: array,
  contractsSelected: array,
  setContractsSelected: func,
  contractOptions: array,
  localisationSelected: array,
  setLocalisationSelected: func,
  localisationOptions: array,
  handleSearchEvents: func,
};

export { Desktop };
