import { schema } from 'normalizr';

// import { eventSchema } from '../event/schema';

export const exponentSchema = new schema.Entity(
  'exponents',
  {
    // _event: eventSchema,
  },
  { idAttribute: '_id' },
);

export const exponentListSchema = [exponentSchema];
