import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as types from './types';

const initialState = fromJS({
  defaultIds: [],
  suggestions: [],
  mergeList: [],
  mergeListTotal: 0,
});

const criterionReducer = handleActions(
  {
    [types.GET_DEFAULT_CRITERIA_SUCCESS]: (state, { payload }) =>
      state.set('defaultIds', state.get('defaultIds').merge(payload)),
    [types.GET_MERGE_LIST_SUCCESS]: (state, { payload }) =>
      state
        .set('suggestions', payload.suggestions)
        .set('mergeList', payload.docs)
        .set('mergeListTotal', payload.total),
  },
  initialState,
);

export default criterionReducer;
