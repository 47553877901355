/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import React from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import moment from 'moment';
import 'moment-business-days';
import 'moment-countdown';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { IntlProvider as IntlProviderHooks } from 'react-intl-hooks';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentLocale } from './selectors';

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * Component Will update
   *
   * @description
   * When the locale changed, we update moment
   */
  componentDidUpdate({ locale }) {
    moment.updateLocale(locale, {
      workingWeekdays: [1, 2, 3, 4, 5, 6],
    });

    dayjs.updateLocale(locale, {
      workingWeekdays: [1, 2, 3, 4, 5, 6],
    });
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale]}
      >
        <IntlProviderHooks
          locale={this.props.locale}
          messages={this.props.messages[this.props.locale]}
          defaultLocale="en"
        >
          {React.Children.only(this.props.children)}
        </IntlProviderHooks>
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: currentLocale,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
