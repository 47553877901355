import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import { useGetPublicEvents } from '@/queries/events/useGetPublicEvents';
import { useInfiniteGetPublicEventsSuggestions } from '@/queries/events/useInfiniteGetPublicEventsSuggestions';
import { queryStringToObject } from '@/utils/url';

const useEventsSearchEngine = ({
  authUser = null,
  enableSuggestionSearch = false,
  ...filters
} = {}) => {
  const location = useLocation();
  const search = queryStringToObject(location.search);
  const contracts =
    typeof search.contracts !== 'object' && !isEmpty(search.contracts)
      ? [search.contracts]
      : search.contracts || [];
  const localisations =
    typeof search.localisations !== 'object' && !isEmpty(search.localisations)
      ? [search.localisations]
      : search.localisations || [];
  const isEmptyFilters = isEmpty(contracts) && isEmpty(localisations) && isEmpty(search.keywords);

  const events = useGetPublicEvents({
    filters: !isEmpty(filters)
      ? filters
      : {
          filters: contracts,
          MOBILITY: localisations,
          keywords: search.keywords,
          facetKey: search.facetKey,
        },
    authUser,
  });

  const suggestedEvents = useInfiniteGetPublicEventsSuggestions({
    enabled:
      (events.isFetched && !events.data?.pages?.[0]?.total) ||
      (enableSuggestionSearch && isEmptyFilters),
    authUser,
    isEmptyFilters,
  });
  const hasFoundEvents = !events.isLoading && events.data?.pages?.[0]?.total > 0;
  const shouldShowNoResults =
    !suggestedEvents.isFetching && !hasFoundEvents && !isEmpty(events.data);

  return {
    events,
    suggestedEvents,
    hasFoundEvents,
    shouldShowNoResults,
    contracts,
    localisations,
    search,
    isEmptyFilters,
    keyword: search.keywords,
  };
};

export default useEventsSearchEngine;
