import React from 'react';

import { useFormatMessage } from 'react-intl-hooks';
import { Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@seekube-tech/ui-kit';

import { useIsMobile } from '@/utils/MediaQueries';

import logo from '../../assets/svg/logo.svg';
import logoWithoutName from '../../assets/svg/logo_without_name.svg';
import { getAppBaseUrl } from '../../utils/hostname';
import styles from './styles.less';

const Header = () => {
  const t = useFormatMessage();
  const isMobile = useIsMobile();
  const appBaseUrl = getAppBaseUrl();

  const seekubeLogo = isMobile ? logoWithoutName : logo;

  const signUpCandidateUrl = `${appBaseUrl}/auth/signup/candidate`;
  const loginCandidateUrl = `${appBaseUrl}/auth/login/candidate`;
  const loginRecruiterUrl = `${appBaseUrl}/auth/login/recruiter`;
  const loginOwnerUrl = `${appBaseUrl}/auth/login/owner`;

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <Link to="/">
          <img src={seekubeLogo} alt="Seekube" />
        </Link>
        <div className={styles.actions}>
          <a href={signUpCandidateUrl}>
            <Button variant="outline" color="neutral">
              {t({ id: 'header.menu.signup_candidate' })}
            </Button>
          </a>
          <Dropdown>
            <DropdownTrigger>
              <Button variant="fill">{t({ id: 'header.menu.signin' })}</Button>
            </DropdownTrigger>
            <DropdownContent>
              <DropdownItem>
                <a href={loginCandidateUrl}>{t({ id: 'header.menu.connect_candidate' })}</a>
              </DropdownItem>
              <DropdownItem>
                <a href={loginRecruiterUrl}>{t({ id: 'header.menu.connect_recruiter' })}</a>
              </DropdownItem>
              <DropdownItem>
                <a href={loginOwnerUrl}>{t({ id: 'header.menu.connect_owner' })}</a>
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
