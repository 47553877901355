import { useInfiniteQuery } from 'react-query';

import fetchService from '@/services/fetch';
import request from '@/utils/request';

export function useInfiniteGetPublicEventsSuggestions({ limit = 6, authUser, ...params }) {
  const queryFn = pageParams => {
    const { pageParam: page } = pageParams;
    const params = `?limit=${limit}&page=${page}`;

    const requestUrl = `${process.env.FRONT_API_URL}/events/public/suggestions${params}`;

    if (!authUser) {
      return fetchService(requestUrl, {
        method: 'GET',
      }).then(response => response.json());
    }

    return request(requestUrl, {
      method: 'GET',
    });
  };

  const query = useInfiniteQuery({
    queryKey: ['publicEventsSuggestions', params],
    keepPreviousData: true,
    getNextPageParam: lastPage => {
      if (lastPage.page * lastPage.limit < lastPage.total) {
        return lastPage.page + 1;
      }
    },
    select: data => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    refetchOnWindowFocus: false,
    ...params,
  });
  return query;
}
