import React from 'react';

import mazarsLogo from '../../../assets/svg/companies/mazars.svg';
import orangeLogo from '../../../assets/svg/companies/orange.svg';
import societegeneraleLogo from '../../../assets/svg/companies/societegenerale.svg';
import thalesLogo from '../../../assets/svg/companies/thales.svg';
import vinciLogo from '../../../assets/svg/companies/vinci.svg';
import styles from '../styles.less';

const Partners = () => (
  <section className="partners">
    <div className="content_flow">
      <ul className={styles.companyLogo}>
        <li>
          <img src={mazarsLogo} alt="Mazars Logo" />
        </li>
        <li>
          <img src={orangeLogo} alt="Orange Logo" />
        </li>
        <li>
          <img src={societegeneraleLogo} alt="Société Générale Logo" />
        </li>
        <li>
          <img src={thalesLogo} alt="Thales Logo" />
        </li>
        <li>
          <img src={vinciLogo} alt="Vinci Logo" />
        </li>
      </ul>
    </div>
  </section>
);

export { Partners };
