import React, { useEffect } from 'react';

import { push } from 'connected-react-router';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { trackingUserEventData } from '@/utils/analytics/trackers/trackingUserEventData';
import { GTM_EVENTS } from '@/utils/constants';
import { getLocale } from '@/utils/localStorage';
import { trackGtmEvent } from '@/utils/pixels/helloWork';

import { appSelectors } from '../../../app/store/app';
import toJS from '../../../app/utils/toJS';
import phoneVideo from '../../assets/video/phone_bd.gif';
import Footer from '../../components/Footer';
import Header from '../../components/HeaderV2';
import { HowItWorks } from './components/HowItWorks';
import { Jobdatings } from './components/Jobdatings';
import { Partners } from './components/Partners';
import { SearchEvents } from './components/SearchEvents';
import { Verbatim } from './components/Verbatim';

const Home = ({ countAppointments }) => {
  const t = useFormatMessage();

  useEffect(() => {
    trackGtmEvent(GTM_EVENTS.virtualPageDisplay, {
      ...trackingUserEventData(),
      page_subject: 'home',
      page_category: 'landing',
    });
  }, []);

  const Badge = (
    <div className="countmeet">
      <span>
        <b role="img">😎</b>
        {countAppointments}
      </span>
      {t({ id: 'footer.interview' })}
    </div>
  );

  return (
    <>
      <Helmet htmlAttributes={{ lang: getLocale() }}>
        <meta charSet="utf-8" />
        <title>{`Seekube - ${t({ id: 'home.jobdating.title' })}`}</title>
        <meta name="description" content={t({ id: 'home.jobdating.text' })} />
      </Helmet>
      <Header badge={<Badge />}>
        <div className="col-left">
          <div className="text">
            <h1>{t({ id: 'home.header.h1' })}</h1>
            <div className="baseline">{t({ id: 'home.header.baseline' })}</div>
          </div>
        </div>
        <div className="phone">
          <div>
            <img src={phoneVideo} alt="Mobile" />
          </div>
        </div>
      </Header>
      <SearchEvents />
      <HowItWorks />
      <Jobdatings />
      <Verbatim />
      <Partners />
      <Footer />
    </>
  );
};

Home.propTypes = {
  countAppointments: string,
};

const mapDispatchToProps = {
  push,
};

const mapStateToProps = createStructuredSelector({
  countAppointments: appSelectors.getCountAppointments,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect, injectIntl)(toJS(Home));
