import React from 'react';

import moment from 'moment/moment';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Body1, IconCalendar } from '@seekube-tech/ui-kit';

import { EVENT_FORMAT } from '@/utils/constants';

import styles from '../../styles.less';

const EventDates = ({ format, keyDates }) => {
  const t = useFormatMessage();

  if (format === EVENT_FORMAT.HYBRID || !keyDates) {
    return null;
  }

  return (
    <div className={styles.infoItem}>
      <IconCalendar className="text-neutral-300" size={16} />
      <Body1 className="text-neutral-500">
        {t(
          { id: 'searchEngine.list.event.dates' },
          {
            dateFrom: moment(keyDates.jobfair.beginAt).format('DD MMMM YYYY'),
            dateTo: moment(keyDates.jobfair.endAt).format('DD MMMM YYYY'),
          },
        )}
      </Body1>
    </div>
  );
};

EventDates.propTypes = {
  format: string,
  keyDates: object,
};

export { EventDates };
