import React from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { array, bool, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Alert, AvatarBusiness, Caption1, CardLive, H3 } from '@seekube-tech/ui-kit';

import LoadingIndicator from '@/components/LoadingIndicator';
import { EventCompanies } from '@/components/SearchEvents/Results/components/EventCompanies';
import { EventDates } from '@/components/SearchEvents/Results/components/EventDates';
import { EventFormat } from '@/components/SearchEvents/Results/components/EventFormat';
import { NoResults } from '@/components/SearchEvents/Results/components/NoResults/NoResults';
import { ShowMoreButton } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import useSearchEngineTracker from '@/utils/analytics/trackers/category/eventSearchEngine/useSearchEngineTracker';

import { getAppBaseUrl } from '../../../../public/utils/hostname';
import styles from '../styles.less';
import { getCdnUrl } from '@/components/ImgCdn';
import { AvatarSizes } from '@/types/utils/avatarTypes.types';

const Results = ({
  eventsInfiniteQuery,
  shouldHideOffersCount,
  title,
  isLoading,
  shouldShowNoResults,
  locale,
  actions,
}) => {
  const t = useFormatMessage();
  moment.locale('fr');
  const appBaseUrl = getAppBaseUrl();
  const hasResults = !isEmpty(eventsInfiniteQuery?.data?.pages?.[0]?.docs);
  const { onTrackShowMore } = useSearchEngineTracker();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {shouldShowNoResults && <NoResults />}
      <div className={styles.eventsListContainer}>
        {title && hasResults && (
          <H3 className={styles.title} color="#3C4E73">
            {title}
          </H3>
        )}
        <div className={styles.eventsLayout}>
          {eventsInfiniteQuery?.data?.pages?.map(page =>
            page?.docs?.map(event => (
              <div key={event.slug}>
                <CardLive
                  className="w-auto justify-between h-full"
                  contentClassName="h-auto"
                  pictureUrl={event.theme?.coverPictureUrl || event.theme?.pictureUrl || ''}
                  description={event.calendarB2CDescription?.[locale]}
                  infosOrga={
                    <div className={styles.infosOrga}>
                      <div className={styles.head}>
                        <AvatarBusiness
                          className={styles.left}
                          pictureUrl={getCdnUrl(event.planners?._organizations?.[0]?.profile.pictureUrl, AvatarSizes.SMALL)}
                        />
                        <div className={styles.right}>
                          <Caption1 className="text-neutral-300">
                            {t(
                              { id: 'organizedBy' },
                              { name: event.planners?._organizations?.[0]?.name },
                            )}
                          </Caption1>
                        </div>
                      </div>
                      {!shouldHideOffersCount && (
                        <Alert color="success" isClosable={false} className={styles.alert}>
                          <span className={styles.text}>
                            {event.countMatchingOffers}{' '}
                            {t(
                              { id: 'searchEngine.eventsList.alert.pl' },
                              { count: event.countMatchingOffers },
                            )}
                          </span>
                        </Alert>
                      )}
                    </div>
                  }
                  title={
                    <a
                      className={styles.title}
                      href={`${appBaseUrl}/${event.slug}`}
                      onClick={() => onTrackShowMore(event)}
                    >
                      {event.name}
                    </a>
                  }
                  infosLive={
                    <div className={styles.infosContainer}>
                      <EventDates {...event} />
                      <EventFormat {...event} />
                      <EventCompanies
                        count={event.countExponents}
                        companyName={event.exponentsName?.[0]}
                      />
                    </div>
                  }
                  actions={actions(event)}
                />
              </div>
            )),
          )}
        </div>
        <ShowMoreButton
          entityInfiniteQuery={eventsInfiniteQuery}
          label={t({ id: 'searchEvents.list.showMore' })}
          variant="outline"
        />
      </div>
    </>
  );
};

Results.propTypes = {
  eventsInfiniteQuery: object,
  shouldHideOffersCount: bool,
  title: string,
  isSuggest: bool,
  localisations: array,
  keywords: string,
  isLoading: bool,
  locale: string,
  shouldShowNoResults: bool,
  authUser: object,
  actions: func,
};

export { Results };
