import { schema } from 'normalizr';

import { eventSchema } from '../event/schema';

export const participantSchema = new schema.Entity(
  'participants',
  {
    _event: eventSchema,
  },
  { idAttribute: '_id' },
);
export const participantListSchema = [participantSchema];
