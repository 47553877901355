import { Map, fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';

import * as exponentTypes from '../exponent/types';
import { organizationSchema } from '../organization/schema';
import * as organizationTypes from '../organization/types';
import * as types from './types';

// Initial state
const initialState = fromJS({
  events: new Map(),
  organizations: new Map(),
  criteria: new Map(),
  exponents: new Map(),
  users: new Map(),
  timeslots: new Map(),
  templates: new Map(),
  interactions: new Map(),
  app: new Map(),
  survey: new Map(),
  conferences: new Map(),
  attendees: new Map(),
});

/**
 * Entities reducer
 */
const entitiesReducer = handleActions(
  {
    [types.MERGE_ENTITIES]: (state, { payload: entities }) => {
      const objects = entities;

      if (objects && objects.events) {
        const { events } = objects;

        Object.keys(events).forEach(eventId => {
          const event = events[eventId];

          ['jobfair', 'discovering', 'hunt'].forEach(key => {
            if (event.keyDates && event.keyDates[key]) {
              events[eventId].keyDates[key].beginAt = moment(
                new Date(event.keyDates[key].beginAt).getTime(),
              )
                .utcOffset(moment(event.keyDates[key].beginAt).tz('Europe/Paris').utcOffset())
                .toDate();
              events[eventId].keyDates[key].endAt = moment(
                new Date(event.keyDates[key].endAt).getTime(),
              )
                .utcOffset(moment(event.keyDates[key].endAt).tz('Europe/Paris').utcOffset())
                .toDate();
            }
          });
        });

        objects.events = events;
      } else if (!isEmpty(objects.users)) {
        return state.set('users', state.get('users').merge(fromJS(objects.users)));
      }

      return state.mergeDeep(fromJS(objects));
    },
    [organizationTypes.PUSH_ORGANIZATION]: (state, { payload: organization }) => {
      const dataNormalized = normalize(organization, organizationSchema);

      return state.mergeDeep(fromJS(dataNormalized.entities));
    },
    [exponentTypes.DELETE_EXPONENT_SUCCESS]: (state, { payload: exponentId }) =>
      state.set('exponents', state.get('exponents').delete(exponentId)),
    [types.REPLACE_ENTITIES]: (state, { payload: { id, type, entities } }) => {
      const entityId = typeof id === 'object' && id._id ? id._id : id;

      return state.setIn([type, entityId], fromJS(entities[type][entityId]));
    },
  },
  initialState,
);

export default entitiesReducer;
