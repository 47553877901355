import { push } from 'connected-react-router';
import { flatten, isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { currentLocale } from '@/containers/LanguageProvider/selectors';
import { CONTEXT_URLS } from '@/utils/constants';
import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';
import request, { isInternalServerError } from '@/utils/request';
import { objectToParams } from '@/utils/url';

import { trackError } from '../../utils/analytics/helpers';
import { authSelectors } from '../auth';
import { entitiesActions } from '../entities';
import { eventActions, eventSelectors } from '../event';
import { interactionActions } from '../interaction';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { participantListSchema, participantSchema } from './schema';
import * as participantSelectors from './selectors';
import * as types from './types';

/**
 * GET participant
 */
function* getParticipant({
  payload: { eventId, userId, noRedirect, callback, redirectUrl = null, openProfile = false },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${userId}`;
  const authUser = yield select(authSelectors.getAuthUser);

  if (!eventId) {
    return eventId;
  }

  try {
    const result = yield call(
      request,
      requestUrl,
      {
        method: 'GET',
      },
      [404],
    );

    if (redirectUrl && result.context) {
      const redirectContext = [
        'confirmed',
        'unconfirmed',
        'interview',
        'refused',
        'score0',
        'score1',
        'score2',
      ].includes(result.context)
        ? CONTEXT_URLS[result.context]
        : result.context;

      window.open(
        `${redirectUrl}${redirectContext}?participant=${result._id}&getprofile=true`,
        '_blank',
      );

      return true;
    }

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    if (result._user) {
      yield put(
        interactionActions.getInteractions({
          eventSlug: eventId,
          clear: false,
          body: { users: [result._user._id] },
        }),
      );
    }

    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );

    if (openProfile) {
      yield put(actions.setOpenParticipant(result._id));

      if (eventId) {
        yield put(actions.getParticipantsStats({ eventId }));
      }

      if (result._event) {
        yield put(eventActions.setCurrentEvent(result._event._id));
      }
    }

    if (eventId) {
      yield put(actions.setCurrentParticipant(result._id));
    } else {
      yield put(actions.setSettingsParticipant(result._id));
    }

    if (typeof callback === 'function') {
      callback(result);
    }
  } catch (err) {
    yield put(actions.setCurrentParticipant(null));

    if (!noRedirect) {
      if (authUser && !authUser._currentOrganization) {
        // yield put(push(`/${eventId}/candidate/preparation/participation`));
      }
    }

    if (typeof callback === 'function') {
      callback(err);
    }
  }

  return eventId;
}

function* getParticipantsStats({ payload: { eventId, context } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/stats?context=${context}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(actions.getParticipantsStatsSuccess(result));
  } catch (err) {
    trackError(err);
  }
}

/**
 * GET participants list
 */
function* getParticipants({
  payload: {
    eventId,
    offset,
    page,
    context,
    search,
    timeout,
    limit,
    hideIds,
    facetIds,
    callback,
    clear = true,
  },
}) {
  const searchParams = objectToParams(search);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants?context=${context === 'pending' ? 'interview' : context}&offset=${offset}&page=${page}&${searchParams}&timeout=${timeout}=&limit=${limit}&hideIds=${hideIds ? JSON.stringify(hideIds) : ''}`;
  const event = yield select(eventSelectors.getCurrentEvent);
  const authUser = yield select(authSelectors.getAuthUser);
  const _organization = authUser.get('_currentOrganization').get('_id');

  try {
    yield put(actions.getParticipantsStats({ eventId, context }));

    const { docs, limit, total, facets, schools, countNew } = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(docs, participantListSchema);
    const userIds = docs.map(p => (p && p._user ? p._user._id : null));

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(
      actions.getParticipantsSuccess({
        event,
        result: dataNormalized.result,
        currentPage: page,
        facetKey: search ? search.facetKey : null,
        facets,
        schools,
        offset,
        limit,
        total,
        countNew,
        facetIds,
        facetSchools: typeof search.schools === 'string' ? [search.schools] : search.schools,
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }

    yield put(
      interactionActions.getInteractions({
        eventSlug: eventId,
        context: 'multi',
        clear,
        body: { users: flatten(userIds), _organization },
      }),
    );
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(
        notificationActions.sendNotification({
          message: err.message,
          kind: 'error',
          style: {},
        }),
      );
    }
  }
}

/**
 * GET participants list
 */
function* getParticipantsFacets({
  payload: { eventId, offset, page, context, search, limit, hideIds, callback },
}) {
  const searchParams = objectToParams(search);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/facets?context=${context}&offset=${offset}&page=${page}&${searchParams}&limit=${limit}&hideIds=${hideIds ? JSON.stringify(hideIds) : ''}`;

  try {
    yield put(actions.getParticipantsStats({ eventId, context }));

    const { facets } = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Merge allIds
    yield put(
      actions.getParticipantsFacetsSuccess({
        facets,
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(
        notificationActions.sendNotification({
          message: err.message,
          kind: 'error',
          style: {},
        }),
      );
    }
  }
}

/**
 * GET participants list
 */
function* getConferenceParticipants({
  payload: { conferenceId, offset, page, context, search, limit, facetIds, callback },
}) {
  const searchParams = objectToParams(search);
  const requestUrl = `${process.env.FRONT_API_URL}/conferences/${conferenceId}/participants?context=${context}&offset=${offset}&page=${page}&${searchParams}&limit=${limit}`;

  const authUser = yield select(authSelectors.getAuthUser);
  const _organization = authUser.get('_currentOrganization').get('_id');

  try {
    const { docs, limit, total, facets, schools, countNew, conference } = yield call(
      request,
      requestUrl,
      {
        method: 'GET',
      },
    );

    const eventId = conference._event._id;

    yield put(eventActions.getEvent(eventId));

    // Normaliz data
    const dataNormalized = normalize(docs, participantListSchema);
    const userIds = docs.map(p => (p && p._user ? p._user._id : null));

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(
      actions.getParticipantsSuccess({
        event: conference._event,
        result: dataNormalized.result,
        currentPage: page,
        facetKey: search ? search.facetKey : null,
        facets,
        schools,
        offset,
        limit,
        total,
        countNew,
        facetIds,
        facetSchools: typeof search.schools === 'string' ? [search.schools] : search.schools,
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }

    yield put(
      interactionActions.getInteractions({
        eventSlug: eventId,
        context: 'multi',
        body: { users: flatten(userIds), _organization },
      }),
    );
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(
        notificationActions.sendNotification({
          message: err.message,
          kind: 'error',
          style: {},
        }),
      );
    }
  }
}

/**
 * Patch a participant
 */
function* patchParticipant({
  payload: {
    eventId,
    redirect,
    slug,
    participantId,
    participantParams,
    notificationParams,
    callback,
  },
}) {
  const authParticipant = yield select(participantSelectors.getCurrentParticipant);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId || authParticipant._id}`;

  const headers = {
    'Content-Type': 'application/json',
  };

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(participantParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (typeof callback === 'function') {
      callback();
    }

    // Go to the settings page ?
    if (redirect && redirect === 'event') {
      const event = yield select(eventSelectors.getCurrentEvent);
      const participantRedirection = event.get('participantRedirection');
      let url = `/${slug}/candidate/jobdating/jobs`;
      if (participantRedirection === 'offer') {
        url = `/${slug}/candidate/jobdating/jobs`;
      } else if (participantRedirection === 'informal1to1') {
        url = `/${slug}/candidate/jobdating/informal1to1`;
      } else if (participantRedirection === 'company') {
        url = `/${slug}/candidate/jobdating/exponents`;
      } else if (participantRedirection === 'live') {
        url = `/${slug}/candidate/jobdating/conferences/discovery`;
      }
      setLocalStorage(LOCAL_STORAGE.event.signUpSuccess, 'true');
      yield put(push(url));
    }

    yield put(actions.patchParticipantSuccess());

    return result;
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Post participant
 */
function* postParticipant({
  payload: { eventId, toPost, slug, redirect, notificationParams, callback },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants`;

  // Post the event
  const body = toPost;
  const authUser = yield select(authSelectors.getAuthUser);

  if (authUser) {
    body._user = authUser.get('_id');
  }

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postParticipantSuccess(dataNormalized.result));
    yield put(actions.setCurrentParticipant(result._id));

    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (typeof callback === 'function') {
      callback();
    }

    // Go to the settings page ?
    if (redirect && redirect === 'event') {
      if (result.isActive) {
        setLocalStorage(LOCAL_STORAGE.event.signUpSuccess, 'true');
        yield put(push(`/${result._event.participantRedirectionUrl}`));
      }
    }
  } catch (err) {
    trackError(err);
  }
}

function* postPreregistration({ payload: { eventId, toPost } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/preregistration`;
  const locale = yield select(currentLocale);

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...toPost, locale }),
    });

    yield put(eventActions.isAuthUserPreregisteredSuccess(true));
  } catch (err) {
    trackError(err);
  }
}

function* postPreregistrationBatch({ payload: { eventIds, userId, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/preregistration`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eventIds, userId }),
    });

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * moveParticipant
 *
 * scoring { appointmentId, score, message }
 */
function* moveParticipant({
  payload: {
    eventId,
    participantId,
    scoring = null,
    context,
    notificationParams,
    offset,
    status,
    nextProfile,
    page,
    search,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/move`;
  const switchUser = yield select(authSelectors.getAuthUserNotConnectedAs);

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ status, switchUser, scoring }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    yield put(actions.getParticipantsStats({ eventId }));
    // Si le score est accompagné d'un message alors il faut refetch les interactions pour mettre à jour la carte
    if (!isEmpty(scoring) && !isEmpty(scoring.message)) {
      yield put(
        interactionActions.getInteractions({
          eventSlug: eventId,
          body: { users: [result._user._id] },
        }),
      );
    }

    if (nextProfile && isEmpty(scoring)) {
      const redirect = context;
      const event = yield select(eventSelectors.getCurrentEvent);
      const updatedSearch = search;

      updatedSearch.participant = nextProfile._id;

      const searchParams = objectToParams(updatedSearch);
      const nextUrl = `/${event.get('slug')}/recruiter/jobdating/${redirect}?page=${page}&${searchParams}`;

      yield put(push(nextUrl));
    }

    yield put(actions.moveParticipantSuccess({ participantId, context }));

    yield put(
      actions.getParticipants({
        eventId,
        context,
        offset,
        page,
        search,
        hideIds: [participantId],
        facetIds: search.filters,
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

function* moveParticipants({
  payload: {
    eventId,
    participantIds,
    batchAll,
    offset,
    page,
    context,
    notificationParams,
    status,
    locationQuery,
    search,
    clearList = false,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/move?context=${context}`;
  const switchUser = yield select(authSelectors.getAuthUserNotConnectedAs);

  // Patch the event
  try {
    yield put(actions.moveParticipantsSuccess({ participantIds, context }));

    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        status,
        switchUser,
        participantIds,
        batchAll,
        page,
        search,
        locationQuery,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result.docs, participantListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    const params = {
      eventId,
      context,
      offset,
      page,
      search,
      hideIds: participantIds,
      facetIds: search.filters,
      callback,
    };

    if (clearList) {
      yield put(actions.getParticipants({ ...params }));
    } else {
      yield put(actions.getParticipantsFacets({ ...params }));
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Patch an event
 */
function* selectParticipant({
  payload: {
    eventId,
    participantId,
    _candidate,
    context,
    notificationParams,
    body,
    offset,
    nextProfile,
    locationQuery,
    page,
    search,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/select?context=${context}`;
  const authUser = yield select(authSelectors.getAuthUser);

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(
        Object.assign(body, {
          locationQuery,
          _candidate,
          _recruiter: body.user && body.user._user._id,
          ownerId: authUser.get('_id'),
        }),
      ),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );

    if (context === 'sourcing' || context === 'applications') {
      const params = {
        eventId,
        context,
        offset,
        page,
        search,
        hideIds: [participantId],
        facetIds: search.filters,
        callback,
      };

      yield put(actions.getParticipantsFacets({ ...params }));
      yield put(actions.getParticipants({ ...params }));
    } else {
      yield put(actions.selectParticipantSuccess({ participantId, context }));
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    yield put(actions.getParticipantsStats({ eventId }));

    if (nextProfile) {
      const redirect = body.withoutRdv ? 'sourcing' : context;
      const event = yield select(eventSelectors.getCurrentEvent);
      const updatedSearch = search;

      updatedSearch.participant = nextProfile._id;

      const searchParams = objectToParams(updatedSearch);
      const nextUrl = `/${event.get('slug')}/recruiter/jobdating/${redirect}?page=${page}&${searchParams}`;

      yield put(push(nextUrl));
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

function* selectParticipants({
  payload: {
    eventId,
    recruiterId,
    message,
    batchAll,
    participantIds,
    page,
    offset,
    search,
    offerId,
    context,
    notificationParams,
    locationQuery,
    clearList = false,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/select?context=${context}`;

  // Patch the event
  try {
    yield put(actions.hideParticipants({ participantsIds: participantIds, context }));

    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        recruiterId,
        participantIds,
        offerId,
        message,
        locationQuery,
        batchAll,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (typeof callback === 'function') {
      callback();
    }

    if (context === 'sourcing') {
      const params = {
        eventId,
        context,
        offset,
        page,
        search,
        hideIds: participantIds,
        facetIds: search.filters,
        callback,
      };

      if (clearList) {
        yield put(actions.getParticipants({ ...params }));
      } else {
        yield put(actions.getParticipantsFacets({ ...params }));
      }
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    yield put(actions.getParticipantsStats({ eventId }));
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Refuse a participant
 */
function* refuseParticipant({
  payload: {
    eventId,
    participantId,
    context,
    notificationParams,
    nextProfile,
    locationQuery,
    offset,
    page,
    search,
    body,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/refuse?context=${context}`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(Object.assign(body, { locationQuery })),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    yield put(
      actions.getParticipants({
        eventId,
        context,
        offset,
        page,
        search,
        hideIds: [participantId],
        facetIds: search.filters,
      }),
    );

    yield put(actions.getParticipantsStats({ eventId }));

    if (nextProfile) {
      const event = yield select(eventSelectors.getCurrentEvent);
      const updatedSearch = search;
      const redirectContext = ['confirmed', 'unconfirmed', 'interview', 'refused'].includes(context)
        ? `interview/${context}`
        : context;

      updatedSearch.participant = nextProfile._id;

      const searchParams = objectToParams(updatedSearch);
      const nextUrl = `/${event.get('slug')}/recruiter/jobdating/${redirectContext}?page=${page}&${searchParams}`;

      yield put(push(nextUrl));
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

function* refuseParticipants({
  payload: {
    eventId,
    recruiterId,
    participantIds,
    offerId,
    batchAll,
    message,
    context,
    notificationParams,
    locationQuery,
    callback,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/refuse?context=${context}`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        recruiterId,
        participantIds,
        offerId,
        message,
        batchAll,
        locationQuery,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (typeof callback === 'function') {
      callback();
    }

    yield put(actions.getParticipantsStats({ eventId }));
    // yield put(actions.refuseParticipantSuccess({ participantIds, context }));
    yield put(actions.hideParticipants({ participantsIds: participantIds, context }));
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Visit a participant
 */
function* visitParticipant({ payload: { eventId, participantId, context } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/visit`;

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ context }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Batch actions for selected participants
 */
function* batchParticipants({
  payload: {
    selectedParticipants,
    actionType,
    eventId,
    allSelected,
    queryString,
    data,
    context,
    notificationParams,
  },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/action`;
  const bodyData = {
    action: actionType,
    userIds: selectedParticipants,
    globalSelection: allSelected,
    params: queryString,
    eventId,
    data,
    context,
  };

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(bodyData),
    });

    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (actionType === 'refuse' || actionType === 'select') {
      yield put(actions.hideParticipants({ participantsIds: selectedParticipants }));
    }
  } catch (err) {
    trackError(err);
  }
}

function* cancelParticipant({
  payload: { interactionId, participantId, offerId, userId, eventId, callback },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/cancel`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ interactionId, offerId }),
    });

    yield put(actions.getParticipant({ userId, eventId }));

    yield put(
      interactionActions.getInteractions({ eventSlug: eventId, body: { users: [userId] } }),
    );

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

function* banParticipant({
  payload: { eventId, participantId, participantParams, notificationParams, callback },
}) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/ban`;

  const headers = {
    'Content-Type': 'application/json',
  };

  // Patch the event
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(participantParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, participantSchema);
    yield put(actions.banParticipantSuccess());

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: result._id,
        type: 'participants',
        entities: dataNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    if (notificationParams && notificationParams.error) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.error.message,
          kind: notificationParams.error.kind,
          style: notificationParams.error.style,
        }),
      );
    }
    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Tag admin status in participants by context
 */
function* tagParticipantsInIntercom({ payload: { eventId, context, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/tagParticipantsInIntercom`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ context }),
    });

    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);
  }
}

function* toogleCoreTarget({ payload: { eventId, participantId, isCoreTarget } }) {
  try {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/${participantId}/coreTarget`;

    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        isCoreTarget,
      }),
    });

    yield put(actions.toogleCoreTargetSuccess({ participantId, result }));
  } catch (error) {
    trackError(error);

    yield put(actions.toogleCoreTargetFailure({ participantId }));
  }
}

function* toogleCoreTargets({ payload: { eventId, participantIds, isCoreTarget, batchAll } }) {
  try {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/participants/coreTarget`;

    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        isCoreTarget,
        participantIds,
        batchAll,
      }),
    });

    let participantsCoreTarget = yield select(participantSelectors.getParticipantsCoreTarget);

    Object.keys(result).forEach(key => {
      participantsCoreTarget = participantsCoreTarget.setIn([key], result[key]);
    });

    yield put(actions.toogleCoreTargetsSuccess({ participantsCoreTarget }));
  } catch (error) {
    trackError(error);

    yield put(actions.toogleCoreTargetsFailure());
  }
}

function* createInformal1To1({ payload: { eventId, recruiterId, organizationId } }) {
  try {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/appointments/informal1to1`;
    const event = yield select(eventSelectors.getCurrentEvent);

    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        recruiterId,
        organizationId,
      }),
    });

    yield put(actions.createInformal1To1Success());
    yield put(
      push(`/${event.get('slug')}/candidate/jobdating/appointment/${result.appointmentId}`),
    );
  } catch (error) {
    trackError(error);

    yield put(actions.createInformal1To1Error());
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_PARTICIPANT, getParticipant),
  takeLatest(types.GET_PARTICIPANTS, getParticipants),
  takeLatest(types.GET_CONFERENCE_PARTICIPANTS, getConferenceParticipants),
  takeLatest(types.GET_PARTICIPANTS_STATS, getParticipantsStats),
  takeLatest(types.GET_PARTICIPANTS_FACETS, getParticipantsFacets),
  takeLatest(types.PATCH_PARTICIPANT, patchParticipant),
  takeLatest(types.POST_PARTICIPANT, postParticipant),
  takeLatest(types.SELECT_PARTICIPANT, selectParticipant),
  takeLatest(types.SELECT_PARTICIPANTS, selectParticipants),
  takeLatest(types.REFUSE_PARTICIPANT, refuseParticipant),
  takeLatest(types.REFUSE_PARTICIPANTS, refuseParticipants),
  takeLatest(types.VISIT_PARTICIPANT, visitParticipant),
  takeLatest(types.MOVE_PARTICIPANT, moveParticipant),
  takeLatest(types.MOVE_PARTICIPANTS, moveParticipants),
  takeLatest(types.BATCH_PARTICIPANTS, batchParticipants),
  takeLatest(types.CANCEL_PARTICIPANT, cancelParticipant),
  takeLatest(types.POST_PREREGISTERED_PARTICIPANT, postPreregistration),
  takeLatest(types.POST_PREREGISTERED_PARTICIPANT_BATCH, postPreregistrationBatch),
  takeLatest(types.TAG_PARTICIPANTS_IN_INTERCOM, tagParticipantsInIntercom),
  takeLatest(types.BAN_PARTICIPANT, banParticipant),
  takeLatest(types.TOOGLE_CORE_TARGET, toogleCoreTarget),
  takeLatest(types.TOOGLE_CORE_TARGETS, toogleCoreTargets),
  takeLatest(types.CREATE_INFORMAL1TO1, createInformal1To1),
];
