// Set current event
export const SET_CURRENT_EVENT = 'app/store/event/SET_CURRENT_EVENT';
export const SET_CALENDAR_EVENT = 'app/store/event/SET_CALENDAR_EVENT';
export const SET_PLANNING_EVENT = 'app/store/event/SET_PLANNING_EVENT';

// GET an event
export const GET_EVENT = 'app/store/event/GET_EVENT';
export const GET_EVENT_SUCCESS = 'app/store/event/GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'app/store/event/GET_EVENT_ERROR';

// GET all events
export const GET_EVENTS = 'app/store/events/GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'app/store/events/GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'app/store/events/GET_EVENTS_ERROR';

// PATCH an event
export const PATCH_EVENT = 'app/store/event/PATCH_EVENT';
export const PATCH_EVENT_SUCCESS = 'app/store/event/PATCH_EVENT_SUCCESS';
export const PATCH_EVENT_ERROR = 'app/store/event/PATCH_EVENT_ERROR';

// POST an event
export const POST_EVENT = 'app/store/event/POST_EVENT';
export const POST_EVENT_SUCCESS = 'app/store/event/POST_EVENT_SUCCESS';
export const POST_EVENT_ERROR = 'app/store/event/POST_EVENT_ERROR';

// DUPLICATE an event
export const DUPLICATE_EVENT = 'app/store/event/DUPLICATE_EVENT';
export const DUPLICATE_EVENT_SUCCESS = 'app/store/event/DUPLICATE_EVENT_SUCCESS';
export const DUPLICATE_EVENT_ERROR = 'app/store/event/DUPLICATE_EVENT_ERROR';

// Get auth events
export const GET_AUTH_USER_EVENTS = 'app/store/event/GET_AUTH_USER_EVENTS';
export const GET_AUTH_USER_EVENTS_SUCCESS = 'app/store/event/GET_AUTH_USER_EVENTS_SUCCESS';
export const GET_AUTH_USER_EVENTS_ERROR = 'app/store/event/GET_AUTH_USER_EVENTS_ERROR';

// Count auth events
export const COUNT_AUTH_USER_EVENTS = 'app/store/event/COUNT_AUTH_USER_EVENTS';
export const COUNT_AUTH_USER_EVENTS_SUCCESS = 'app/store/event/COUNT_AUTH_USER_EVENTS_SUCCESS';
export const COUNT_AUTH_USER_EVENTS_ERROR = 'app/store/event/COUNT_AUTH_USER_EVENTS_ERROR';

export const IS_AUTH_USER_PREREGISTERED = 'app/store/event/IS_AUTH_USER_PREREGISTERED';
export const IS_AUTH_USER_PREREGISTERED_SUCCESS =
  'app/store/event/IS_AUTH_USER_PREREGISTERED_SUCCESS';
export const IS_AUTH_USER_PREREGISTERED_ERROR = 'app/store/event/IS_AUTH_USER_PREREGISTERED_ERROR';

export const EXPORT_RESUMES = 'app/store/event/EXPORT_RESUMES';
export const EXPORT_RESUMES_SUCCESS = 'app/store/event/EXPORT_RESUMES_SUCCESS';
export const EXPORT_RESUMES_ERROR = 'app/store/event/EXPORT_RESUMES_ERROR';

export const SET_FETCHING = 'app/store/event/SET_FETCHING';
