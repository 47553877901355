import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { useGetDefaultCriteria } from '@/queries/criterion/useGetDefaultCriteria';
import { useGetUserEventsParticipation } from '@/queries/users/useGetUserEventsParticipation';
import { getAuthUser } from '@/store/auth/selectors';
import { trackingUserEventData } from '@/utils/analytics/trackers/trackingUserEventData';
import {
  GTM_EVENTS,
  GTM_EVENTS_INFOS,
  GTM_EVENTS_NAME,
  GTM_EVENT_PAGE_CATEGORY,
  GTM_EVENT_PAGE_SUBJECT,
} from '@/utils/constants';
import { trackGtmEvent } from '@/utils/pixels/helloWork';

const useSearchEngineTracker = ({ events, contracts, localisations, keyword } = {}) => {
  const criteria = useGetDefaultCriteria();
  const authUser = useSelector(getAuthUser)?.toJS();
  const participations = useGetUserEventsParticipation({
    userId: authUser?._id,
    enabled: !isEmpty(authUser),
  });

  useEffect(() => {
    if (!events?.data || events?.isFetching) {
      return;
    }

    const contractOptions =
      criteria.data?.find(criteria => criteria.key === 'CONTRACT')?._choices || [];
    const localisationOptions =
      criteria.data?.find(criteria => criteria.key === 'MOBILITY')?._choices || [];

    const contractSelected = contractOptions.filter(contract => contracts.includes(contract._id));
    const localisationsSelected = localisationOptions.filter(localisation =>
      localisations.includes(localisation._id),
    );

    trackGtmEvent(GTM_EVENTS.virtualPageDisplay, {
      ...trackingUserEventData(null, authUser, participations.data),
      page_subject: GTM_EVENT_PAGE_SUBJECT.result,
      page_category: GTM_EVENT_PAGE_CATEGORY.searchEvent,
      search_what: keyword || '',
      search_contract: contractSelected?.map(({ label }) => label)?.join('|') || '',
      search_where: localisationsSelected?.map(({ label }) => label)?.join('|') || '',
      events_count: events.data.pages?.[0]?.total ?? '',
      opportunities_count: events.data.pages?.[0]?.offersFacets?.status?.published ?? '',
    });
  }, [events?.data, events?.isFetching]);

  const onTrackShowMore = event => {
    trackGtmEvent(GTM_EVENTS_NAME.redirect, {
      ...trackingUserEventData(event, authUser, participations.data),
      event_info: GTM_EVENTS_INFOS.readMoreLanding,
      jobdating_name: event.name,
    });
  };

  const onTrackCta = (event, info) => {
    const eventInfo = info ?? (isEmpty(authUser) ? GTM_EVENTS_INFOS.viewOfferLanding : '');

    trackGtmEvent(GTM_EVENTS_NAME.redirect, {
      ...trackingUserEventData(event, authUser, participations.data),
      event_info: eventInfo,
      jobdating_name: event.name,
    });
  };

  return {
    onTrackShowMore,
    onTrackCta,
  };
};

export default useSearchEngineTracker;
