import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Actions for get a sector
 */
export const getSectors = createAction(types.GET_SECTORS);
export const getSectorsSuccess = createAction(types.GET_SECTORS_SUCCESS);
export const getSectorsError = createAction(types.GET_SECTORS_ERROR);
