import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.less';

export function Button({ children, className, onClick, type, variant = 'fill', linkTo }) {
  if (linkTo) {
    return (
      <a className={`${styles.btn} ${className} ${styles[variant]}`} href={linkTo}>
        {children}
      </a>
    );
  }
  return (
    <button
      type={type}
      className={`${styles.btn} ${className} ${styles[variant]}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  linkTo: PropTypes.string,
};

export default Button;
