import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { appointmentListSchema } from './schema';

const getAppointmentReducer = state => state.get('appointment');
const getEntities = state => state.get('entities');

/**
 * Return the appointment
 */
export const getAppointment = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('appointment'),
);

/**
 * Return the timeslots for user selection
 */
export const getTimeslots = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('timeslots'),
);

/**
 * Return the selected timeslot
 */
export const getSelectedTimelot = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('selectedTimeslot'),
);

/**
 * Return organization user appointments
 */
export const getUserAppointments = createSelector(
  getAppointmentReducer,
  getEntities,
  (appointmentState, entities) => {
    const userIds = appointmentState.get('userIds');
    const appointmentList = userIds.map(id => entities.getIn(['appointments', id]));

    return denormalize(appointmentList, appointmentListSchema, entities);
  },
);

/**
 * Return the timeslots for user selection
 */
export const getIsFetching = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('isFetching'),
);

/**
 * Return the timeslots for user selection
 */
export const getLoadMore = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('loadMore'),
);

/**
 * Return the appointment
 */
export const getAppointmentPagination = createSelector(getAppointmentReducer, appointmentState =>
  appointmentState.get('pagination'),
);

export const getAppointments = createSelector(
  getAppointmentReducer,
  getEntities,
  (appointmentState, entities) => {
    const allIds = appointmentState.get('allIds');
    const appointmentList = allIds.map(id => entities.getIn(['appointments', id]));

    return denormalize(appointmentList, appointmentListSchema, entities);
  },
);
