import { isEmpty } from 'lodash';

const getImageUrl = (fileUrl, filterParams = '') => {
  const filePath = fileUrl.replace(`https://${process.env.FRONT_AWS_S3_BUCKET}/`, '');

  if (isEmpty(filterParams)) {
    return fileUrl;
  }

  return `${process.env.FRONT_AWS_IMANGE_HANDLER_URL}${filterParams}${filePath}`;
};

const getImageSize = size => {
  if (size <= 50) {
    return 100;
  }
  if (size <= 100) {
    return 150;
  }
  if (size < 300) {
    return 250;
  }
  if (size < 600) {
    return 500;
  }
  if (size < 1000) {
    return 960;
  }

  return 1500;
};

export { getImageUrl, getImageSize };
