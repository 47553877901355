import React, { useEffect, useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { array, func, object, string } from 'prop-types';
import GoogleMapLoader from 'react-google-maps-loader';
import { useFormatMessage } from 'react-intl-hooks';
import { useDebounce } from 'react-use';

import { getGoogleMapAddress } from '@/features/gmaps/helpers/getGoogleMapAddress';
import { useGetDefaultCriteria } from '@/queries/criterion/useGetDefaultCriteria';
import { useIsMobile } from '@/utils/MediaQueries';
import { algoliaSearch } from '@/utils/algolia';
import { CRITERIA } from '@/utils/constants';

import { Desktop } from './Desktop/Desktop';
import { Mobile } from './Mobile/Mobile';
import { getAlgoliaHitsChoices } from './utils';

const SearchEngineComponent = ({
  facets,
  keywords,
  contracts = [],
  localisations,
  onSubmit,
  onFilterChange,
  googleMaps = {},
}) => {
  const isWiderThanMobile = !useIsMobile();
  const [contractsSelected, setContractsSelected] = useState(contracts);
  const [localisationSelected, setLocalisationSelected] = useState(
    localisations ? { label: localisations[0], value: localisations[0] } : '',
  );
  const [contractOptions, setContractOptions] = useState([]);
  const [localisationOptions, setLocalisationOptions] = useState([]);
  const [searchHits, setSearchHits] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selected, setSelected] = useState({ label: keywords, value: keywords });
  const criteria = useGetDefaultCriteria();
  const t = useFormatMessage();
  const timeout = useRef();

  useDebounce(() => setDebouncedSearch(inputSearch), 300, [inputSearch]);

  useEffect(() => {
    algoliaSearch(debouncedSearch, hits => setSearchHits(getAlgoliaHitsChoices(hits)));
  }, [debouncedSearch]);

  useEffect(() => {
    if (isEmpty(facets)) {
      return;
    }
    const contractFamiliesFacets = facets.contractFamilies ?? {};

    setContractOptions(
      Object.entries(contractFamiliesFacets).map(([contract, { count }]) => ({
        value: contract,
        label: t({ id: `contracts.families.${contract}` }),
        count,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facets, criteria.data]);

  const handleSelectKeyword = value => {
    const newValue = value === null ? '' : (value?.label ?? keywords);
    setSelected(value);
    onSubmit?.({
      keywords: newValue,
      contracts,
      localisations,
    });
  };

  const handleClickSearch = () => {
    onSubmit?.({
      contracts: contractsSelected,
      localisations: localisationSelected?.label || '',
      keywords: inputSearch || selected?.label || '',
    });
  };

  const handleLocalisationSelected = localisation => {
    setLocalisationSelected(localisation);
    onFilterChange({
      keywords,
      contracts,
      localisations: localisation?.label,
    });
  };

  const handleContractsSelected = contracts => {
    setContractsSelected(contracts);
    onFilterChange(
      {
        keywords,
        contracts,
        localisations,
      },
      CRITERIA.CONTRACT,
    );
  };

  const handleLocalisationSearchChange = value => {
    if (value.length >= 3) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(async () => {
        const cities = await getGoogleMapAddress(googleMaps, value);
        setLocalisationOptions(cities);
      }, 500);
    }
  };

  if (isWiderThanMobile) {
    return (
      <Desktop
        selected={selected}
        onChange={handleSelectKeyword}
        search={inputSearch}
        onSearch={setInputSearch}
        searchHits={searchHits}
        contractsSelected={contractsSelected}
        setContractsSelected={handleContractsSelected}
        contractOptions={contractOptions}
        localisationSelected={localisationSelected}
        setLocalisationSelected={handleLocalisationSelected}
        localisationOptions={localisationOptions}
        handleLocalisationSearchChange={handleLocalisationSearchChange}
        handleSearchEvents={handleClickSearch}
      />
    );
  }

  return (
    <Mobile
      selected={selected}
      onChange={handleSelectKeyword}
      search={inputSearch}
      onSearch={setInputSearch}
      searchHits={searchHits}
      contractsSelected={contractsSelected}
      setContractsSelected={handleContractsSelected}
      contractOptions={contractOptions}
      localisationSelected={localisationSelected}
      setLocalisationSelected={handleLocalisationSelected}
      localisationOptions={localisationOptions}
      handleLocalisationSearchChange={handleLocalisationSearchChange}
      handleSearchEvents={handleClickSearch}
    />
  );
};

SearchEngineComponent.propTypes = {
  keywords: string,
  contracts: array,
  localisations: string,
  onSubmit: func,
  facets: object,
  onFilterChange: func,
  googleMaps: object,
};

export const SearchEngine = GoogleMapLoader(SearchEngineComponent, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
