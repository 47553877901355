import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { sectorListSchema } from './schema';

const getSectorReducer = state => state.get('sector');
const getEntities = state => state.get('entities');
export const getFacets = state => state.get('exponent').get('facets');

/**
 * Return current exponent
 */
export const getSectors = createSelector(
  getSectorReducer,
  getEntities,
  getFacets,
  (exponentState, entities, facets) => {
    const sectors = facets ? facets['organizationProfile.sectors._id'] : [];

    let sectorList = [];

    if (sectors) {
      sectorList = Object.keys(sectors).map(id => entities.getIn(['sectors', id]));
    }

    return denormalize(sectorList, sectorListSchema, entities);
  },
);

export const getAllSectors = createSelector(getSectorReducer, getEntities, sectorState =>
  sectorState.get('list'),
);
