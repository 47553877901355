import React from 'react';

import { useFormatMessage } from 'react-intl-hooks';

import { H4, Subtitle1 } from '@seekube-tech/ui-kit';

import styles from './styles.less';

const NoResults = () => {
  const t = useFormatMessage();

  return (
    <div className={styles.titles}>
      <H4 className={styles.title}>{t({ id: 'searchEngine.noResults' })}</H4>
      <Subtitle1 className={styles.subTitle}>
        {t({ id: 'searchEngine.noResults.subtitle' })}
      </Subtitle1>
    </div>
  );
};

export { NoResults };
