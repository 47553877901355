import { createSelector } from 'reselect';

const getV1KpiReducer = state => state.get('v1Kpi');

/**
 * Return events v1Kpi
 */
export const getEvents = createSelector(getV1KpiReducer, eventState => eventState.get('events'));

/**
 * Return contacts v1Kpi
 */
export const getContacts = createSelector(getV1KpiReducer, eventState =>
  eventState.get('contacts'),
);
