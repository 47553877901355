/**
 * public.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Import all the third party stuff
import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { ConnectedRouter } from 'connected-react-router/immutable';
import createHistory from 'history/createBrowserHistory';
import ReactDOM from 'react-dom';
// Import Language Provider
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';

import '@seekube-tech/ui-kit/dist/index.css';

import LanguageProvider from '../app/containers/LanguageProvider';
import configureStore from './configureStore';
import App from './containers/App';
import './containers/App/oldstyles.less';
// Import i18n messages
import { translationMessages } from './i18n';
// Import Avenir fonts
import './less/fonts.less';

// SegmentIO Snippet
!(function () {
  // eslint-disable-line
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    // eslint-disable-line
    if (analytics.invoked)
      window.console && console.error && console.error('Segment snippet included twice.');
    else {
      // eslint-disable-line
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t, e) {
        const n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        const o = document.getElementsByTagName('script')[0];
        o.parentNode.insertBefore(n, o);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0'; // eslint-disable-line
      analytics.load(process.env.FRONT_SEGMENT_PUBLIC_API_KEY); // eslint-disable-line
    }
  }

  // Set your APP_ID
  const APP_ID = process.env.FRONT_INTERCOM_APP_ID;

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })(); // eslint-disable-line
})();

if (process.env.NODE_ENV === 'production') {
  window.datadogRum = datadogRum;

  window.datadogRum.init({
    applicationId: process.env.FRONT_DATADOG_APP_ID,
    clientToken: process.env.FRONT_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'www.seekube.com',
    sampleRate: 100,
    trackInteractions: true,
  });
}

// Create redux store with history
const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ConnectedRouter>
    </LanguageProvider>
  </Provider>,
  document.getElementById('app'),
);
