import { createAction } from 'redux-actions';

import * as types from './types';

// GET app
export const getApp = createAction(types.GET_APP);
export const getAppSuccess = createAction(types.GET_APP_SUCCESS);
export const getAppError = createAction(types.GET_APP_ERROR);

export const cleanFetchError = createAction(types.CLEAN_FETCH_ERROR);
export const setFetchError = createAction(types.SET_FETCH_ERROR);

export const getPublicAppointments = createAction(types.GET_PUBLIC_APPOINTMENTS);

/**
 * Action for set current app
 */
export const setCurrentApp = createAction(types.SET_CURRENT_APP);

// PATCH app
export const patchApp = createAction(types.PATCH_APP);
export const patchAppSuccess = createAction(types.PATCH_APP_SUCCESS);
export const patchAppError = createAction(types.PATCH_APP_ERROR);
