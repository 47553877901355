// Get appointment
export const GET_APPOINTMENT = 'app/store/appointment/GET_APPOINTMENT';
export const GET_APPOINTMENT_SUCCESS = 'app/store/appointment/GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_ERROR = 'app/store/appointment/GET_APPOINTMENT_ERROR';

export const GET_APPOINTMENTS = 'app/store/appointment/GET_APPOINTMENTS';
export const GET_APPOINTMENTS_SUCCESS = 'app/store/appointment/GET_APPOINTMENTS_SUCCESS';

// Select slot
export const SELECT_APPOINTMENT_SLOT = 'app/store/appointment/SELECT_APPOINTMENT_SLOT';
export const SELECT_APPOINTMENT_SLOT_SUCCESS =
  'app/store/appointment/SELECT_APPOINTMENT_SLOT_SUCCESS';
export const SELECT_APPOINTMENT_SLOT_ERROR = 'app/store/appointment/SELECT_APPOINTMENT_SLOT_ERROR';

export const DOWNLOAD_APPOINTMENTS = 'app/store/appointment/DOWNLOAD_APPOINTMENTS';
export const DOWNLOAD_APPOINTMENTS_SUCCESS = 'app/store/appointment/DOWNLOAD_APPOINTMENTS_SUCCESS';
export const DOWNLOAD_APPOINTMENTS_ERROR = 'app/store/appointment/DOWNLOAD_APPOINTMENTS_ERROR';

// Appointment events
export const CANCEL_APPOINTMENT = 'app/store/appointment/CANCEL_APPOINTMENT';
export const MODIFY_APPOINTMENT = 'app/store/appointment/MODIFY_APPOINTMENT';
export const MODIFY_APPOINTMENT_SUCCESS = 'app/store/appointment/MODIFY_APPOINTMENT_SUCCESS';

export const GET_USER_APPOINTMENTS = 'app/store/appointment/GET_USER_APPOINTMENTS';
export const GET_USER_APPOINTMENTS_SUCCESS = 'app/store/appointment/GET_USER_APPOINTMENTS_SUCCESS';
export const GET_USER_APPOINTMENTS_ERROR = 'app/store/appointment/GET_USER_APPOINTMENTS_ERROR';

export const PATCH_APPOINTMENT = 'app/store/PATCH_APPOINTMENT';
export const PATCH_APPOINTMENT_SUCCESS = 'app/store/PATCH_APPOINTMENT_SUCCESS';
export const PATCH_APPOINTMENT_ERROR = 'app/store/PATCH_APPOINTMENT_ERROR';

export const PATCH_APPOINTMENTS = 'app/store/PATCH_APPOINTMENTS';
export const PATCH_APPOINTMENTS_SUCCESS = 'app/store/PATCH_APPOINTMENTS_SUCCESS';
export const PATCH_APPOINTMENTS_ERROR = 'app/store/PATCH_APPOINTMENTS_ERROR';

export const REFUSE_APPOINTMENT = 'app/store/REFUSE_APPOINTMENT';
export const REFUSE_APPOINTMENT_SUCCESS = 'app/store/REFUSE_APPOINTMENT_SUCCESS';
export const REFUSE_APPOINTMENT_ERROR = 'app/store/REFUSE_APPOINTMENT_ERROR';

export const PATCH_APPOINTMENT_DATE = 'app/store/PATCH_APPOINTMENT_DATE';
export const PATCH_APPOINTMENT_DATE_SUCCESS = 'app/store/PATCH_APPOINTMENT_DATE_SUCCESS';
export const PATCH_APPOINTMENT_DATE_ERROR = 'app/store/PATCH_APPOINTMENT_DATE_ERROR';

export const CHANGE_APPOINTMENT_OWNER = 'app/store/CHANGE_APPOINTMENT_OWNER';
export const CHANGE_APPOINTMENT_OWNER_SUCCESS = 'app/store/CHANGE_APPOINTMENT_OWNER_SUCCESS';
export const CHANGE_APPOINTMENT_OWNER_ERROR = 'app/store/CHANGE_APPOINTMENT_OWNER_ERROR';

export const RELAUNCH_APPOINTMENT = 'app/store/RELAUNCH_APPOINTMENT';
export const RELAUNCH_APPOINTMENT_SUCCESS = 'app/store/RELAUNCH_APPOINTMENT_SUCCESS';
export const RELAUNCH_APPOINTMENT_ERROR = 'app/store/RELAUNCH_APPOINTMENT_ERROR';

export const CONFIRM_APPOINTMENT = 'app/store/CONFIRM_APPOINTMENT';
export const CONFIRM_APPOINTMENT_SUCCESS = 'app/store/CONFIRM_APPOINTMENT_SUCCESS';
export const CONFIRM_APPOINTMENT_ERROR = 'app/store/CONFIRM_APPOINTMENT_ERROR';
