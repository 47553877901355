import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Action for get templates
 */
export const getRoles = createAction(types.GET_ROLES);
export const getRolesSuccess = createAction(types.GET_ROLES_SUCCESS);
export const getRolesError = createAction(types.GET_ROLES_ERROR);
