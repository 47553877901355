import { isEmpty } from 'lodash';

import { store } from '@/configureStore';
import { authLogoutUser } from '@/store/auth/actions';
import { initialState } from '@/store/auth/reducer';
import { USERS_TYPE } from '@/utils/constants';
import { LOCAL_STORAGE } from '@/utils/localStorage';

export const redirectUnAuthUser = user => {
  store.dispatch(authLogoutUser());

  setUserConnectionStatus(false);
  setUserSwitchedStatus(false);

  if (isEmpty(user)) {
    return window.location.replace('/auth/login/candidate');
  }

  const savedLocation = window.location.pathname;
  const userType = user?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;

  localStorage.setItem(
    'redux',
    JSON.stringify({ ...JSON.parse(window.localStorage.getItem('redux')), auth: initialState }),
  );
  return window.location.replace(`/auth/login/${userType}?redirect=${savedLocation}`);
};

export const setUserConnectionStatus = (isConnected = true, user = null) => {
  if (isConnected && isEmpty(user)) {
    return;
  }
  window.localStorage.setItem(LOCAL_STORAGE.global.isConnected, isConnected ? 'true' : 'false');
};

export const setUserSwitchedStatus = (isSwitchedMode = true) => {
  window.localStorage.setItem(
    LOCAL_STORAGE.global.isSwitchedMode,
    isSwitchedMode ? 'true' : 'false',
  );
};

export const isUserSwitchedMode = () => {
  return window.localStorage.getItem(LOCAL_STORAGE.global.isSwitchedMode) === 'true';
};
