import { endsWith, find } from 'lodash';

import { trackGtmEvent } from '@/utils/pixels/helloWork';

import { CONTINENT_LOCATION } from '../../constants';
import { getCookieConsent } from '../../cookie';
import { getId } from '../../global';

const cookieConsent = getCookieConsent();

const listenPages = (history, authUser, cookieConsent) => {
  const urlsNotToFireWhenParamChange = [
    '/recruiter/jobdating/sourcing',
    '/recruiter/jobdating/selection',
    '/recruiter/jobdating/applications',
    '/candidate/jobdating/jobs',
    '/candidate/jobdating/exponent',
  ];

  const eventName = location.pathname;
  // Push page to analytcs on page refresh
  window.analytics?.page(eventName);

  // Push page to analytics each time url changes
  history.listen((location, action) => {
    if (location && action !== 'REPLACE') {
      let isTriggered = true;
      if (
        location.search !== '' &&
        urlsNotToFireWhenParamChange.filter(url => endsWith(location.pathname, url)).length
      ) {
        isTriggered = false;
      }
      if (isTriggered) {
        if (window.location.hostname === 'app.seekube.com') {
          window.analytics?.page(location.pathname, {
            url: location.pathname,
          });
        }

        if (cookieConsent && cookieConsent.marketing) {
          const excludePath = ['/seekalendar'];

          const pathSize = location.pathname.split('/').length;

          // Prevent to track /:eventSlug && /seekalendar/:eventSlug
          if (!(pathSize <= 2 && excludePath.includes(location.pathname))) {
            if (
              !excludePath.includes(location.pathname) &&
              location.pathname.indexOf('/seekalendar/') === -1
            ) {
              trackGtmEvent('page.display', {
                user_id_back: authUser?._id || '',
                path: location.pathname,
              });
            }
          }
        }
      }
    }
  });

  return true;
};

const getUserData = user => {
  if (!user) {
    return {
      role: 'visitor',
    };
  }

  let role = 'candidate';

  if (user.isAdmin) {
    role = 'admin';
  } else if (user._currentOrganization) {
    role = 'recruiter';
  }

  return {
    _id: user._id,
    organization: user._currentOrganization ? user._currentOrganization.name : null,
    organizationId: user._currentOrganization ? user._currentOrganization._id : null,
    signupDate: user.createdAt,
    role,
  };
};

const track = ({ user, name, event, properties, intercom = false }) => {
  let eventData = {};
  // Mixpanel is disabled in dev so we log all every track
  if (
    !cookieConsent ||
    !cookieConsent.statistics ||
    window.location.hostname !== 'app.seekube.com' ||
    localStorage.getItem('segmentDisabled') === 'true'
  ) {
    const css =
      'font-weight: bold; font-size: 17x;color: red;text-shadow: 2px 2px 0 rgba(217, 31, 38, 0.2)';
    const cssName = 'font-weight: 600';
    /* eslint-disable no-console */
    console.groupCollapsed(`%ctracker %c${name}`, css, cssName);
    console.log({ properties, userId: getId(user), eventId: getId(event) });
    console.groupEnd();

    return false;
  }

  if (event) {
    eventData = {
      forum: event.name,
      forumId: event.slug,
    };
  }

  if (intercom && window.Intercom) {
    window.Intercom('trackEvent', name);
  }

  window.analytics.track(name, {
    userId: user ? user._id : 'null',
    ...getUserData(user),
    ...properties,
    ...eventData,
  });

  return true;
};

const identify = user => {
  if (window.location.hostname !== 'app.seekube.com') {
    return false;
  }

  if (!user) {
    return false;
  }

  window.analytics.track({
    userId: user._id,
    traits: getUserData(user),
  });

  return true;
};

const getContinentFromLocation = location => {
  if (location) {
    const country = find(location.address_components, ac => ac.types[0] === 'country');

    if (country) {
      const continent = CONTINENT_LOCATION[country.short_name];

      if (continent) {
        return continent;
      }
    }
  }

  return null;
};

const trackError = (err, context) => {
  if (window.location.hostname === 'app.seekube.com') {
    window.datadogRum.addError(err, context);
  } else {
    console.error(err);
  }
};

export { track, trackError, identify, listenPages, getContinentFromLocation };
