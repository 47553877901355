import { fromJS } from 'immutable';
import { uniq } from 'lodash';
import { handleActions } from 'redux-actions';

import * as offerTypes from '../offer/types';
import * as types from './types';

// Initial State
const initialReducer = fromJS({
  error: {},
  currentId: null,
  settingId: null,
  isFetching: false,
  actionIsPending: false,
  allIds: [],
  interactionId: null,
  byUserId: {},
});

// Events reducer
export default handleActions(
  {
    [types.GET_INTERACTION]: (state, { payload: { interactionId } }) =>
      state.set('interactionId', interactionId),
    [types.GET_INTERACTIONS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.SELECT_INTERACTIONS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.SET_CURRENT_INTERACTION]: (state, { payload: participantId }) =>
      state.set('currentId', participantId).set('isFetching', false),
    [offerTypes.APPLY_OFFER_SUCCESS]: (state, { payload: participantId }) =>
      state.set('currentId', participantId),
    [types.POST_INTERACTION_SUCCESS]: (state, { payload }) =>
      state.set('allIds', state.get('allIds').push(payload)).set('currentId', payload),
    [types.SELECT_INTERACTION]: state => state.set('actionIsPending', true),
    [types.SELECT_INTERACTION_SUCCESS]: (state, { payload: { participantId, context } }) =>
      context !== 'interview'
        ? state
            .set(
              'allIds',
              state.get('allIds').filter(id => id !== participantId),
            )
            .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1)
            .set('actionIsPending', false)
        : state.set('actionIsPending', true),
    [types.SELECT_INTERACTION_SUCCESS]: (state, { payload }) =>
      state
        .set(
          'allIds',
          state.get('allIds').filter(id => !payload.participantsIds.includes(id)),
        )
        .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1),
    [types.GET_INTERACTIONS_SUCCESS]: (state, { payload }) => {
      // eslint-disable-line
      // Payload.clear to prevent cancel application persistent interaction
      const newAllIds =
        payload.clear === false ? [...state.get('allIds'), ...payload.result] : [...payload.result];

      return state.set('allIds', fromJS(uniq(newAllIds))).set('isFetching', false);
    },
  },
  initialReducer,
);
