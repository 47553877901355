import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { array, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useDebounce } from 'react-use';

import { useGetDefaultCriteria } from '@/queries/criterion/useGetDefaultCriteria';
import { useIsMobile } from '@/utils/MediaQueries';
import { algoliaSearch } from '@/utils/algolia';
import { CRITERIA } from '@/utils/constants';

import { Desktop } from './Desktop/Desktop';
import { Mobile } from './Mobile/Mobile';
import {
  choiceToSelectOption,
  getAlgoliaHitsChoices,
  getSelectedLocalisations,
  sortOptions,
} from './utils';

export const SearchEngine = ({
  facets,
  keywords,
  contracts = [],
  localisations,
  onSubmit,
  onFilterChange,
}) => {
  const isWiderThanMobile = !useIsMobile();
  const [contractsSelected, setContractsSelected] = useState(contracts);
  const [localisationSelected, setLocalisationSelected] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [localisationOptions, setLocalisationOptions] = useState([]);
  const [searchHits, setSearchHits] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selected, setSelected] = useState({ label: keywords, value: keywords });
  const criteria = useGetDefaultCriteria();
  const t = useFormatMessage();

  useDebounce(() => setDebouncedSearch(inputSearch), 300, [inputSearch]);

  useEffect(() => {
    algoliaSearch(debouncedSearch, hits => setSearchHits(getAlgoliaHitsChoices(hits)));
  }, [debouncedSearch]);

  useEffect(() => {
    if (isEmpty(facets)) {
      return;
    }
    const contractFamiliesFacets = facets.contractFamilies ?? {};

    const localisationOptions =
      criteria.data?.find(criteria => criteria.key === 'MOBILITY')?._choices || [];

    setLocalisationOptions(
      sortOptions(
        choiceToSelectOption(facets, localisationOptions, 'MOBILITY'),
        localisationSelected,
      ),
    );
    setLocalisationSelected(getSelectedLocalisations(localisationOptions, localisations));
    setContractOptions(
      Object.entries(contractFamiliesFacets).map(([contract, { count, contractsIds }]) => ({
        value: contract,
        label: t({ id: `contracts.families.${contract}` }),
        count,
      })),
    );
  }, [facets, criteria.data]);

  const handleSelectKeyword = value => {
    const newValue = value === null ? '' : (value?.label ?? keywords);
    setSelected(value);
    onSubmit?.({
      keywords: newValue,
      contracts,
      localisations,
    });
  };

  const handleClickSearch = () => {
    onSubmit?.({
      contracts: contractsSelected,
      localisations: localisationSelected,
      keywords: inputSearch || selected?.label || '',
    });
  };

  const handleLocalisationsSelected = localisations => {
    setLocalisationSelected(localisations);
    onFilterChange(
      {
        keywords,
        contracts,
        localisations,
      },
      CRITERIA.MOBILITY,
    );
  };

  const handleContractsSelected = contracts => {
    setContractsSelected(contracts);
    onFilterChange(
      {
        keywords,
        contracts,
        localisations,
      },
      CRITERIA.CONTRACT,
    );
  };

  if (isWiderThanMobile) {
    return (
      <Desktop
        selected={selected}
        onChange={handleSelectKeyword}
        search={inputSearch}
        onSearch={setInputSearch}
        searchHits={searchHits}
        contractsSelected={contractsSelected}
        setContractsSelected={handleContractsSelected}
        contractOptions={contractOptions}
        localisationSelected={localisationSelected}
        setLocalisationSelected={handleLocalisationsSelected}
        localisationOptions={localisationOptions}
        handleSearchEvents={handleClickSearch}
      />
    );
  }

  return (
    <Mobile
      selected={selected}
      onChange={handleSelectKeyword}
      search={inputSearch}
      onSearch={setInputSearch}
      searchHits={searchHits}
      contractsSelected={contractsSelected}
      setContractsSelected={handleContractsSelected}
      contractOptions={contractOptions}
      localisationSelected={localisationSelected}
      setLocalisationSelected={handleLocalisationsSelected}
      localisationOptions={localisationOptions}
      handleSearchEvents={handleClickSearch}
    />
  );
};

SearchEngine.propTypes = {
  keywords: string,
  contracts: array,
  localisations: array,
  onSubmit: func,
  facets: object,
  onFilterChange: func,
};
