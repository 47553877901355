import { useMedia } from 'react-use';

export function useIsMobile() {
  return useMedia('(max-width: 768px)');
}

export function useIsTablet() {
  return useMedia('(max-width: 1239px)');
}

export function useIsDesktop() {
  return useMedia('(min-width: 1240px)');
}
