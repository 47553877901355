import { store } from '@/configureStore';
import { appActions } from '@/store/app';
import { API_CUSTOM_ERRORS } from '@/utils/constants';
import { getLocalStorage } from '@/utils/localStorage';
import { parseJSON } from '@/utils/request';
import { redirectUnAuthUser } from '@/utils/user';

/**
 *
 * This fetch wrapper is to use with our Seekube apis ONLY
 *
 * @param url
 * @param options
 * @returns {Promise<Response | {}>}
 */

const fetchService = (url, options = {}) =>
  fetch(url, options)
    .then(async response => {
      let isWrongPasswordApiError = false;
      const isJsonResponse = response.headers.get('Content-Type')?.includes('json');

      if (isJsonResponse) {
        const clonedResponse = await response.clone();
        const data = await parseJSON(clonedResponse);
        isWrongPasswordApiError = data?.message === API_CUSTOM_ERRORS.wrongPassword;
      }

      if (response.status === 401 && !isWrongPasswordApiError) {
        const user =
          JSON.parse(getLocalStorage('redux'))?.auth?.connectedAs?.user ||
          JSON.parse(getLocalStorage('redux'))?.auth?.user;
        redirectUnAuthUser(user);
      }

      return response;
    })
    .catch(e => {
      const error = new Error(`FAILED_TO_FETCH ${url} - server modal will appear`);
      window.datadogRum?.addError(error);
      window.datadogRum?.addError(e);
      store.dispatch(appActions.setFetchError());
      return {};
    });

export default fetchService;
