import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Action for create new notification
 */
export const sendNotification = createAction(types.NEW_NOTIFICATION);

/**
 * Action for delete all notifications
 */
export const clearNotification = createAction(types.CLEAR_NOTIFICATION);

/**
 * Action for delete a specific notification
 */
export const dismissNotification = createAction(types.DISMISS_NOTIFICATION);
