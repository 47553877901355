export const PUSH_ORGANIZATION = 'app/store/organization/PUSH_ORGANIZATION';

export const SET_CURRENT_ORGANIZATION = 'app/store/organization/SET_CURRENT_ORGANIZATION';

export const POST_EXPONENTS = 'app/store/organization/POST_EXPONENTS';

export const POST_ORGANIZATION = 'app/store/organization/POST_ORGANIZATION';
export const POST_ORGANIZATION_SUCCESS = 'app/store/organization/POST_ORGANIZATION_SUCCESS';
export const POST_ORGANIZATION_ERROR = 'app/store/organization/POST_ORGANIZATION_ERROR';

export const GET_SPECIFIC_ORGANIZATION_SUCCESS =
  'app/store/organization/GET_SPECIFIC_ORGANIZATION_SUCCESS';
export const GET_SPECIFIC_ORGANIZATION = 'app/store/organization/GET_SPECIFIC_ORGANIZATION';
export const GET_ORGANIZATION = 'app/store/organization/GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'app/store/organization/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'app/store/organization/GET_ORGANIZATION_ERROR';

export const PATCH_ORGANIZATION = 'app/store/organization/PATCH_ORGANIZATION';
export const RESET_ALREADY_EXIST = 'app/store/organization/RESET_ALREADY_EXIST';
export const PATCH_ORGANIZATION_SUCCESS = 'app/store/organization/PATCH_ORGANIZATION_SUCCESS';
export const PATCH_ORGANIZATION_ERROR = 'app/store/organization/PATCH_ORGANIZATION_ERROR';

export const GET_ORGANIZATIONS = 'app/store/organization/GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_SUCCESS = 'app/store/organization/GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_ERROR = 'app/store/organization/GET_ORGANIZATIONS_ERROR';

export const GET_ALL_ORGANIZATIONS_LIST = 'app/store/organization/GET_ALL_ORGANIZATIONS_LIST';
export const GET_ALL_ORGANIZATIONS_LIST_SUCCESS =
  'app/store/organization/GET_ALL_ORGANIZATIONS_LIST_SUCCESS';
export const GET_ALL_ORGANIZATIONS_LIST_ERROR =
  'app/store/organization/GET_ALL_ORGANIZATIONS_LIST_ERROR';

export const GET_MERGE_LIST = 'app/store/organization/GET_MERGE_LIST';
export const GET_MERGE_LIST_SUCCESS = 'app/store/organization/GET_MERGE_LIST_SUCCESS';

export const MERGE_ORGANIZATION = 'app/store/organization/MERGE_ORGANIZATION';

export const MERGE_ORGANIZATION_NAME = 'app/store/organization/MERGE_ORGANIZATION_NAME';

export const DELETE_ORGANIZATION = 'app/store/organization/DELETE_ORGANIZATION';

export const ADD_JOBDATING = 'app/store/organization/ADD_JOBDATING';

export const SEARCH_ORGANIZATIONS = 'app/store/organization/SEARCH_ORGANIZATIONS';
