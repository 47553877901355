import { List } from 'immutable';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { authSelectors } from '../auth';
import { eventListSchema, eventSchema } from './schema';

const getEventReducer = state => state.get('event');
const getEntities = state => state.get('entities');

/**
 * Return the current event
 */
export const getCurrentEvent = createSelector(
  getEventReducer,
  getEntities,
  (eventState, entities) => {
    const eventId = eventState.get('currentId');
    const event = entities.getIn(['events', eventId]);

    return denormalize(event, eventSchema, entities);
  },
);

export const getCalendarEvent = createSelector(
  getEventReducer,
  getEntities,
  (eventState, entities) => {
    const eventId = eventState.get('calendarId');
    const event = entities.getIn(['events', eventId]);

    return denormalize(event, eventSchema, entities);
  },
);

export const getPlanningEvent = createSelector(
  getEventReducer,
  getEntities,
  (eventState, entities) => {
    const eventId = eventState.get('planningId');

    if (eventId === 'all') {
      return eventId;
    }

    const event = entities.getIn(['events', eventId]);

    return denormalize(event, eventSchema, entities);
  },
);

/**
 * Return events
 */
export const getEvents = createSelector(getEventReducer, getEntities, (eventState, entities) => {
  const allIds = eventState.get('allIds');
  const eventsList = allIds.map(id => entities.getIn(['events', id]));

  return denormalize(eventsList, eventListSchema, entities);
});

/**
 * Return events
 */
export const getExponentEvents = createSelector(
  getEventReducer,
  getEntities,
  (eventState, entities) => {
    const allIds = eventState.get('exponentIds');
    const eventsList = allIds.map(id => entities.getIn(['events', id]));

    return denormalize(eventsList, eventListSchema, entities);
  },
);

export const getEventsFetching = createSelector(getEventReducer, getEntities, eventState =>
  eventState.get('isFetching'),
);

/**
 * Get event pagination
 */
export const getEventPagination = createSelector(getEventReducer, eventState =>
  eventState.get('pagination'),
);

/**
 * Get criteria of the current event
 */
export const getCurrentEventCriteria = createSelector(getCurrentEvent, currentEvent => {
  if (!currentEvent) return new List();

  return currentEvent.get('_criteria');
});

/**
 * Return events where the auth user is
 */
export const getAuthUserEvents = createSelector(
  authSelectors.getAuthUser,
  getEntities,
  getEventReducer,
  (authUser, entities, eventState) => {
    if (!authUser) {
      return new List();
    }

    const allIds = eventState.getIn(['byUserId', authUser._id]) || new List();
    const eventsList = allIds.map(id => entities.getIn(['events', id]));

    return denormalize(eventsList, eventListSchema, entities);
  },
);

/**
 * Get event pagination
 */
export const getAuthUserEventsPagination = createSelector(getEventReducer, eventState =>
  eventState.get('byUserPagination'),
);

/**
 * Return if the currentUser is event owner
 */
export const isEventOwner = createSelector(
  authSelectors.getAuthUser,
  getCurrentEvent,
  getEntities,
  (authUser, currentEvent, entities) => {
    if (!authUser || !currentEvent) {
      return false;
    }

    const organizations =
      entities.getIn(['events', currentEvent.get('_id'), '_organizations']).toJS() || new List();
    const organizationIds = organizations
      ? organizations.map(item => (item._id || item).toString())
      : [];
    const currentOrganizationId = authUser.getIn(['_currentOrganization', '_id']);

    return organizationIds.includes(currentOrganizationId);
  },
);

/**
 * Return count auth user events
 */
export const countAuthUserEvents = createSelector(getEventReducer, eventState =>
  eventState.get('countAuthUserEvents'),
);

export const isAuthUserPreregistered = createSelector(getEventReducer, eventState =>
  eventState.get('isAuthUserPreregistered'),
);

export const getUserEventIds = createSelector(getEventReducer, getEntities, eventState =>
  eventState.get('userEventIds'),
);
