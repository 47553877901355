import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { criterionListSchema } from './schema';

const getCriterionReducer = state => state.get('criterion');
const getEntities = state => state.get('entities');

/**
 * Return list of all default criteria
 */
export const getDefaultCriteria = createSelector(
  getCriterionReducer,
  getEntities,
  (criterionState, entities) => {
    const defaultCriteriaIds = criterionState.get('defaultIds');
    const criteriaEntities = entities.get('criteria');
    const defaultCriteria = defaultCriteriaIds.map(id => criteriaEntities.get(id));

    return denormalize(defaultCriteria, criterionListSchema, entities);
  },
);

/**
 * Return list of all default criteria
 */
export const getMergeList = createSelector(getCriterionReducer, getEntities, criterionState =>
  criterionState.get('mergeList'),
);

export const getMergeListTotal = createSelector(getCriterionReducer, getEntities, criterionState =>
  criterionState.get('mergeListTotal'),
);

/**
 * Return list of all default criteria
 */
export const getSuggestions = createSelector(getCriterionReducer, getEntities, criterionState =>
  criterionState.get('suggestions'),
);
