export function addUtmToLink(link, utm) {
  if (!utm) {
    return link;
  }

  const utmString = `utm_source=${utm.source}&utm_campaign=${utm.campaign}&utm_medium=${utm.medium}`;

  if (link.match(/\?./)) {
    return `${link}&${utmString}`;
  }
  return `${link}?${utmString}`;
}

export default addUtmToLink;
