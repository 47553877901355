export const SET_CURRENT_EXPONENT = 'app/store/exponent/SET_CURRENT_EXPONENT';

export const GET_EXPONENT = 'app/store/exponent/GET_EXPONENT';
export const GET_EXPONENT_BY_ORGANIZATION = 'app/store/exponent/GET_EXPONENT_BY_ORGANIZATION';
export const GET_EXPONENT_BY_ORGANIZATION_SUCCESS =
  'app/store/exponent/GET_EXPONENT_BY_ORGANIZATION_SUCCESS';
export const GET_EXPONENT_BY_ORGANIZATION_ERROR =
  'app/store/exponent/GET_EXPONENT_BY_ORGANIZATION_ERROR';
export const GET_EXPONENT_SUCCESS = 'app/store/exponent/GET_EXPONENT_SUCCESS';
export const GET_EXPONENT_ERROR = 'app/store/exponent/GET_EXPONENT_ERROR';

export const GET_EXPONENTS_BY_ORGANIZATION = 'app/store/exponent/GET_EXPONENTS_BY_ORGANIZATION';
export const GET_EXPONENTS_BY_ORGANIZATION_SUCCESS =
  'app/store/exponent/GET_EXPONENTS_BY_ORGANIZATION_SUCCESS';
export const GET_EXPONENTS_BY_ORGANIZATION_ERROR =
  'app/store/exponent/GET_EXPONENTS_BY_ORGANIZATION_ERROR';

export const GET_EXPONENTS = 'app/store/exponent/GET_EXPONENTS';
export const GET_EXPONENTS_SUCCESS = 'app/store/exponent/GET_EXPONENTS_SUCCESS';
export const GET_EXPONENTS_ERROR = 'app/store/exponent/GET_EXPONENTS_ERROR';

export const GET_EXPONENTS_STATS = 'app/store/exponent/GET_EXPONENTS_STATS';
export const GET_EXPONENTS_STATS_SUCCESS = 'app/store/exponent/GET_EXPONENTS_STATS_SUCCESS';

export const PATCH_EXPONENT = 'app/store/exponent/PATCH_EXPONENT';
export const PATCH_EXPONENT_SUCCESS = 'app/store/exponent/PATCH_EXPONENT_SUCCESS';
export const PATCH_EXPONENT_ERROR = 'app/store/exponent/PATCH_EXPONENT_ERROR';

export const POST_EXPONENT = 'app/store/exponent/POST_EXPONENT';
export const POST_EXPONENT_SUCCESS = 'app/store/exponent/POST_EXPONENT_SUCCESS';
export const POST_EXPONENT_ERROR = 'app/store/exponent/POST_EXPONENT_ERROR';

export const DELETE_EXPONENT = 'app/store/exponent/DELETE_EXPONENT';
export const DELETE_EXPONENT_SUCCESS = 'app/store/exponent/DELETE_EXPONENT_SUCCESS';
export const DELETE_EXPONENT_ERROR = 'app/store/exponent/DELETE_EXPONENT_ERROR';

export const GET_EXPONENTS_PARTNERS = 'app/store/exponent/GET_EXPONENTS_PARTNERS';
export const GET_EXPONENTS_PARTNERS_SUCCESS = 'app/store/exponent/GET_EXPONENTS_PARTNERS_SUCCESS';
export const GET_EXPONENTS_PARTNERS_ERROR = 'app/store/exponent/GET_EXPONENTS_PARTNERS_ERROR';

export const PATCH_EXPONENTS_PARTNERS = 'app/store/exponent/PATCH_EXPONENTS_PARTNERS';
export const PATCH_EXPONENTS_PARTNERS_SUCCESS =
  'app/store/exponent/PATCH_EXPONENTS_PARTNERS_SUCCESS';
export const PATCH_EXPONENTS_PARTNERS_ERROR = 'app/store/exponent/PATCH_EXPONENTS_PARTNERS_ERROR';

export const EXPONENT_CLEAR_ERROR = 'app/store/exponent/CLEAR_ERROR';
