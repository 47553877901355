/*
 *
 * LanguageProvider reducer
 *
 */
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';

import { DEFAULT_LOCALE, LANGUAGES } from '../App/constants';
import { CHANGE_LOCALE } from './constants';

// eslint-disable-line

const matchLanguage = LANGUAGES.filter(lang =>
  lang.alias.includes(navigator.language.toLowerCase()),
);

const initialState = fromJS({
  locale: !isEmpty(matchLanguage) ? matchLanguage[0].id : DEFAULT_LOCALE,
  languages: LANGUAGES,
});

const languageProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale);
    default:
      return state;
  }
};

export default languageProviderReducer;
