import { isEmpty } from 'lodash';

export const getLandingParams = (
  keywords: string = '',
  contracts: string[] = [],
  localisations: string[] = [],
  facets: { contractFamilies?: object } = {},
) => {
  const contractFamiliesFacets = facets.contractFamilies ?? {};
  const contractsIds = [];

  contracts.forEach(contract => {
    contractsIds.push(...contractFamiliesFacets[contract]?.contractsIds);
  });

  const filters = contractsIds.join(',');

  const params = new URLSearchParams({
    ...(keywords ? { keywords } : {}),
    ...(!isEmpty(filters) ? { filters } : {}),
    ...(!isEmpty(localisations) ? { localization: localisations.join(',') } : {}),
  }).toString();

  return params.toString();
};
