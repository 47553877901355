import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Actions for get the list of all default criterion
 */
export const getDefaultCriteria = createAction(types.GET_DEFAULT_CRITERIA);
export const getDefaultCriteriaSuccess = createAction(types.GET_DEFAULT_CRITERIA_SUCCESS);
export const getDefaultCriteriaError = createAction(types.GET_DEFAULT_CRITERIA_ERROR);

/**
 * Actions for post new criterion into a event
 */
export const postCriterion = createAction(types.POST_CRITERION);
export const postCriterionSuccess = createAction(types.POST_CRITERION_SUCCESS);
export const postCriterionError = createAction(types.POST_CRITERION_ERROR);

/**
 * Actions for post new criterion into a event
 */
export const mergeCriterion = createAction(types.MERGE_CRITERION);
export const skipCriterion = createAction(types.SKIP_CRITERION);

/**
 * Actions for delete criterion into a event
 */
export const deleteCriterion = createAction(types.DELETE_CRITERION);
export const deleteCriterionSuccess = createAction(types.DELETE_CRITERION_SUCCESS);
export const deleteCriterionError = createAction(types.DELETE_CRITERION_ERROR);

export const getMergeList = createAction(types.GET_MERGE_LIST);
export const getMergeListSuccess = createAction(types.GET_MERGE_LIST_SUCCESS);
