import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { interactionListSchema, interactionSchema } from './schema';

const getInteractionReducer = state => state.get('interaction');
const getEntities = state => state.get('entities');

/**
 * Return current interaction
 */
export const getCurrentInteraction = createSelector(
  getInteractionReducer,
  getEntities,
  (interactionState, entities) => {
    const interactionId = interactionState.get('currentId');
    const interaction = entities.getIn(['interactions', interactionId]);
    return denormalize(interaction, interactionSchema, entities);
  },
);

/**
 * Return interactions
 */
export const getInteractions = createSelector(
  getInteractionReducer,
  getEntities,
  (interactionState, entities) => {
    const allIds = interactionState.get('allIds');
    const interactionsList = allIds.map(id => entities.getIn(['interactions', id]));

    return denormalize(interactionsList, interactionListSchema, entities);
  },
);
/**
 * Return interactions
 */
export const getInteraction = createSelector(
  getInteractionReducer,
  getEntities,
  (interactionState, entities) => {
    const id = interactionState.get('interactionId');
    const interaction = entities.getIn(['interactions', id]);

    return denormalize(interaction, interactionSchema, entities);
  },
);

/**
 * Get all interactions IDS
 */
export const getInteractionsIds = createSelector(
  getInteractionReducer,
  getEntities,
  interactionState => interactionState.get('allIds'),
);

/**
 * Get loader
 */
export const getInteractionsFetching = createSelector(getInteractionReducer, interactionState =>
  interactionState.get('isFetching'),
);
