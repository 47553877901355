import { normalize } from 'normalizr';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { trackError } from '@/utils/analytics';
import request from '@/utils/request';

import { authSelectors } from '../auth';
import { entitiesActions } from '../entities';
import { userActions } from '../user';
import * as actions from './actions';
import { roleListSchema } from './schema';
import * as types from './types';

function* getRoles() {
  const authUser = yield select(authSelectors.getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/apps/roles`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    const dataNormalized = normalize(result, roleListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (authUser) {
      yield put(userActions.setCurrentUser(authUser.get('_id')));
    }

    yield put(
      actions.getRolesSuccess({
        result: dataNormalized.result,
        items: result,
      }),
    );
  } catch (err) {
    trackError(err);
  }
}

export default [takeLatest(types.GET_ROLES, getRoles)];
