import { Map, fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { handleActions } from 'redux-actions';

import * as types from './types';

const initialState = fromJS({
  error: {},
  appointment: null,
  timeslots: [],
  selectedTimeslot: null,
  organizationIds: [],
  userIds: [],
  allIds: [],
  isFetching: false,
  loadMore: false,
  pagination: {
    current: 1,
    pageSize: 10,
    limit: 10,
    total: 0,
    offset: 0,
  },
});

/**
 * Appointment reducer
 */
const appointmentReducer = handleActions(
  {
    [types.GET_USER_APPOINTMENTS]: state => state.set('isFetching', true),
    [types.GET_APPOINTMENT_SUCCESS]: (
      state,
      { payload: { appointment, timeslots, selectedTimeslot } },
    ) =>
      state
        .set('error', new Map())
        .set('appointment', appointment)
        .set('timeslots', timeslots)
        .set('selectedTimeslot', selectedTimeslot),
    [types.GET_APPOINTMENTS]: state => state.set('isFetching', true),
    [types.GET_APPOINTMENTS_SUCCESS]: (state, { payload }) => {
      const allIds = state.get('allIds').toJS();
      const newAllIds = payload.offset === 0 ? payload.ids : [...allIds, ...payload.ids];

      return state
        .set('allIds', fromJS(newAllIds))
        .set('loadMore', !isEmpty(payload.ids))
        .set('isFetching', false)
        .setIn(['pagination', 'current'], (payload.offset % payload.limit) + 1)
        .setIn(['pagination', 'pageSize'], payload.limit)
        .setIn(['pagination', 'offset'], payload.offset)
        .setIn(['pagination', 'total'], payload.total);
    },
    [types.SELECT_APPOINTMENT_SLOT_SUCCESS]: (state, { payload: { appointment, timeslot } }) =>
      state.set('appointment', appointment).set('selectedTimeslot', timeslot),
    [types.MODIFY_APPOINTMENT_SUCCESS]: state =>
      state.set('appointment', null).set('selectedTimeslot', null),
    [types.PATCH_APPOINTMENT_DATE_SUCCESS]: (state, { payload: { appointmentId } }) =>
      state.set(
        'allIds',
        state.get('allIds').filter(id => id !== appointmentId),
      ),
    [types.GET_USER_APPOINTMENTS_SUCCESS]: (state, { payload }) => {
      const userIds = state.get('userIds').clear().merge(payload.result);

      return state.set('isFetching', false).set('userIds', userIds);
    },
  },
  initialState,
);

export default appointmentReducer;
