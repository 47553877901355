import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Action for get jobs of an organization in a event
 */
export const getJobs = createAction(types.GET_JOBS);
export const getJobsSuccess = createAction(types.GET_JOBS_SUCCESS);
export const getJobsError = createAction(types.GET_JOBS_ERROR);

/**
 * Action for patch job
 */
export const patchJob = createAction(types.PATCH_JOB);
export const patchJobSuccess = createAction(types.PATCH_JOB_SUCCESS);
export const patchJobError = createAction(types.PATCH_JOB_ERROR);

/**
 * Action for post new job
 */
export const postJob = createAction(types.POST_JOB);
export const postJobSuccess = createAction(types.POST_JOB_SUCCESS);
export const postJobError = createAction(types.POST_JOB_ERROR);

export const deleteJob = createAction(types.DELETE_JOB);
export const deleteJobSuccess = createAction(types.DELETE_JOB_SUCCESS);
