import { get, isEmpty, isObject, isString, keys, map, size, toString } from 'lodash';

export const APPOINTMENTS_ACTIONS = {
  recruiter: {
    propose: 'propose',
    refuse: 'refuse',
  },
  candidate: {
    cancel: 'cancel',
  },
};
export const CONTEXTS = {
  save: 'Vivier',
  interview: 'Entretiens',
  top: 'Top profils',
  refuse: 'Non retenus',
  applications: "Liste d'attente",
  sourcing: 'Sourcing',
};

export const CONTEXTS_PROCESS_JD = [
  'interview',
  'refused',
  'unconfirmed',
  'confirmed',
  'pending',
  'top',
  'save',
  'refuse',
];
export const CONTEXTS_INTERVIEW = ['refused', 'unconfirmed', 'confirmed', 'pending', 'transferred'];

export const CONTEXTS_POST_JD = ['top', 'save', 'refuse'];
export const CONTEXTS_WITH_INTERACTION = ['sourcing', 'applications', 'interview'];
export const CONTEXTS_ADMIN_SELECTION = ['adminall', 'admintop', 'adminsave', 'adminrefuse'];
export const CONTEXTS_ALL = [...keys(CONTEXTS), ...CONTEXTS_ADMIN_SELECTION, ...CONTEXTS_INTERVIEW];
export const CONTEXTS_RECRUITER = [...CONTEXTS_POST_JD, ...CONTEXTS_WITH_INTERACTION];

export const APPOINTMENT_MEDIUMS = {
  physical: {
    icon: 'location',
  },
  phone: {
    icon: 'phone',
  },
  visio: {
    icon: 'visio',
  },
};
export const DISTINCT_INTERACTION = {
  candidate: {
    interviewCanceled: 'interview-canceled-by-candidate',
    interviewPending: 'interview-pending-by-candidate',
    applicationPending: 'application-pending-by-candidate',
    interviewScheduled: 'interview-scheduled-by-candidate',
  },
  recruiter: {
    refusedApplication: 'application-refused-by-recruiter',
    pendingApplication: 'application-pending',
    acceptedApplication: 'application-accepted-by-recruiter',
  },
  interaction: {
    interactionPending: 'application-pending',
    interactionAccepted: 'application-accepted',
    interactionRefused: 'application-refused',
  },
};

export const getInteractionStatus = interaction => {
  const appointment = get(interaction, '_appointment');
  const timeslot = get(interaction, '_appointment._timeslot');
  if (
    interaction.type === 'application' &&
    interaction.pending === true &&
    appointment &&
    appointment.status === 'pending' &&
    isString(appointment.message) &&
    isEmpty(timeslot)
  )
    return DISTINCT_INTERACTION.recruiter.acceptedApplication;
  if (interaction.type === 'application' && interaction.pending === true && isEmpty(appointment))
    return DISTINCT_INTERACTION.recruiter.pendingApplication;
  if (
    interaction.type === 'application' &&
    appointment &&
    appointment.status === 'refused' &&
    isString(appointment.message)
  )
    return DISTINCT_INTERACTION.candidate.interviewCanceled;
  if (
    interaction.type === 'application' &&
    isString(interaction.messageRefused) &&
    isEmpty(appointment)
  )
    return DISTINCT_INTERACTION.recruiter.refusedApplication;
  if (timeslot) return DISTINCT_INTERACTION.candidate.interviewScheduled;

  return '';
};

export const getId = collection =>
  isObject(collection) ? toString(collection._id) : toString(collection);

export const getIds = arr => map(arr, item => getId(item));

export const checkIfPropertyChanged = (obj1, obj2, path1, path2 = null) =>
  get(obj1, path1) !== get(obj2, path2 || path1);

export const getTz = user => {
  if (user && user.timezone && user.timezone.utc) {
    return user.timezone.utc[size(user.timezone.utc) - 1];
  }

  return 'Europe/Paris';
};

export const COLORS = {
  primary: '#33A8EB',
  secondary: '#385077',
  black: '#252D47',
  active: '#2EA3EB',
  greyLight: '#C4C4C4',
  success: '#56D58F',
  successIcon: '#8ED633',
  warning: '#F7C003',
  error: '#F95377',
};

export const TEMPLATE_CRITERIA_ID = {
  cdi: '598826added2cd00b2ad666a',
  cdd: '598826added2cd00b2ad666b',
};
