export function trackGtmEvent(eventName, eventParams = {}) {
  if (eventName === 'page.display') {
    eventParams.event = 'virtual.page.display';

    const elems = eventParams.path.split('/');

    if (['recruiter', 'candidate', 'owner'].includes(elems[2])) {
      eventParams.jobdating_name = elems[1];
    }

    if (eventParams.path?.indexOf('/auth/login') > -1) {
      eventParams.page_category = 'login';
      eventParams.page_subject = 'login';
    } else if (eventParams.path?.indexOf('/resetpassword') > -1) {
      eventParams.page_category = 'login';
      eventParams.page_subject = 'resetpassword';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/authentication') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'authentication';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/search') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'search';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/discovery') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'discovery';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/profile') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'profile';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'signup';
    } else if (eventParams.path?.indexOf('/events') > -1) {
      eventParams.page_category = 'profil';
      eventParams.page_subject = 'profil';
    } else if (eventParams.path?.indexOf('/events/archive') > -1) {
      eventParams.page_category = 'profil';
      eventParams.page_subject = 'archive';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/jobs') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'jobs';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/signin') > -1) {
      eventParams.page_category = 'event_onboarding';
      eventParams.page_subject = 'login';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/signup') > -1) {
      return;
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/profile') > -1) {
      return;
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/matching') > -1) {
      return;
    } else if (eventParams.path?.indexOf('/candidate/jobdating/exponents') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'stands';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/lives') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'webinar';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/dashboard') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'dashboard';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/jobs/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'job_detail';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/exponents/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'stand_detail';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/appointments/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'appointment';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/appointments') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'meetings';
    } else if (eventParams.path?.indexOf('/seekalendar') > -1) {
      eventParams.page_category = 'home';
      eventParams.page_subject = 'home_seekalendar';
    } else if (eventParams.page_category !== 'landing_page') {
      eventParams.page_category = 'default';
      eventParams.page_subject = 'default';
    }
  } else if (eventName === 'virtual.page.display') {
    eventParams.event = 'virtual.page.display';
  } else {
    eventParams.event_name = eventName;
    eventParams.event = 'generic';
  }

  window.dataLayer.push(eventParams);
  console.log(`GTAG Event ${process.env.NODE_ENV}`, eventParams);
}
