import { isEmpty } from 'lodash/fp';
import { useInfiniteQuery } from 'react-query';

import fetchService from '@/services/fetch';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetPublicEvents({ authUser, limit = 50, filters }) {
  const queryFn = pageParams => {
    const { pageParam: page } = pageParams;
    const filtersParams = objectToParams(filters);
    const params = `limit=${limit}&page=${page || '1'}&${filtersParams}`;
    const requestUrl = `${process.env.FRONT_API_URL}/events/public?${params}`;

    if (!authUser) {
      return fetchService(requestUrl, {
        method: 'GET',
      }).then(response => response.json());
    }

    return request(requestUrl, {
      method: 'GET',
    });
  };

  const query = useInfiniteQuery({
    queryKey: ['publicEvents', filters],
    getNextPageParam: lastPage => {
      if (lastPage.page * lastPage.limit < lastPage.total) {
        return lastPage.page + 1;
      }
    },
    select: data => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return query;
}
