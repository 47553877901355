import React from 'react';

import moment from 'moment';
import 'moment-timezone';
import { func, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { CookieConsent } from '@seekube-tech/cookie-consent';
import '@seekube-tech/cookie-consent/dist/index.css';

import { appActions } from '../../../app/store/app';
import toJS from '../../../app/utils/toJS';
import Article from '../../components/Article';
import CalendarPage from '../../scenes/Calendar';
import Home from '../../scenes/Home';
import RecruiterPage from '../../scenes/Recruiter';
import { SearchEvents } from '../../scenes/SearchEvents/SearchEvents';
import './oldstyles.less';
import './stylesV2.less';

class App extends React.PureComponent {
  static propTypes = {
    history: object,
    location: object,
    getPublicAppointments: func,
    intl: object,
  };

  componentDidMount() {
    const { location } = this.props;
    moment.tz.setDefault('Europe/Paris');

    const intercomSettings = {
      app_id: process.env.INTERCOM_APP_ID,
      hide_default_launcher: false,
    };

    window.Intercom('boot', intercomSettings);
    this.props.getPublicAppointments();

    if (location.pathname === '/mazars') {
      window.location.href = `https://app.seekube.com/forum-virtuel-mazars1${location.search}`;
    } else if (location.pathname === '/semaine-tech') {
      window.location.href = `https://app.seekube.com/semaine-de-la-tech-cdi1${location.search}`;
    } else if (location.pathname === '/metiers-de-lingenieur-cdi-dec-20211') {
      window.location.href = `https://app.seekube.com/metiers-de-lingenieur-cdi-dec-20211${location.search}`;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);

      this.props.getPublicAppointments();
    }
  }

  /**
   * ComponentWillUnmount
   *
   * @description
   * Reset allIds participants in redux store
   */
  componentWillUnmount() {
    window.Intercom('shutdown');
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <CookieConsent
          excludePaths={['/terms', '/legals', '/privacy-policy']}
          labels={{
            cookieModal: {
              description: {
                first: intl.formatMessage({ id: 'cookieModal.description.first' }),
                second: intl.formatMessage({ id: 'cookieModal.description.second' }),
              },
              actions: {
                refuse: intl.formatMessage({ id: 'cookieModal.actions.refuse' }),
                customize: intl.formatMessage({ id: 'cookieModal.actions.customize' }),
                accept: intl.formatMessage({ id: 'cookieModal.actions.accept' }),
              },
            },
            customizeModal: {
              title: intl.formatMessage({ id: 'customizeModal.title' }),
              description: intl.formatMessage({ id: 'customizeModal.description' }),
              fonctional: {
                label: intl.formatMessage({ id: 'customizeModal.fonctional.label' }),
                description: intl.formatMessage({ id: 'customizeModal.fonctional.description' }),
              },
              statistics: {
                label: intl.formatMessage({ id: 'customizeModal.statistics.label' }),
                description: intl.formatMessage({ id: 'customizeModal.statistics.description' }),
              },
              marketing: {
                label: intl.formatMessage({ id: 'customizeModal.marketing.label' }),
                description: intl.formatMessage({ id: 'customizeModal.marketing.description' }),
              },
              actions: {
                findMore: intl.formatMessage({ id: 'customizeModal.actions.findMore' }),
                refuse: intl.formatMessage({ id: 'customizeModal.actions.refuse' }),
                accept: intl.formatMessage({ id: 'customizeModal.actions.accept' }),
              },
            },
          }}
        />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/recruiters" component={RecruiterPage} />
          <Route exact path="/schools" component={RecruiterPage} />
          <Route exact path="/legals" render={() => <Article articleId="legals" />} />
          <Route exact path="/privacy-policy" render={() => <Article articleId="privacy" />} />
          <Route exact path="/terms" render={() => <Article articleId="tos" />} />
          <Route exact path="/recruiters/events" component={CalendarPage} />

          <Route path="/home">
            <Redirect to="/" />
          </Route>
          <Route path="/cgu">
            <Redirect to="/terms" />
          </Route>
          <Route path="/company">
            <Redirect to="/recruiter" />
          </Route>
          <Route path="/school">
            <Redirect to="/" />
          </Route>
          <Route path="/dedicated">
            <Redirect to="/" />
          </Route>
          <Route path="/partners">
            <Redirect to="/" />
          </Route>
          <Route exact path="/forums">
            <Redirect to="/recruiters/events" />
          </Route>
          <Route exact path="/forums/en">
            <Redirect to="/recruiters/events" />
          </Route>
          <Route path="/forums">
            <Redirect to="/" />
          </Route>

          <Route path="/search-event" component={SearchEvents} />

          <Route
            path="/pub/"
            component={() => {
              window.location.href = 'https://app.seekube.com/404';
              return null;
            }}
          />

          <Route
            path="/inscription"
            component={() => {
              window.location.href = 'https://app.seekube.com/auth/signup/candidate';
              return null;
            }}
          />

          <Route
            path="/login"
            component={() => {
              window.location.href = 'https://app.seekube.com';
              return null;
            }}
          />

          <Route
            path="/user/reset/request"
            component={() => {
              window.location.href = 'https://app.seekube.com/resetpassword';
              return null;
            }}
          />

          <Route
            exact
            path="/team"
            component={() => {
              window.location.href = 'https://www.welcometothejungle.co/fr/companies/seekube';
              return null;
            }}
          />
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = {
  getPublicAppointments: appActions.getPublicAppointments,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withRouter, withConnect)(toJS(injectIntl(App)));
