export const GET_DEFAULT_CRITERIA = 'app/store/criterion/GET_DEFAULT_CRITERIA';
export const GET_DEFAULT_CRITERIA_SUCCESS = 'app/store/criterion/GET_DEFAULT_CRITERIA_SUCCESS';
export const GET_DEFAULT_CRITERIA_ERROR = 'app/store/criterion/GET_DEFAULT_CRITERIA_ERROR';

export const GET_MERGE_LIST = 'app/store/criterion/GET_MERGE_LIST';
export const GET_MERGE_LIST_SUCCESS = 'app/store/criterion/GET_MERGE_LIST_SUCCESS';
export const GET_MERGE_LIST_ERROR = 'app/store/criterion/GET_MERGE_LIST_ERROR';

export const MERGE_CRITERION = 'app/store/criterion/MERGE_CRITERION';
export const SKIP_CRITERION = 'app/store/criterion/SKIP_CRITERION';

export const POST_CRITERION = 'app/store/criterion/POST_CRITERION';
export const POST_CRITERION_SUCCESS = 'app/store/criterion/POST_CRITERION_SUCCESS';
export const POST_CRITERION_ERROR = 'app/store/criterion/POST_CRITERION_ERROR';

export const DELETE_CRITERION = 'app/store/criterion/DELETE_CRITERION';
export const DELETE_CRITERION_SUCCESS = 'app/store/criterion/DELETE_CRITERION_SUCCESS';
export const DELETE_CRITERION_ERROR = 'app/store/criterion/DELETE_CRITERION_ERROR';
