import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as types from './types';

const initialState = fromJS({
  calendarId: null,
  planningId: null,
  modalUserId: null,
  isFetching: false,
  modalHasSlot: false,
  byUserId: {},
  modalByUserId: {},
});

export default handleActions(
  {
    [types.GET_USER_TIMESLOTS]: (state, { payload: { loader, currentUser, context = null } }) =>
      state
        .set('calendarId', context === 'calendar' ? currentUser._id : state.get('calendarId'))
        .set('planningId', context === 'planning' ? currentUser._id : state.get('planning'))
        .set('isFetching', loader === true),
    [types.GET_USER_TIMESLOTS_SUCCESS]: (state, { payload: { ids, userId, context } }) => {
      let newState = state;

      if (context === 'modal') {
        newState = state.set('modalUserId', userId).setIn(['modalByUserId', userId], fromJS(ids));
      }

      return newState.setIn(['byUserId', userId], fromJS(ids)).set('isFetching', false);
    },
  },
  initialState,
);
