import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@/utils/request';

import { trackError } from '../../utils/analytics/helpers';
import { entitiesActions } from '../entities';
import { notificationActions } from '../notification';
import * as actions from './actions';
import { jobListSchema, jobSchema } from './schema';
import * as types from './types';

/**
 * GetJobs
 *
 * @description
 * Get all jobs of the current event & the currentOrganization of the authUser
 */
function* getJobs({ payload: { offset, page, limit } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/jobs?offset=${offset}&limit=${limit}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(docs, jobListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(
      actions.getJobsSuccess({
        result: dataNormalized.result,
        currentPage: page,
        offset,
        limit,
        total,
      }),
    );
  } catch (err) {
    trackError(err);
  }
}

/**
 * PostJob
 *
 * @description
 * Create new job
 */
function* postJob({ payload: { eventId, jobParams, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/jobs`;

  // Post the job
  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, jobSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Put id in allIds
    yield put(actions.postJobSuccess(dataNormalized.result));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * PatchJob
 *
 * @description
 * Patch job
 */
function* patchJob({ payload: { jobId, _event, jobParams, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${_event}/jobs/${jobId}`;

  // Patch the job
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobParams),
    });

    // Normaliz data
    const dataNormalized = normalize(result, jobSchema);

    // Save entities
    yield put(
      entitiesActions.replaceEntities({
        id: jobId,
        type: 'jobs',
        entities: dataNormalized.entities,
      }),
    );
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * DeleteJob
 *
 * @description
 * Delete job
 */
function* deleteJob({ payload: { jobId, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/jobs/${jobId}`;

  // Delete the job
  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Update entities
    yield put(actions.deleteJobSuccess(jobId));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    yield put(
      notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }),
    );
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_JOBS, getJobs),
  takeLatest(types.POST_JOB, postJob),
  takeLatest(types.PATCH_JOB, patchJob),
  takeLatest(types.DELETE_JOB, deleteJob),
];
