import React from 'react';

import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import Helmet from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import request from '@/utils/request';
import toJS from '@/utils/toJS';
import { objectToParams } from '@/utils/url';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import EventCard from './components/EventCard';
import messages from './messages';
import styles from './styles.less';

/* eslint-disable */
class CalendarScene extends React.PureComponent {
  // eslint-disable-line
  $months;

  state = {
    data: {
      targets: [],
    },
    filters: {
      targets: [],
    },
    isLoading: true,
    months: [],
    limit: 20,
    offset: 0,
    total: undefined,
  };

  componentWillMount() {
    const { intl } = this.props;
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        targets: [
          {
            value: 'tech',
            label: intl.formatMessage({ id: 'event.category.tech' }),
          },
          {
            value: 'business',
            label: intl.formatMessage({ id: 'event.category.business' }),
          },
          {
            value: 'engineer',
            label: intl.formatMessage({ id: 'event.category.engineering' }),
          },
        ],
      },
    });
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll.bind(this));

    this.getEvents();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  getEvents(isLoadmore) {
    const { filters, limit, offset } = this.state;
    const query = {
      targets: filters.targets,
      limit,
      offset,
    };

    this.setState({ isLoading: true });

    request(`${process.env.FRONT_API_URL}/events/public-calendar?${objectToParams(query)}`).then(
      data => {
        this.setState({
          isLoading: false,
          months: this.parseEvents(data.docs, isLoadmore),
          total: data.total,
        });
      },
    );
  }

  parseEvents = (events, isLoadmore) => {
    const months = isLoadmore ? [...this.state.months] : [];

    events.forEach(event => {
      const beginAt = parseISO(event.keyDates.jobfair.beginAt);
      const monthKey = format(beginAt, 'MM-yyyy');
      const month = months.find(m => m.key === monthKey);
      if (month) return month.events.push(event);
      months.push({
        key: monthKey,
        date: parse(`01-${monthKey} 00:00:00`, 'dd-MM-yyyy HH:mm:ss', new Date(), { locale: fr }),
        label: format(beginAt, 'MMMM yyyy', { locale: fr }),
        events: [event],
      });
    });

    months.sort((a, b) => {
      if (a.date < b.date) return -1;
      return 1;
    });

    months.forEach(m => {
      m.events.sort((a, b) => {
        if (parseISO(a.keyDates.jobfair.beginAt) < parseISO(b.keyDates.jobfair.beginAt)) return -1;
        return 1;
      });
    });

    return months;
  };

  toggleFilterTarget(target) {
    const { filters } = this.state;
    const targets = filters.targets;
    const index = targets.indexOf(target);

    index !== -1 ? targets.splice(index, 1) : targets.push(target);
    this.setState({
      filters: {
        ...filters,
        targets,
      },
      offset: 0,
    });
    this.getEvents();
  }

  handleScroll() {
    const { isLoading, months, offset, total } = this.state;
    if (isLoading || !months.length || offset >= total) return undefined;
    const isLoadmore =
      this.$months &&
      window.scrollY + window.innerHeight >= this.$months.offsetTop + this.$months.offsetHeight;
    if (!isLoadmore) return undefined;
    this.setState({ offset: offset + 20 });
    this.getEvents(true);
  }

  render() {
    const { intl } = this.props;
    const { data, filters, months } = this.state;

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang: intl.locale }}>
          <meta charSet="utf-8" />
          <title>{`Seekube - ${intl.formatMessage(messages.h1)}`}</title>
          <meta name="description" content={intl.formatMessage(messages.excerpt)} />
        </Helmet>

        <Header type="calendar" />
        <div className={styles.calendar}>
          <div className={styles.header}>
            <h1>
              <FormattedMessage {...messages.h1} />
            </h1>
            <p>
              <FormattedMessage {...messages.excerpt} />
            </p>
            <a
              href="https://seekube.hellowork.com/demande-de-rappel"
              target="_blank"
              className="btn primary large"
            >
              <i role="img">😎</i>
              <FormattedMessage {...messages.cta} />
            </a>
          </div>
          <div className={styles.filters}>
            <div className={styles.content}>
              <h2>
                <FormattedMessage {...messages.h2} />
              </h2>
              <ul>
                {data.targets.map((target, key) => (
                  <li
                    className={filters.targets.indexOf(target.value) !== -1 ? 'active' : ''}
                    onClick={() => this.toggleFilterTarget(target.value)}
                    key={key}
                  >
                    {target.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div ref={el => (this.$months = el)} className={styles.months}>
            <div className={styles.content}>
              {months.map((month, key) => (
                <div className={styles.month} key={key}>
                  <h4>{month.label}</h4>
                  <div className={styles.events}>
                    {month.events
                      .filter(e => e && e.slug)
                      .map((event, keyEvent) => (
                        <EventCard event={event} key={keyEvent} />
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.more}>
            <div className={styles.content}>
              <h6>
                <FormattedMessage {...messages.moreTitle} />
              </h6>
              <a
                href="https://seekube.hellowork.com/demande-de-rappel"
                target="_blank"
                className="btn primary large"
              >
                <i role="img">😎</i>
                <FormattedMessage {...messages.moreCta} />
              </a>
            </div>
          </div>
        </div>
        <Footer type="recruiter" />
      </React.Fragment>
    );
  }
}

export default compose(withRouter, injectIntl)(toJS(CalendarScene));
