// GET app
export const GET_APP = 'app/store/app/GET_APP';
export const GET_APP_SUCCESS = 'app/store/app/GET_APP_SUCCESS';
export const GET_APP_ERROR = 'app/store/app/GET_APP_ERROR';
export const CLEAN_FETCH_ERROR = 'app/store/app/CLEAN_FETCH_ERROR';
export const SET_FETCH_ERROR = 'app/store/app/SET_FETCH_ERROR';

// SET current app
export const SET_CURRENT_APP = 'app/store/app/SET_CURRENT_APP';

export const GET_PUBLIC_APPOINTMENTS = 'app/store/app/GET_PUBLIC_APPOINTMENTS';

// PATCH app
export const PATCH_APP = 'app/store/app/PATCH_APP';
export const PATCH_APP_SUCCESS = 'app/store/app/PATCH_APP_SUCCESS';
export const PATCH_APP_ERROR = 'app/store/app/PATCH_APP_ERROR';
