export const GET_JOBS = 'app/store/job/GET_JOBS';
export const GET_JOBS_SUCCESS = 'app/store/job/GET_JOBS_SUCCESS';
export const GET_JOBS_ERROR = 'app/store/job/GET_JOBS_ERROR';

export const PATCH_JOB = 'app/store/PATCH_JOB';
export const PATCH_JOB_SUCCESS = 'app/store/PATCH_JOB_SUCCESS';
export const PATCH_JOB_ERROR = 'app/store/PATCH_JOB_ERROR';

export const POST_JOB = 'app/store/POST_JOB';
export const POST_JOB_SUCCESS = 'app/store/POST_JOB_SUCCESS';
export const POST_JOB_ERROR = 'app/store/POST_JOB_ERROR';

export const DELETE_JOB = 'app/store/DELETE_JOB';
export const DELETE_JOB_SUCCESS = 'app/store/DELETE_JOB_SUCCESS';
