import { createAction } from 'redux-actions';

import * as types from './types';

export const resetParticipantsStats = createAction(types.RESET_PARTICIPANTS_STATS);
export const resetParticipantsList = createAction(types.RESET_PARTICIPANTS_LIST);
export const resetParticipant = createAction(types.RESET_PARTICIPANT);

export const getParticipant = createAction(types.GET_PARTICIPANT);
export const setCurrentParticipant = createAction(types.SET_CURRENT_PARTICIPANT);
export const setOpenParticipant = createAction(types.SET_OPEN_PARTICIPANT);
export const setSettingsParticipant = createAction(types.SET_SETTINGS_PARTICIPANT);

// GET all participants
export const getParticipants = createAction(types.GET_PARTICIPANTS);
export const getParticipantsSuccess = createAction(types.GET_PARTICIPANTS_SUCCESS);
export const getParticipantsError = createAction(types.GET_PARTICIPANTS_ERROR);

export const getConferenceParticipants = createAction(types.GET_CONFERENCE_PARTICIPANTS);

export const getParticipantsFacets = createAction(types.GET_PARTICIPANTS_FACETS);
export const getParticipantsFacetsSuccess = createAction(types.GET_PARTICIPANTS_FACETS_SUCCESS);

export const getParticipantsStats = createAction(types.GET_PARTICIPANTS_STATS);
export const getParticipantsStatsSuccess = createAction(types.GET_PARTICIPANTS_STATS_SUCCESS);

// PATCH an participant
export const patchParticipant = createAction(types.PATCH_PARTICIPANT);
export const patchParticipantSuccess = createAction(types.PATCH_PARTICIPANT_SUCCESS);
export const patchParticipantError = createAction(types.PATCH_PARTICIPANT_ERROR);

export const moveParticipant = createAction(types.MOVE_PARTICIPANT);
export const moveParticipantSuccess = createAction(types.MOVE_PARTICIPANT_SUCCESS);

export const moveParticipants = createAction(types.MOVE_PARTICIPANTS);
export const moveParticipantsSuccess = createAction(types.MOVE_PARTICIPANTS_SUCCESS);

export const refuseParticipant = createAction(types.REFUSE_PARTICIPANT);
export const refuseParticipantSuccess = createAction(types.REFUSE_PARTICIPANT_SUCCESS);
export const refuseParticipantError = createAction(types.REFUSE_PARTICIPANT_ERROR);

export const refuseParticipants = createAction(types.REFUSE_PARTICIPANTS);
export const refuseParticipantsSuccess = createAction(types.REFUSE_PARTICIPANTS_SUCCESS);
export const refuseParticipantsError = createAction(types.REFUSE_PARTICIPANTS_ERROR);

export const selectParticipant = createAction(types.SELECT_PARTICIPANT);
export const selectParticipantSuccess = createAction(types.SELECT_PARTICIPANT_SUCCESS);
export const selectParticipantError = createAction(types.SELECT_PARTICIPANT_ERROR);

export const selectParticipants = createAction(types.SELECT_PARTICIPANTS);
export const selectParticipantsSuccess = createAction(types.SELECT_PARTICIPANTS_SUCCESS);
export const selectParticipantSError = createAction(types.SELECT_PARTICIPANTS_ERROR);

export const hideParticipants = createAction(types.HIDE_PARTICIPANTS);

export const cancelParticipant = createAction(types.CANCEL_PARTICIPANT);
export const cancelParticipantSuccess = createAction(types.CANCEL_PARTICIPANT_SUCCESS);
export const cancelParticipantError = createAction(types.CANCEL_PARTICIPANT_ERROR);

export const visitParticipant = createAction(types.VISIT_PARTICIPANT);
export const visitParticipantSuccess = createAction(types.VISIT_PARTICIPANT_SUCCESS);

// POST an participant
export const postParticipant = createAction(types.POST_PARTICIPANT);
export const postPreregisteredParticipant = createAction(types.POST_PREREGISTERED_PARTICIPANT);
export const postPreregistrationBatch = createAction(types.POST_PREREGISTERED_PARTICIPANT_BATCH);
export const postParticipantSuccess = createAction(types.POST_PARTICIPANT_SUCCESS);

export const banParticipant = createAction(types.BAN_PARTICIPANT);
export const banParticipantSuccess = createAction(types.BAN_PARTICIPANT_SUCCESS);

// Batch actions
export const batchParticipants = createAction(types.BATCH_PARTICIPANTS);

// Tag admin status in participants by context
export const tagParticipantsInIntercom = createAction(types.TAG_PARTICIPANTS_IN_INTERCOM);

// Toogle core target
export const toogleCoreTarget = createAction(types.TOOGLE_CORE_TARGET);
export const toogleCoreTargetSuccess = createAction(types.TOOGLE_CORE_TARGET_SUCCESS);
export const toogleCoreTargetFailure = createAction(types.TOOGLE_CORE_TARGET_FAILURE);

export const toogleCoreTargets = createAction(types.TOOGLE_CORE_TARGETS);
export const toogleCoreTargetsSuccess = createAction(types.TOOGLE_CORE_TARGETS_SUCCESS);
export const toogleCoreTargetsFailure = createAction(types.TOOGLE_CORE_TARGETS_FAILURE);

export const createInformal1To1 = createAction(types.CREATE_INFORMAL1TO1);
export const createInformal1To1Success = createAction(types.CREATE_INFORMAL1TO1_SUCCESS);
export const createInformal1To1Error = createAction(types.CREATE_INFORMAL1TO1_ERROR);
