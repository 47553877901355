import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as offerTypes from '../offer/types';
import * as types from './types';

// Initial State
export const initialStats = {
  allParticipations: 0,
  applications: 0,
  sourcing: 0,
  selection: 0,
  countOffers: 0,
  countNew: 0,
  countExponents: 0,
  countInterviews: 0,
};

const initialReducer = fromJS({
  error: {},
  currentId: null,
  settingId: null,
  isFetching: false,
  actionIsPending: false,
  allIds: [],
  stats: initialStats,
  statsByEvent: {},
  byUserId: {},
  facets: {},
  schools: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    offset: 0,
  },
  participantsFetching: {},
  participantsCoreTarget: {},
});

// Events reducer
const participantReducer = handleActions(
  {
    [types.GET_PARTICIPANTS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.GET_CONFERENCE_PARTICIPANTS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.MOVE_PARTICIPANTS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.SELECT_PARTICIPANTS]: (state, { payload }) => {
      if (payload && payload.loader === true) {
        return state.set('isFetching', true);
      }

      return state;
    },
    [types.GET_PARTICIPANT]: (state, { payload }) => {
      if (payload && payload.noLoader === true) {
        return state;
      }

      return state.set('isFetching', true);
    },
    [types.RESET_PARTICIPANTS_STATS]: state => state.set('stats', initialStats),
    [types.RESET_PARTICIPANTS_LIST]: state => state.set('allIds', state.get('allIds').clear()),
    [types.RESET_PARTICIPANT]: () => initialReducer,
    [types.SET_OPEN_PARTICIPANT]: (state, { payload: participantId }) =>
      state.set('openId', participantId),
    [types.SET_CURRENT_PARTICIPANT]: (state, { payload: participantId }) =>
      state.set('currentId', participantId).set('isFetching', false),
    [types.SET_SETTINGS_PARTICIPANT]: (state, { payload: participantId }) =>
      state.set('settingId', participantId).set('isFetching', false),
    [offerTypes.APPLY_OFFER_SUCCESS]: (state, { payload: participantId }) =>
      state.set('currentId', participantId),
    [types.POST_PARTICIPANT_SUCCESS]: (state, { payload }) =>
      state.set('allIds', state.get('allIds').push(payload)).set('currentId', payload),
    [types.MOVE_PARTICIPANT_SUCCESS]: (state, { payload: { participantId } }) =>
      state
        .set(
          'allIds',
          state.get('allIds').filter(id => id !== participantId),
        )
        .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1),
    [types.REFUSE_PARTICIPANT]: state => state.set('actionIsPending', true),
    [types.SELECT_PARTICIPANT]: state => state.set('actionIsPending', true),
    [types.REFUSE_PARTICIPANT_SUCCESS]: (state, { payload: { participantId } }) =>
      state
        .set('actionIsPending', false)
        .set(
          'allIds',
          state.get('allIds').filter(id => id !== participantId),
        )
        .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1),
    [types.SELECT_PARTICIPANT_SUCCESS]: (state, { payload: { participantId, context } }) =>
      context !== 'interview'
        ? state
            .set(
              'allIds',
              state.get('allIds').filter(id => id !== participantId),
            )
            .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1)
        : state.set('actionIsPending', true),
    [types.HIDE_PARTICIPANTS]: (state, { payload }) =>
      state
        .set(
          'allIds',
          state.get('allIds').filter(id => !payload.participantsIds.includes(id)),
        )
        .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1),
    [types.SELECT_PARTICIPANTS_SUCCESS]: (state, { payload }) =>
      state
        .set(
          'allIds',
          state.get('allIds').filter(id => !payload.participantIds.includes(id)),
        )
        .setIn(['pagination', 'total'], state.getIn(['pagination', 'total']) - 1),
    [types.MOVE_PARTICIPANTS_SUCCESS]: (state, { payload }) =>
      state.set(
        'allIds',
        state.get('allIds').filter(id => !payload.participantIds.includes(id)),
      ),
    [types.GET_PARTICIPANTS_STATS_SUCCESS]: (state, { payload }) =>
      state.set('stats', payload).setIn(['statsByEvent', payload.eventId], payload),
    [types.GET_PARTICIPANTS_SUCCESS]: (state, { payload, payload: { facets, countNew } }) => {
      // eslint-disable-line
      const newAllIds = state.get('allIds').clear().merge(payload.result);
      return state
        .set('countNew', countNew || 0)
        .set('facets', facets)
        .set('schools', payload.schools)
        .set('allIds', newAllIds)
        .set('isFetching', false)
        .setIn(['pagination', 'current'], payload.currentPage)
        .setIn(['pagination', 'pageSize'], payload.limit)
        .setIn(['pagination', 'offset'], payload.offset)
        .setIn(['pagination', 'total'], payload.total);
    },
    [types.GET_PARTICIPANTS_FACETS_SUCCESS]: (state, { payload, payload: { facets } }) => {
      // eslint-disable-line
      return state.set('facets', facets).set('schools', payload.schools);
    },
    [types.PATCH_PARTICIPANT]: state => state.set('isFetching', true),
    [types.PATCH_PARTICIPANT_SUCCESS]: state => state.set('isFetching', false),

    [types.TOOGLE_CORE_TARGET]: (state, { payload }) =>
      state.setIn(['participantsFetching', payload.participantId], true),

    [types.TOOGLE_CORE_TARGET_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['participantsFetching', payload.participantId], false)
        .setIn(
          ['participantsCoreTarget', payload.participantId],
          payload.result[payload.participantId],
        ),

    [types.TOOGLE_CORE_TARGET_FAILURE]: (state, { payload }) =>
      state.setIn(['participantsFetching', payload.participantId], false),

    [types.TOOGLE_CORE_TARGETS]: state => state.set('isFetching', true),

    [types.TOOGLE_CORE_TARGETS_SUCCESS]: (state, { payload }) =>
      state.set('isFetching', false).set('participantsCoreTarget', payload.participantsCoreTarget),

    [types.TOOGLE_CORE_TARGETS_FAILURE]: state => state.set('isFetching', false),
  },
  initialReducer,
);

export default participantReducer;
