import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { surveySchema } from './schema';

const getSurveyReducer = state => state.get('survey');
const getEntities = state => state.get('entities');

/**
 * Return current survey for event
 */
export const getCurrentSurvey = createSelector(
  getSurveyReducer,
  getEntities,
  (surveyState, entities) => {
    const surveyId = surveyState.get('currentId');
    const survey = entities.getIn(['survey', surveyId]);

    return denormalize(survey, surveySchema, entities);
  },
);
