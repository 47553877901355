import { createSelector } from 'reselect';

const getAuthReducer = state => state.get('auth');

/**
 * Return the user not connected as
 */
export const getAuthUserNotConnectedAs = createSelector(getAuthReducer, authState =>
  authState.get('user'),
);

/**
 * Return the invite user not connected as
 */
export const getAuthInviteUser = createSelector(getAuthReducer, authState =>
  authState.get('inviteUser'),
);

/**
 * Return the user connected as
 */
export const getAuthUserConnectedAs = createSelector(getAuthReducer, authState =>
  authState.getIn(['connectedAs', 'user']),
);

/**
 * Return the user
 *
 * @description
 * If we have connected as return this
 */
export const getAuthUser = createSelector(
  getAuthUserConnectedAs,
  getAuthUserNotConnectedAs,
  (userConnectedAs, userNotConnectedAs) => userConnectedAs || userNotConnectedAs,
);

/**
 * Get Reset Status on reset password flow
 */
export const getResetStatus = createSelector(getAuthReducer, authState =>
  authState.get('resetStatus'),
);

/**
 * Get Reset Status on reset password flow
 */
export const getIsChecking = createSelector(getAuthReducer, authState =>
  authState.get('isChecking'),
);

/**
 * Get Reset Status on reset password flow
 */
export const getResetIsFetching = createSelector(getAuthReducer, authState =>
  authState.get('resetIsFetching'),
);

/**
 * Get Error
 */
export const getError = createSelector(getAuthReducer, authState => authState.get('error'));

/**
 * Get Reset Status on reset password flow
 */
export const getHasClosedIeAlert = createSelector(getAuthReducer, authState =>
  authState.get('hasClosedIeAlert'),
);

export const getHasClosedCookieAlert = createSelector(getAuthReducer, authState =>
  authState.get('hasClosedCookieAlert'),
);
export const getHasClosedFeedbackAboutCandidates = createSelector(getAuthReducer, authState =>
  authState.get('hasClosedFeedbackAboutCandidates'),
);

export const getShowSettings = createSelector(getAuthReducer, authState =>
  authState.get('showSettings'),
);

export const getDefaultSettingsView = createSelector(getAuthReducer, authState =>
  authState.get('defaultSettingsView'),
);

export const getAgendaView = createSelector(getAuthReducer, authState =>
  authState.get('agendaView'),
);

export const getAtsDefaultReviewer = createSelector(getAuthReducer, authState =>
  authState.get('atsDefaultReviewer'),
);
