import dayjs from 'dayjs';
import { get } from 'lodash';
import { normalize } from 'normalizr';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import request from '@/utils/request';

import { trackError } from '../../utils/analytics/helpers';
import { authSelectors } from '../auth';
import { entitiesActions } from '../entities';
import { userActions } from '../user';
import * as actions from './actions';
import { timeslotListSchema } from './schema';
import * as types from './types';

/**
 * Get user timeslots
 *
 * @param {object} currentUser
 */
function* getUserTimeslots({ payload: { currentUser, currentEvent, context, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;
  const eventDate = currentEvent
    ? `?beginAt=${dayjs(get(currentEvent, 'keyDates.jobfair.beginAt')).toISOString()}&endAt=${dayjs(get(currentEvent, 'keyDates.jobfair.endAt')).toISOString()}`
    : '';
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots${eventDate}`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, timeslotListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save timeslotsIds by userId
    yield put(
      actions.getUserTimeslotsSuccess({
        ids: dataNormalized.result,
        userId: userIdSelected,
        context,
      }),
    );

    if (typeof callback === 'function') {
      return callback();
    }

    // Set Current user
    yield put(userActions.setCurrentUser(userIdSelected));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Listen Actions
 */
export default [takeLatest(types.GET_USER_TIMESLOTS, getUserTimeslots)];
