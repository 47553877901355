import React from 'react';

import moment from 'moment';
// Components
import RCNotification from 'rc-notification';

import { Toaster } from '@seekube-tech/ui-kit';

// Styles & Translations
import styles from './styles.less';

// Configs
const defaultDuration = 3;

let messageInstance;
let getContainer;
const defaultStyle = {};

/**
 * Create an instance of RCNotification
 */
const getNotificationInstance = cb =>
  messageInstance ||
  RCNotification.newInstance(
    {
      prefixCls: styles.notificationContainer,
      transitionName: 'move-up',
      style: defaultStyle,
      getContainer,
    },
    cb,
  );

const notification = (content, duration = defaultDuration, type, style = defaultStyle, onClose) =>
  getNotificationInstance(notification => {
    notification.notice({
      key: moment().unix(),
      duration,
      style,
      content: <Toaster color={type}>{content}</Toaster>,
      onClose,
    });
  });

export default {
  default: (content, duration, style, onClose) =>
    notification(content, duration, 'success', style, onClose),
  info: (content, duration, style, onClose) =>
    notification(content, duration, 'info', style, onClose),
  success: (content, duration, style, onClose) =>
    notification(content, duration, 'success', style, onClose),
  warning: (content, duration, style, onClose) =>
    notification(content, duration, 'warning', style, onClose),
  error: (content, duration, style, onClose) =>
    notification(content, duration, 'error', style, onClose),
};
