import React from 'react';

import { string } from 'prop-types';

import Icon from '../Icon';
import styles from './styles.less';

class Tag extends React.PureComponent {
  render() {
    const { className, icon, name } = this.props;

    return (
      <div className={[styles.tag, className].join(' ')}>
        <div className={styles.content}>
          <Icon className={styles.icon} name={icon} />
          <div className={styles.text}>{name}</div>
        </div>
      </div>
    );
  }
}

Tag.propTypes = {
  className: string,
  icon: string,
  name: string,
};

export default Tag;
