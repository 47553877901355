import { isEmpty, sortBy } from 'lodash';

import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { trackGtmEvent } from '@/utils/pixels/helloWork';

import { track } from '../helpers';

const trackFilterOffer = ({ params, user, event, countOffers, localization, size, sector }) => {
  track({
    name: ANALYTICS_CANDIDATE.FILTER_OFFER,
    user,
    event,
    properties: {
      countOffers,
      ...params,
      localization,
      size,
      sector,
    },
  });
};

const trackExponentsVisit = ({ user, event }) => {
  track({
    name: ANALYTICS_CANDIDATE.EXPONENTS_VISIT,
    event,
    user,
    properties: {
      countOffers: 0,
    },
  });
};

const trackViewOfferClick = ({ user, event, company, currentUrl }) => {
  track({
    name: ANALYTICS_CANDIDATE.VIEW_OFFER_CLICK,
    user,
    event,
    properties: {
      'Current url': currentUrl,
      company,
    },
  });
};

const trackEditedSearchClick = ({ user, event }) => {
  track({
    name: ANALYTICS_CANDIDATE.CLICKED_EDITED_SEARCH,
    user,
    event,
  });
};

const trackSendEmailRecruiterCalendar = ({ type, event }) => {
  track({
    name: ANALYTICS_CANDIDATE.SEND_EMAIL_RECRUITER_CALENDAR,
    event,
    properties: {
      type,
    },
  });
};

const trackDeclinedJobOffer = ({ user, event, message }) => {
  track({
    name: ANALYTICS_CANDIDATE.DECLINED_JOB_OFFER,
    user,
    event,
    properties: {
      hasMessage: !isEmpty(message),
      message,
    },
  });
};

const trackCanceledAppointment = ({ user, event, message }) => {
  track({
    name: ANALYTICS_CANDIDATE.CANCELED_APPOINTMENT,
    user,
    event,
    properties: {
      hasMessage: !isEmpty(message),
      message,
    },
  });
};

const trackQuickSignupStep1 = ({ user, targets = [] }) => {
  track({
    name: ANALYTICS_CANDIDATE.QUICK_SIGNUP_STEP_1_SUBMIT,
    user,
    properties: {
      targets: sortBy(targets).join(', '),
    },
  });
};

const trackCandidateClickedForum = ({ authUser, event }) => {
  trackGtmEvent('learn_more', {
    page_category: 'profile',
    page_subject: 'jobdating',
    jobdating_name: event.slug,
    event_info: 'CTA',
  });

  track({
    name: ANALYTICS_CANDIDATE.CLICKED_FORUM,
    user: authUser,
    event,
    properties: {
      stage: event.dateStatus,
      registered: event.isAuthUserParticipating,
    },
  });
};

const trackCandidateClickedSigninLanding = ({ event }) => {
  trackGtmEvent('login', {
    jobdating_name: event.slug,
    flow_name: 'login_jobdating',
  });

  track({
    name: ANALYTICS_CANDIDATE.LANDING_SIGNIN_CLICK,
    properties: {
      forum: event.name,
    },
  });
};

const trackCandidateClickedRegistration = ({ authUser, event, countOffers }) => {
  trackGtmEvent('login_after_sign_up', {
    jobdating_name: event.slug,
    user_id_back: authUser._id,
    flow_name: 'inscription_jobdating',
  });

  track({
    name: ANALYTICS_CANDIDATE.REGISTRATION_CLICK,
    user: authUser,
    properties: {
      forum: event.name,
      countOffers,
    },
  });
};

const trackCandidateClickedViewKeyDates = ({ authUser }) =>
  track({
    name: ANALYTICS_CANDIDATE.CLICKED_VIEW_KEY_DATES,
    user: authUser,
  });

const trackCandidateClickedModifyParticipationMode = ({ authUser, event }) =>
  track({
    name: ANALYTICS_CANDIDATE.CLICKED_MODIFY_PARTICIPATION_MODE,
    user: authUser,
    event,
    properties: {
      forum: event.name,
    },
  });

const trackCandidateModifiedParticipationMode = ({ authUser, event }) =>
  track({
    name: ANALYTICS_CANDIDATE.MODIFIED_PARTICIPATION_MODE,
    user: authUser,
    event,
    properties: {
      forum: event.name,
    },
  });

const trackCandidateClickedLandingTabs = ({ tabParam }) => {
  return null;
  trackGtmEvent('landing_tabs', {
    page_category: 'landing_page',
    tab_param: tabParam,
  });
};

const trackCandidateClickedOnCardToSignup = ({ from }) => {
  return null;
  // On souhaite savoir par quel biais le candidat a rejoint le funnel d’inscription de l’événement
  trackGtmEvent('landing_card', {
    cta: from,
    page_category: 'landing_page',
    page_subject: 'inscription',
  });
};

export {
  trackFilterOffer,
  trackViewOfferClick,
  trackEditedSearchClick,
  trackExponentsVisit,
  trackSendEmailRecruiterCalendar,
  trackDeclinedJobOffer,
  trackCanceledAppointment,
  trackCandidateClickedForum,
  trackQuickSignupStep1,
  trackCandidateClickedRegistration,
  trackCandidateClickedSigninLanding,
  trackCandidateClickedViewKeyDates,
  trackCandidateClickedModifyParticipationMode,
  trackCandidateModifiedParticipationMode,
  trackCandidateClickedLandingTabs,
  trackCandidateClickedOnCardToSignup,
};
