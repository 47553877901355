import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Action for login auth
 */
export const authLogin = createAction(types.AUTH_LOGIN);
export const authLoginOauth = createAction(types.AUTH_LOGIN_OAUTH);
export const authLoginSuccess = createAction(types.AUTH_LOGIN_SUCCESS);
export const authLoginError = createAction(types.AUTH_LOGIN_ERROR);

export const authRefreshUser = createAction(types.AUTH_REFRESH_USER);

/**
 * Actions for reset password
 */
export const authCheckResetToken = createAction(types.AUTH_CHECK_RESET_TOKEN);
export const authCheckInvitation = createAction(types.AUTH_CHECK_INVITATION);
export const authCheckInvitationSuccess = createAction(types.AUTH_CHECK_INVITATION_SUCCESS);

export const authCheckResetTokenSuccess = createAction(types.AUTH_CHECK_RESET_TOKEN_SUCCESS);
export const authResetPassword = createAction(types.AUTH_RESET_PASSWORD);
export const authChangePassword = createAction(types.AUTH_CHANGE_PASSWORD);
export const authResetPasswordStatus = createAction(types.AUTH_RESET_PASSWORD_STATUS);

/**
 * Action for signup auth
 */
export const authSignup = createAction(types.AUTH_SIGNUP);
export const authSSO = createAction(types.AUTH_SSO);
export const authSignupProvider = createAction(types.AUTH_SIGNUP_PROVIDER);

/**
 * Action for switch user auth
 */
export const switchAuth = createAction(types.SWITCH_AUTH);
export const switchAuthSuccess = createAction(types.SWITCH_AUTH_SUCCESS);
export const switchAuthError = createAction(types.SWITCH_AUTH_ERROR);

/**
 * Action for exit switch auth
 */
export const switchExit = createAction(types.SWITCH_EXIT);
export const switchExitSuccess = createAction(types.SWITCH_EXIT_SUCCESS);

/**
 * Action for logout
 */
export const authLogout = createAction(types.AUTH_LOGOUT);
export const authLogoutUser = createAction(types.AUTH_LOGOUT_USER);

/**
 * Action for change email
 */
export const changeEmail = createAction(types.AUTH_CHANGE_EMAIL);

export const toggleSettings = createAction(types.TOGGLE_SETTINGS);
export const closeSettings = createAction(types.CLOSE_SETTINGS);
export const closeIeAlert = createAction(types.CLOSE_IE_ALERT);
export const closeCookieAlert = createAction(types.CLOSE_COOKIE_ALERT);
export const setAgendaView = createAction(types.SET_AGENDA_VIEW);
export const setAtsDefaultReviewer = createAction(types.SET_ATS_DEFAULT_REVIEWER);
export const closeFeedbackAboutCandidates = createAction(types.CLOSE_FEEBACK_ABOUT_CANDIDATES);
