export const RESET_DEMONSTRATION = 'app/store/user/RESET_DEMONSTRATION';
export const RESEND_INVITATION_EMAIL = 'app/store/user/RESEND_INVITATION_EMAIL';
export const SWITCH_ORGANIZATION = 'app/store/user/SWITCH_ORGANIZATION';
export const SET_CURRENT_USER = 'app/store/user/SET_CURRENT_USER';
export const PATCH_USER = 'app/store/user/PATCH_USER';
export const PATCH_ME = 'app/store/user/PATCH_ME';
export const PATCH_USER_SUCCESS = 'app/store/user/PATCH_USER_SUCCESS';
export const DELETE_USER = 'app/store/user/DELETE_USER';
export const DELETE_USER_SUCCESS = 'app/store/user/DELETE_USER_SUCCESS';

export const PUT_USER_AFTER_SIGNUP = 'app/store/user/PUT_USER_AFTER_SIGNUP';
export const PATCH_USER_BEFORE_SIGNUP = 'app/store/user/PATCH_USER_BEFORE_SIGNUP';

export const PATCH_USERS = 'app/store/user/PATCH_USERS';
export const PATCH_USERS_SUCCESS = 'app/store/user/PATCH_USERS_SUCCESS';

export const GET_USERS_ORGANIZATION = 'app/store/user/GET_USERS_ORGANIZATION';
export const GET_USERS_ORGANIZATION_SUCCESS = 'app/store/user/GET_USERS_ORGANIZATION_SUCCESS';
export const GET_USERS_ORGANIZATION_ERROR = 'app/store/user/GET_USERS_ORGANIZATION_ERROR';
export const DELETE_USER_FROM_ORGANIZATION = 'app/store/user/DELETE_USER_FROM_ORGANIZATION';

export const POST_USER_ORGANIZATION = 'app/store/user/POST_USER_ORGANIZATION';
export const POST_USER_ORGANIZATION_AND_ADD_TO_EXPONENT =
  'app/store/user/POST_USER_ORGANIZATION_AND_ADD_TO_EXPONENT';
export const POST_USER_ORGANIZATION_SUCCESS = 'app/store/user/POST_USER_ORGANIZATION_SUCCESS';
export const POST_USER_ORGANIZATION_ERROR = 'app/store/user/POST_USER_ORGANIZATION_ERROR';

export const GET_USER = 'app/store/user/GET_USER';
export const GET_USER_SUCCESS = 'app/store/user/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'app/store/user/GET_USER_ERROR';

export const GET_USER_LAST_PARTICIPATION = 'app/store/user/GET_USER_LAST_PARTICIPATION';
export const GET_USER_LAST_PARTICIPATION_SUCCESS =
  'app/store/user/GET_USER_LAST_PARTICIPATION_SUCCESS';
export const GET_USER_LAST_PARTICIPATION_ERROR = 'app/store/user/GET_USER_LAST_PARTICIPATION_ERROR';

export const GET_SYNC_CONNECT_URL = 'app/store/user/GET_SYNC_CONNECT_URL';
export const GET_SYNC_DISCONNECT_URL = 'app/store/user/GET_SYNC_DISCONNECT_URL';
export const POST_SYNC_CODE = 'app/store/user/POST_SYNC_CODE';
export const SET_CALENDARS = 'app/store/user/SET_CALENDARS';
export const GET_CALENDARS = 'app/store/user/GET_CALENDARS';
