import React from 'react';

import { number, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Body1, IconBuilding } from '@seekube-tech/ui-kit';

import styles from '../../styles.less';

const EventCompanies = ({ count, companyName = '' }) => {
  const t = useFormatMessage();

  if (!count) {
    return null;
  }

  return (
    <div className="flex justify-between">
      <div className={styles.infoItem}>
        <IconBuilding className="text-neutral-300" size={16} />
        <Body1 className="text-neutral-500">
          {count > 1 ? t({ id: 'searchEngine.list.compagnies.pl' }, { count }) : companyName}
        </Body1>
      </div>
    </div>
  );
};

EventCompanies.propTypes = {
  count: number,
  companyName: string,
};

export { EventCompanies };
