import React from 'react';

import { node, string } from 'prop-types';

import { Body1, H6 } from '@seekube-tech/ui-kit';

import styles from '../styles.less';

const Card = ({ title, description, icon }) => (
  <div className={styles.cardContainer}>
    {icon}
    <div>
      <H6 className="text-neutral-600">{title}</H6>
      <Body1 className="text-neutral-400">{description}</Body1>
    </div>
  </div>
);

Card.propTypes = {
  title: string,
  description: string,
  icon: node,
};

export { Card };
