import { normalize } from 'normalizr';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getAuthUser } from '@/store/auth/selectors';
import { notificationActions } from '@/store/notification';
import { getParticipant, getParticipants } from '@/store/participant/actions';
import { trackError } from '@/utils/analytics';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

import { entitiesActions } from '../entities';
import { eventSelectors } from '../event';
import * as actions from './actions';
import { interactionListSchema } from './schema';
import * as types from './types';

function* getInteractions({ payload: { eventSlug, body, callback, clear = true } }) {
  const params = objectToParams({ ...body });
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/interactions?${params}`;
  const event = yield select(eventSelectors.getCurrentEvent);

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Save entities
    const dataNormalized = normalize(result, interactionListSchema) || {};
    // Save entities
    // yield put(entitiesActions.replaceEntities({ id: result._id, type: 'interactions', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(
      actions.getInteractionsSuccess({
        event,
        clear,
        result: dataNormalized.result,
      }),
    );
    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

function* postInteractionOwner({
  payload: {
    interactionId,
    ownerId,
    eventSlug,
    participantsParams,
    message,
    timeslotId,
    notificationParams,
    userId,
    context,
    callback,
  },
}) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/interactions/${interactionId}/change_owner`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        message,
        timeslotId,
        userId,
        ownerId,
        authUser,
      }),
    });

    if (context !== 'agenda') {
      if (timeslotId && !authUser.getIn(['_currentOrganization', '_id'])) {
        yield put(
          getParticipant({
            userId: participantsParams.userId,
            eventId: eventSlug,
          }),
        );
      } else {
        yield put(
          getParticipants({
            eventId: eventSlug,
            ...participantsParams,
          }),
        );
      }
    }

    if (callback) {
      callback();
    }

    if (notificationParams?.success) {
      yield put(
        notificationActions.sendNotification({
          message: notificationParams.success.message,
          kind: notificationParams.success.kind,
          style: notificationParams.success.style,
        }),
      );
    }
  } catch (err) {
    trackError(err);

    if (notificationParams?.error) {
      yield put(
        notificationActions.sendNotification({
          message: err.message,
          kind: 'error',
        }),
      );
    }
  }
}

export default [
  takeEvery(types.GET_INTERACTIONS, getInteractions),
  takeLatest(types.CHANGE_INTERACTION_OWNER, postInteractionOwner),
];
