import dayjs from 'dayjs';
import { List, Map } from 'immutable';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux-immutable';

import Notification from '../../components/Notification';
import * as types from './types';

/**
 * Notification reducer
 *
 * @param {object} state
 * @param {object} action
 */
const notification = (state = new Map(), action) => {
  switch (action.type) {
    case types.NEW_NOTIFICATION:
      Notification[action.payload.kind || 'info'](
        action.payload.message,
        3,
        action.payload.style || {
          bottom: '5%',
          top: 'inherit',
        },
      );

      return new Map()
        .set('message', action.payload.message || 'Modification enregistré')
        .set('kind', action.payload.kind || 'info') // Can be 'info | success | warning | danger'
        .set('style', action.payload.style || 'info') // Can be 'info | success | warning | danger'
        .set('id', dayjs().format('x').toString())
        .set('dismissAfter', action.payload.dismissAfter || null);
    default:
      return state;
  }
};

/**
 * ById reducer
 */
const byId = handleActions(
  {
    [types.NEW_NOTIFICATION]: (state, action) => {
      const notif = notification(state.get(action.payload.id), action);
      return state.set(notif.get('id'), notif);
    },
    [types.CLEAR_NOTIFICATION]: state => state.clear(),
    [types.DISMISS_NOTIFICATION]: (state, { id }) => state.delete(id),
  },
  new Map(),
);

/**
 * AllIds reducer
 */
const allIds = handleActions(
  {
    [types.NEW_NOTIFICATION]: (state, action) => {
      const notif = notification(state.get(action.payload.id), action);
      return state.push(notif.get('id'));
    },
    [types.CLEAR_NOTIFICATION]: state => state.clear(),
    [types.DISMISS_NOTIFICATION]: (state, { id }) => state.delete(id),
  },
  new List(),
);

export default combineReducers({
  byId,
  allIds,
});
