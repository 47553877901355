import { PARIS_LAT, PARIS_LNG } from '@/features/gmaps/constants';

export const getGoogleMapAddress = (
  googleMaps,
  address: string,
  selectedPlaces: Array<{ label: string; name?: string }> = [],
  types = ['geocode'],
) =>
  new Promise((resolve, reject) => {
    if (googleMaps) {
      const autoCompleteService = new googleMaps.places.AutocompleteService();

      if (autoCompleteService) {
        try {
          autoCompleteService.getPlacePredictions(
            {
              input: address,
              locationBias: new googleMaps.LatLng(PARIS_LAT, PARIS_LNG),
              types,
            },
            googleSuggests => {
              const formattedResults = googleSuggests
                ?.filter(
                  suggest =>
                    !selectedPlaces
                      ?.map(({ label, name }) => label ?? name)
                      ?.includes(suggest.description),
                )
                .map(suggest => ({
                  label: suggest.description,
                  name: suggest.description,
                  value: suggest.place_id,
                  placeId: suggest.place_id,
                  suggest,
                }));

              resolve(
                selectedPlaces?.length > 0
                  ? [...selectedPlaces, ...formattedResults]
                  : formattedResults,
              );
            },
          );
        } catch (e) {
          reject(e);
        }
      }
    }
  });
