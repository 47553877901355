import { createAction } from 'redux-actions';

import * as types from './types';

/**
 * Action for get user timeslots
 */
export const getUserTimeslots = createAction(types.GET_USER_TIMESLOTS);
export const getUserTimeslotsSuccess = createAction(types.GET_USER_TIMESLOTS_SUCCESS);
export const getUserTimeslotError = createAction(types.GET_USER_TIMESLOTS_ERROR);
